import NormalObject from '@components/NormalObject';
import { useBookingContext } from './BookingContext';
import checkedIcon from '@assets/icons/shared/checked.svg';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';

interface Step11Props {
  setShowTermCondition: (value: boolean) => void;
}

export function Step11(props: Step11Props) {
  const { t } = useTranslation('booking');
  const {
    email,
    setEmail,
    isChecked,
    setIsChecked,
    isEmailValid,
    setIsEmailValid,
  } = useBookingContext();
  const { setShowTermCondition } = props;

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  }, [email]);

  return (
    <>
      <h1 className="hidden md:block booking-step-title">
        {t`questions.question-11.title`}
      </h1>

      <h1 className="block md:hidden font-extrabold text-[28px] leading-[36px]">
        {t`questions.question-11.title`}
      </h1>

      <div className="flex flex-col gap-[20px] md:gap-[24px] my-[10px] md:my-[30px]">
        <p className="font-regular text-sm md:text-lg text-[#181818]">
          {t`questions.question-11.question.p1`}
        </p>
        <p className="font-semibold text-base md:text-lg text-[#181818]">
          {t`questions.question-11.question.p2`}
        </p>

        <div className="">
          <input
            type="email"
            placeholder={t`questions.question-11.placeholder`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border-b border-black focus:outline-none focus:border-black placeholder-gray-500 py-2"
          />
          {/* Hint */}
          {isEmailValid || (
            <p className="text-[13px] md:text-base text-[#7a6b6b]">
              {t`questions.question-11.hint`}
            </p>
          )}
        </div>

        <div className="flex flex-row items-center">
          <label className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              className="w-0 h-0 opacity-0 absolute"
            />
            <div
              className={`w-[40px] h-[40px] mr-[15px] rounded-[3px] border border-black flex items-center justify-center ${
                isChecked ? 'bg-[#AF9CFB]' : 'bg-white'
              }`}
            >
              {isChecked && <NormalObject data={checkedIcon} className="" />}
            </div>
          </label>

          {/* Text does not toggle checkbox */}
          <span className="text-[13px] md:text-base">
            {t`questions.question-11.confirmation-1`}
            <span
              className="text-[#797979] underline cursor-pointer font-semibold select-none"
              onClick={() => setShowTermCondition(true)}
            >
              {t`questions.question-11.confirmation-2`}
            </span>
          </span>
        </div>
      </div>
    </>
  );
}
