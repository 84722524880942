import Button from '@components/Button';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// TODO: Will use Trans

export function IntroSection() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation('home');
  return (
    <>
      <div className="flex flex-col lg:flex-row md:mb-[64px]">
        <div className="flex flex-col px-[26px] md:pl-[76px] md:pr-0 mt-[20px] md:mt-[64px] md:pt-0">
          <div
            className={`hidden md:block font-extrabold md:text-8xl md:leading-[85px] tracking-[-0.03em]`}
          >
            <h1
              dangerouslySetInnerHTML={{ __html: t`introduction.title` }}
            ></h1>
          </div>

          <div className="md:hidden font-extrabold text-[32px] leading-[36px]">
            <p dangerouslySetInnerHTML={{ __html: t`introduction.title` }}></p>
          </div>

          <div className="font-bold md:font-semibold text-xl md:text-3xl mt-[20px]">
            <p>{t('introduction.subtitle')}</p>
          </div>

          <Button
            label={t('introduction.cta')}
            className="md:hidden font-semibold mt-[20px] text-lg h-[50px] w-[154px] rounded-[20px]"
            onClick={() => navigate('/learn-more')}
          />

          <div
            className="md:hidden pl-[26px] transform translate-x-[26px] mt-[-64px]"
            style={{ zIndex: -1 }}
          >
            <img
              src="media/home/hero-mobile.png"
              alt={t('alt.home.hero', { ns: 'common' })}
              className="w-full"
            />
          </div>

          <p className="hidden md:block md:mt-[28px] font-regular text-base md:text-2xl md:pr-[68px] leading-[22px] md:leading-[32px]">
            {t('introduction.content')}
          </p>

          <Button
            label={t('introduction.cta')}
            className="hidden md:block font-semibold mt-[28px] text-2xl h-[60px] w-[174px] rounded-2xl"
            onClick={() => navigate('/learn-more')}
          />
        </div>
        <img
          src="media/home/hero.webp"
          alt={t('alt.home.hero', { ns: 'common' })}
          className="hidden lg:block h-full object-contain"
        />
      </div>
      <div className="md:hidden relative">
        <p className="absolute top-1/2 left-0 -translate-y-1/2 left-0 z-[999] px-[24px]">
          {t('introduction.content')}
        </p>
        <AnimationBackground />
      </div>
    </>
  );
}

function AnimationBackground() {
  const WIDTH1 = 560;
  const WIDTH2 = 350;
  const delta = 50;

  const initTranslateX = -(2 * WIDTH1 + WIDTH2 + delta);
  const [translateX, setTranslateX] = useState<number>(initTranslateX);

  const [shouldTransition, setShouldTransition] = useState(true);

  const [screenWidth, setScreenWidth] = useState<number>(
    document.documentElement.clientWidth
  ); // Exclude scrollbar width

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(document.documentElement.clientWidth); // Update screen width on resize
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  const frameIndex = useRef(1);

  useEffect(() => {
    const startAnimation = () => {
      switch (frameIndex.current) {
        case 1:
          setTranslateX(-(WIDTH1 + WIDTH2 + delta));
          break;
        case 2:
          setTranslateX(-WIDTH1);
          break;
        case 3:
          setTranslateX(-delta);
          break;
      }
      frameIndex.current += 1;

      setTimeout(() => {
        setShouldTransition(false);

        if (frameIndex.current === 4) {
          setTranslateX(initTranslateX);
          frameIndex.current = 1;
        }

        setTimeout(() => {
          setShouldTransition(true);
          startAnimation();
        }, 500);
      }, 2000); // Pause at the target for the duration of the transition
    };

    startAnimation(); // Start the animation on mount
  }, [screenWidth]);

  return (
    <div className="relative overflow-hidden w-full">
      <div
        className="whitespace-nowrap h-[330px] inline-flex"
        style={{
          transform: `translateX(${translateX}px)`, // Dynamic horizontal position
          transition: shouldTransition ? 'transform 1s linear' : 'none', // Apply or remove transition
        }}
      >
        <ColorBlock1 color="bg-purple-400" />
        <ColorBlock2 />
        <ColorBlock1 color="bg-yellow-400" />
        <ColorBlock1 color="bg-purple-400" />
      </div>
    </div>
  );
}

interface ColorBlock1Props {
  color: string;
}

function ColorBlock1(props: ColorBlock1Props) {
  const { color } = props;

  return (
    <div className={clsx('w-[560px] h-[330px] rounded-full', color)}></div>
  );
}

function ColorBlock2() {
  return <div className="bg-yellow-400 w-[350px] h-[330px] rounded-full"></div>;
}
