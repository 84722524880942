import Button from '@components/Button';
import { openGoogleForm } from '@src/utils/open-googleform';
import { useTranslation } from 'react-i18next';

// TODO: Define some common class to use global

export default function JoinUsSection() {
  const { t } = useTranslation('become-a-listener');

  return (
    <section className="relative bg-[#151515] py-[50px] md:py-[80px] overflow-hidden">
      <div className="text-5xl leading-[48px] md:text-7xl font-extrabold md:leading-[65px] text-white text-center  mx-auto ">
        <h2
          className="hidden lg:block"
          dangerouslySetInnerHTML={{ __html: t`join-with-us.title` }}
        ></h2>
        <h2
          className="lg:hidden"
          dangerouslySetInnerHTML={{ __html: t`join-with-us.title-mobile` }}
        ></h2>
      </div>

      <div className="text-center mt-[20px]">
        <Button
          label={t`join-with-us.description`}
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={openGoogleForm}
          // TODO: Put this className in css file
        />
      </div>
    </section>
  );
}
