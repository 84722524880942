import React, { useState } from 'react';

import closeIcon from '@assets/icons/shared/close-icon.svg';

import clsx from 'clsx';
import { Swipe } from '@components/Swipe';
import { PopUp } from '@components/Popup';
import { MobileSwipe } from '@components/MobileSwipe';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

type CardItem = {
  id: number;
  name: string;
  description: string;
  avatar: string;
  background: string;
  backgroundMobile: string;
  alt: string;
};

const CARD_WIDTH = 656;
const GAP = 67;
const BOUNDARY_PADDING = 67;

export function SupporterSwipe() {
  const { t, i18n } = useTranslation('become-a-listener');
  const [selectedSupporter, setSelectedSupporter] = useState<number | null>(
    null
  );

  const cards =
    i18n.language === 'vn'
      ? [
          {
            id: 6,
            profile: 6,
            name: t`meet-supporters.cards.card-6.name`,
            description: t`meet-supporters.cards.card-6.description`,
            avatar: 'media/supporters/avatar-bao.webp',
            background: 'media/supporters/supporter-background-2.webp',
            backgroundMobile:
              'media/supporters/supporter-background-2-mobile.webp',
            profileBackground: 'media/supporters/profile-background-2.webp',
            alt: 'Asian woman with a little smile, looking kind and empathetic',
          },
          {
            id: 8,
            profile: 8,
            name: t`meet-supporters.cards.card-8.name`,
            description: t`meet-supporters.cards.card-8.description`,
            avatar: 'media/supporters/avatar-thao.webp',
            background: 'media/supporters/supporter-background-3.webp',
            backgroundMobile:
              'media/supporters/supporter-background-3-mobile.webp',
            profileBackground: 'media/supporters/profile-background-3.webp',
            alt: 'Asian woman with glasses, a kind smile and friendly attitude',
          },
          {
            id: 9,
            profile: 9,
            name: t`meet-supporters.cards.card-9.name`,
            description: t`meet-supporters.cards.card-9.description`,
            avatar: 'media/supporters/avatar-an.webp',
            background: 'media/supporters/supporter-background-4.webp',
            backgroundMobile:
              'media/supporters/supporter-background-4-mobile.webp',
            profileBackground: 'media/supporters/profile-background-4.webp',
            alt: 'Asian woman with glasses, a kind smile and friendly attitude',
          },
          {
            id: 10,
            profile: 10,
            name: t`meet-supporters.cards.card-10.name`,
            description: t`meet-supporters.cards.card-10.description`,
            avatar: 'media/supporters/avatar-lam.webp',
            background: 'media/supporters/supporter-background-1.webp',
            backgroundMobile:
              'media/supporters/supporter-background-1-mobile.webp',
            profileBackground: 'media/supporters/profile-background-1.webp',
            alt: 'Asian woman with glasses, a kind smile and friendly attitude',
          },
          {
            id: 11,
            profile: 11,
            name: t`meet-supporters.cards.card-11.name`,
            description: t`meet-supporters.cards.card-11.description`,
            avatar: 'media/supporters/avatar-han.webp',
            background: 'media/supporters/supporter-background-2.webp',
            backgroundMobile:
              'media/supporters/supporter-background-2-mobile.webp',
            profileBackground: 'media/supporters/profile-background-2.webp',
            alt: 'Asian woman with glasses, a kind smile and friendly attitude',
          },
          {
            id: 12,
            profile: 12,
            name: t`meet-supporters.cards.card-12.name`,
            description: t`meet-supporters.cards.card-12.description`,
            avatar: 'media/supporters/avatar-nhi.webp',
            background: 'media/supporters/supporter-background-3.webp',
            backgroundMobile:
              'media/supporters/supporter-background-3-mobile.webp',
            profileBackground: 'media/supporters/profile-background-3.webp',
            alt: 'Asian woman with glasses, a kind smile and friendly attitude',
          },
          {
            id: 13,
            profile: 13,
            name: t`meet-supporters.cards.card-13.name`,
            description: t`meet-supporters.cards.card-13.description`,
            avatar: 'media/supporters/avatar-tran.webp',
            background: 'media/supporters/supporter-background-4.webp',
            backgroundMobile:
              'media/supporters/supporter-background-4-mobile.webp',
            profileBackground: 'media/supporters/profile-background-4.webp',
            alt: 'Asian woman with glasses, a kind smile and friendly attitude',
          },
        ]
      : [
          {
            id: 1,
            profile: 1,
            name: t`meet-supporters.cards.card-1.name`,
            description: t`meet-supporters.cards.card-1.description`,
            avatar: 'media/supporters/avatar-pei-shan.webp',
            background: 'media/supporters/supporter-background-1.webp',
            backgroundMobile:
              'media/supporters/supporter-background-1-mobile.webp',
            profileBackground: 'media/supporters/profile-background-1.webp',
            alt: 'Asian woman smiling, looking friendly and kind.',
          },
          {
            id: 2,
            profile: 2,
            name: t`meet-supporters.cards.card-2.name`,
            description: t`meet-supporters.cards.card-2.description`,
            avatar: 'media/supporters/avatar-pradnya.webp',
            background: 'media/supporters/supporter-background-2.webp',
            backgroundMobile:
              'media/supporters/supporter-background-2-mobile.webp',
            profileBackground: 'media/supporters/profile-background-2.webp',
            alt: 'Asian woman with a big smile, looking cheerful and kind',
          },
          {
            id: 3,
            profile: 3,
            name: t`meet-supporters.cards.card-3.name`,
            description: t`meet-supporters.cards.card-3.description`,
            avatar: 'media/supporters/avatar-ana.webp',
            background: 'media/supporters/supporter-background-3.webp',
            backgroundMobile:
              'media/supporters/supporter-background-3-mobile.webp',
            profileBackground: 'media/supporters/profile-background-3.webp',
            alt: 'Caucasian woman, smiling, and looking kind and empathetic',
          },
          {
            id: 4,
            profile: 4,
            name: t`meet-supporters.cards.card-4.name`,
            description: t`meet-supporters.cards.card-4.description`,
            avatar: 'media/supporters/avatar-yoke.webp',
            background: 'media/supporters/supporter-background-4.webp',
            backgroundMobile:
              'media/supporters/supporter-background-4-mobile.webp',
            profileBackground: 'media/supporters/profile-background-4.webp',
            alt: 'Asian woman, smiling and looking kind',
          },
          {
            id: 5,
            profile: 5,
            name: t`meet-supporters.cards.card-5.name`,
            description: t`meet-supporters.cards.card-5.description`,
            avatar: 'media/supporters/avatar-jeba.webp',
            background: 'media/supporters/supporter-background-1.webp',
            backgroundMobile:
              'media/supporters/supporter-background-1-mobile.webp',
            profileBackground: 'media/supporters/profile-background-1.webp',
            alt: 'Middle Eastern woman looking energetic, kind and friendly',
          },
          {
            id: 6,
            profile: 6,
            name: t`meet-supporters.cards.card-6.name`,
            description: t`meet-supporters.cards.card-6.description`,
            avatar: 'media/supporters/avatar-bao.webp',
            background: 'media/supporters/supporter-background-2.webp',
            backgroundMobile:
              'media/supporters/supporter-background-2-mobile.webp',
            profileBackground: 'media/supporters/profile-background-2.webp',
            alt: 'Asian woman with a little smile, looking kind and empathetic',
          },
          {
            id: 7,
            profile: 7,
            name: t`meet-supporters.cards.card-7.name`,
            description: t`meet-supporters.cards.card-7.description`,
            avatar: 'media/supporters/avatar-wen-xin.webp',
            background: 'media/supporters/supporter-background-3.webp',
            backgroundMobile:
              'media/supporters/supporter-background-3-mobile.webp',
            profileBackground: 'media/supporters/profile-background-3.webp',
            alt: 'Asian woman with glasses, a kind smile and friendly attitude',
          },
          {
            id: 8,
            profile: 8,
            name: t`meet-supporters.cards.card-8.name`,
            description: t`meet-supporters.cards.card-8.description`,
            avatar: 'media/supporters/avatar-thao.webp',
            background: 'media/supporters/supporter-background-4.webp',
            backgroundMobile:
              'media/supporters/supporter-background-4-mobile.webp',
            profileBackground: 'media/supporters/profile-background-4.webp',
            alt: 'Asian woman with glasses, a kind smile and friendly attitude',
          },
        ];

  const desktopCards = [
    cards[cards.length - 1],
    ...cards,
    // Empty card for just showing
    {
      id: 10,
      profile: null,
      name: '',
      description: '',
      avatar: '',
      background: '',
      backgroundMobile: '',
      profileBackground: '',
      alt: '',
    },
  ];

  const profiles =
    i18n.language === 'vn'
      ? [
          {
            id: 6,
            image: 'media/supporters/profile-bao.webp',
            title: t`meet-supporters.profiles.profile-6.title`,
            language: t`meet-supporters.profiles.profile-6.language`,
            description: t`meet-supporters.profiles.profile-6.description`,
            area: t`meet-supporters.profiles.profile-6.area`,
          },
          {
            id: 8,
            image: 'media/supporters/profile-thao.webp',
            title: t`meet-supporters.profiles.profile-8.title`,
            language: t`meet-supporters.profiles.profile-8.language`,
            description: t`meet-supporters.profiles.profile-8.description`,
            area: t`meet-supporters.profiles.profile-8.area`,
          },
          {
            id: 9,
            image: 'media/supporters/profile-an.webp',
            title: t`meet-supporters.profiles.profile-9.title`,
            language: t`meet-supporters.profiles.profile-9.language`,
            description: t`meet-supporters.profiles.profile-9.description`,
            area: t`meet-supporters.profiles.profile-9.area`,
          },
          {
            id: 10,
            image: 'media/supporters/profile-lam.webp',
            title: t`meet-supporters.profiles.profile-10.title`,
            language: t`meet-supporters.profiles.profile-10.language`,
            description: t`meet-supporters.profiles.profile-10.description`,
            area: t`meet-supporters.profiles.profile-10.area`,
          },
          {
            id: 11,
            image: 'media/supporters/profile-han.webp',
            title: t`meet-supporters.profiles.profile-11.title`,
            language: t`meet-supporters.profiles.profile-11.language`,
            description: t`meet-supporters.profiles.profile-11.description`,
            area: t`meet-supporters.profiles.profile-11.area`,
          },
          {
            id: 12,
            image: 'media/supporters/profile-nhi.webp',
            title: t`meet-supporters.profiles.profile-12.title`,
            language: t`meet-supporters.profiles.profile-12.language`,
            description: t`meet-supporters.profiles.profile-12.description`,
            area: t`meet-supporters.profiles.profile-12.area`,
          },
          {
            id: 13,
            image: 'media/supporters/profile-tran.webp',
            title: t`meet-supporters.profiles.profile-13.title`,
            language: t`meet-supporters.profiles.profile-13.language`,
            description: t`meet-supporters.profiles.profile-13.description`,
            area: t`meet-supporters.profiles.profile-13.area`,
          },
        ]
      : [
          {
            id: 1,
            image: 'media/supporters/profile-pei-shan.webp',
            title: t`meet-supporters.profiles.profile-1.title`,
            language: t`meet-supporters.profiles.profile-1.language`,
            description: t`meet-supporters.profiles.profile-1.description`,
            area: t`meet-supporters.profiles.profile-1.area`,
          },
          {
            id: 2,
            image: 'media/supporters/profile-pradnya.webp',
            title: t`meet-supporters.profiles.profile-2.title`,
            language: t`meet-supporters.profiles.profile-2.language`,
            description: t`meet-supporters.profiles.profile-2.description`,
            area: t`meet-supporters.profiles.profile-2.area`,
          },
          {
            id: 3,
            image: 'media/supporters/profile-ana.webp',
            title: t`meet-supporters.profiles.profile-3.title`,
            language: t`meet-supporters.profiles.profile-3.language`,
            description: t`meet-supporters.profiles.profile-3.description`,
            area: t`meet-supporters.profiles.profile-3.area`,
          },
          {
            id: 4,
            image: 'media/supporters/profile-yoke.webp',
            title: t`meet-supporters.profiles.profile-4.title`,
            language: t`meet-supporters.profiles.profile-4.language`,
            description: t`meet-supporters.profiles.profile-4.description`,
            area: t`meet-supporters.profiles.profile-4.area`,
          },
          {
            id: 5,
            image: 'media/supporters/profile-jeba.webp',
            title: t`meet-supporters.profiles.profile-5.title`,
            language: t`meet-supporters.profiles.profile-5.language`,
            description: t`meet-supporters.profiles.profile-5.description`,
            area: t`meet-supporters.profiles.profile-5.area`,
          },
          {
            id: 6,
            image: 'media/supporters/profile-bao.webp',
            title: t`meet-supporters.profiles.profile-6.title`,
            language: t`meet-supporters.profiles.profile-6.language`,
            description: t`meet-supporters.profiles.profile-6.description`,
            area: t`meet-supporters.profiles.profile-6.area`,
          },
          {
            id: 7,
            image: 'media/supporters/profile-wen-xin.webp',
            title: t`meet-supporters.profiles.profile-7.title`,
            language: t`meet-supporters.profiles.profile-7.language`,
            description: t`meet-supporters.profiles.profile-7.description`,
            area: t`meet-supporters.profiles.profile-7.area`,
          },
          {
            id: 8,
            image: 'media/supporters/profile-thao.webp',
            title: t`meet-supporters.profiles.profile-8.title`,
            language: t`meet-supporters.profiles.profile-8.language`,
            description: t`meet-supporters.profiles.profile-8.description`,
            area: t`meet-supporters.profiles.profile-8.area`,
          },
        ];

  return (
    <>
      <Swipe
        className="hidden md:block pb-[5px] md:pb-[10px]"
        currentStepList={Array(desktopCards.length).fill('')}
        cardWidth={CARD_WIDTH}
        gap={GAP}
        boundaryPadding={BOUNDARY_PADDING}
      >
        {desktopCards.map((card, index) => {
          const className =
            index === 0
              ? 'md:pr-[33.5px] md:pl-[67px]'
              : index === desktopCards.length - 1
                ? // ? 'md:pl-[33.5px] md:pr-[67px]'
                  'md:pl-[33.5px]'
                : 'md:px-[33.5px]';

          return (
            <Card
              name={card.name}
              key={card.name + card.id + index}
              description={card.description}
              avatar={card.avatar}
              background={card.background}
              className={className}
              handleClick={() => setSelectedSupporter(card.profile)}
              alt={card.alt}
            />
          );
        })}
      </Swipe>
      {/* TODO: Filter to remove card with empty in mobile */}
      <MobileSwipe dataList={cards}>
        {cards.map((card, index) => {
          const className = 'min-w-full flex justify-center';

          return (
            <Card
              name={card.name}
              key={card.name + card.id + index}
              description={card.description}
              avatar={card.avatar}
              background={card.background}
              className={className}
              handleClick={() => setSelectedSupporter(card.profile)}
              alt={card.alt}
            />
          );
        })}
      </MobileSwipe>

      <PopUp
        isOpen={!!selectedSupporter}
        onClose={() => {
          setSelectedSupporter(null);
        }}
        className="rounded-[25px] w-[88%] md:w-[93.75%] h-[99.desktopCards.length%] md:h-[613px]"
        isGlobalOverlap={true}
      >
        <Profile
          handleClose={() => setSelectedSupporter(null)}
          profile={profiles.find((profile) => profile.id === selectedSupporter)}
          card={cards.find((card) => card.profile === selectedSupporter)}
          profileBackground={
            cards.find((card) => card.profile === selectedSupporter)
              ?.profileBackground
          }
        />
      </PopUp>
    </>
  );
}

interface CardProps {
  name: string;
  description: string;
  avatar: string;
  background: string;
  className?: string;
  handleClick: () => void;
  alt: string;
}

function Card(props: CardProps) {
  const { t } = useTranslation('become-a-listener');
  const { name, description, avatar, background, className, handleClick, alt } =
    props;

  const emptyCard = name === '';
  const emptyCardWith = (window.innerWidth - 2 * GAP - CARD_WIDTH) / 2 - 16; // TODO: 16 is just a delta param, maybe have a more robust solution

  if (emptyCard) {
    return (
      <div className={clsx(className)}>
        <div
          className="relative md:w-[656px] md:h-[420px] overflow-hidden"
          style={{
            ...(window.innerWidth >= 768 && {
              width: `${emptyCardWith}px`,
            }),
          }}
        ></div>
      </div>
    );
  }

  return (
    <div className={clsx(className)}>
      <div
        className="relative w-[80%] md:w-[656px] h-[460px] md:h-[420px] rounded-[20px] overflow-hidden shadow-[0px_4px_12px_rgba(0,0,0,0.2)] flex flex-col"
        style={{
          ...(emptyCard && window.innerWidth >= 768
            ? { width: `${emptyCardWith}px` } // Apply dynamic width only for md screens and above
            : {}),
        }}
      >
        <div className="absolute top-[7px] left-1/2 -translate-x-1/2">
          <img
            src={avatar}
            alt={alt}
            className="w-[123px] h-[123px] md:w-[197px] md:h-[197px]"
          />
        </div>
        <div>
          <img
            src={background}
            alt={name + 'background'}
            className="h-[91px] md:h-[124px] object-cover"
          />
        </div>

        <div className="md:px-[44px] mt-[65px] md:mt-[92px] text-center flex-1 flex flex-col justify-between">
          <div>
            <p className="font-extrabold text-[28px] leading-[36px] md:mb-[8px]">
              {name}
            </p>
            <p className="md:text-base md:leading-[24px] px-[20px] md:px-0">
              {description}
            </p>
          </div>

          <p
            className="md:text-[13px] text-[#A5A5A7] underline pb-[20px] md:pb-[24px] cursor-pointer select-none"
            onClick={handleClick}
          >
            {t`meet-supporters.cards.read-more`}
          </p>
        </div>
      </div>
    </div>
  );
}

interface ProfileProps {
  handleClose: () => void;
  profile:
    | {
        id: number;
        image: string;
        title: string;
        language: string;
        description: string | React.ReactNode;
        area: string;
      }
    | undefined;
  card: CardItem | undefined;
  profileBackground?: string;
}

function Profile(props: ProfileProps) {
  const { t } = useTranslation('become-a-listener');
  const { handleClose, profile, card, profileBackground } = props;

  if (!profile) return null;

  return (
    <div
      className={`relative box-border md:w-full h-full md:h-[613px] p-[32px] md:py-[63px] md:px-[37px]`}
    >
      <div
        className="absolute left-0 top-0 w-full h-full bg-white"
        style={{ zIndex: -1 }}
      >
        <img
          src={profileBackground}
          alt="Profile Background"
          className="hidden md:block absolute left-0 top-0 w-full object-cover"
        />
        <img
          src={card?.backgroundMobile}
          alt="Profile Background"
          className="md:hidden absolute left-0 top-0 w-full object-cover"
        />
      </div>

      <div
        className="absolute right-[18px] md:right-[37px] top-[28px] w-[15px] h-[15px] md:w-auto md:h-auto cursor-pointer"
        onClick={() => {
          handleClose();
        }}
      >
        <NormalObject
          data={closeIcon}
          className="w-[15px] h-[15px] object-contain"
        />
      </div>

      <div className="flex flex-col md:flex-row md:gap-[28px] h-full overflow-hidden">
        <div className="flex flex-col items-center gap-[20px]">
          <div className="md:hidden pt-[20px] font-extrabold text-center text-[28px] leading-[36px]">
            {profile.title}
          </div>

          <img
            src={profile.image}
            alt="Profile"
            className="w-[160px] h-[244px] md:w-auto md:h-auto"
          />
        </div>

        <div className="flex-1 mt-[20px] md:mt-0 overflow-auto">
          <div className="hidden md:flex flex-row justify-between items-center md:mb-[32px]">
            <span className="font-bold md:text-4xl md:leading-[40px]">
              {profile.title}
            </span>
            <span className="md:text-lg">
              <span className="font-bold">
                {t`meet-supporters.cards.language`}{' '}
              </span>
              {profile.language}
            </span>
          </div>
          <div className="text-[13px] md:text-lg leading-[20px] md:leading-[26px]">
            <p>{profile.description}</p>
            <div className="mt-[20px] md:mt-[40px]">
              <p className="font-bold mb-[4px]">
                {t`meet-supporters.cards.area`}
              </p>
              <p>{profile.area}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
