import React, { useContext, useState } from 'react';
import { FeelingDescription } from '@source/types';

import { useBookingContext } from './BookingContext';
import Option from './components/Option';
import { useTranslation } from 'react-i18next';

// TODO: Fix this anchor TOO_MANY_WORRIES -> OTHER

export function Step04() {
  const { t } = useTranslation('booking');
  const { setCurrentStep, dataList04, setDataList04 } = useBookingContext();
  const options = [
    {
      label: t`questions.question-4.options.option-1`,
      value: FeelingDescription.NERVOUS,
    },
    {
      label: t`questions.question-4.options.option-2`,
      value: FeelingDescription.UNABLE_TO_RELAX,
    },
    {
      label: t`questions.question-4.options.option-3`,
      value: FeelingDescription.SOMETHING_AWFUL,
    },
    {
      label: t`questions.question-4.options.option-4`,
      value: FeelingDescription.UNCONTROLLED_WORRYING,
    },
    {
      label: t`questions.question-4.options.option-5`,
      value: FeelingDescription.RESTLESS,
    },
    {
      label: t`questions.question-4.options.option-6`,
      value: FeelingDescription.UNABLE_TO_CONCENTRATE,
    },
    {
      label: t`questions.question-4.options.option-7`,
      value: FeelingDescription.URGE_TO_ESCAPE,
    },
    {
      label: t`questions.question-4.options.option-8`,
      value: FeelingDescription.OBSESSIVE_THOUGHTS,
    },
    {
      label: t`questions.question-4.options.option-9`,
      value: FeelingDescription.TOO_MANY_WORRIES,
    },
  ];

  return (
    <>
      <h1 className="booking-step-title">{t`questions.question-4.title`}</h1>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] md:gap-y-[16px] md:gap-x-[20px] mt-[30px] md:mt-[40px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList04.includes(option.value)}
            onClick={() => {
              let newList = [...dataList04];
              if (!dataList04.includes(option.value))
                newList.push(option.value);
              else {
                newList = [...newList.filter((item) => item !== option.value)];
              }

              setDataList04(newList);
            }}
            themeColor="md:bg-[#D8FFF3]"
            mobileThemeColor="bg-[#D8CFFC]"
            // height="md:h-[90px]"
            height="md:h-[80px]" // Change to fit the screen
            mobileHeight="h-[70px]"
          />
        ))}
      </div>
    </>
  );
}
