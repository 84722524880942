import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@components/Button';
import appLogo from '@assets/logo.svg';
import closeNavMenu from '@assets/icons/shared/close-nav-menu.svg';

const MobileNavLinks = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation('header');

  const links = [
    { path: '/team-juni', label: t`team-hey-juni` },
    { path: '/mission-and-vision', label: t`mission-vision` },
    { path: '/learn-more', label: t`our-services` },
    { path: '/become-a-listener', label: t`become-peer-counselor` },
    { path: '/pricing', label: t`pricing` },
    { path: '/blog', label: t`blog` },
  ];

  return (
    <>
      {links.map(({ path, label }) => (
        <Link
          key={path}
          to={path}
          onClick={onClick}
          className="font-extrabold leading-[36px] text-[28px]"
        >
          {label}
        </Link>
      ))}
    </>
  );
};

export default function MobileMenu({
  toggleMenu,
  navigate,
}: {
  toggleMenu: () => void;
  navigate: (path: string) => void;
}) {
  const { t } = useTranslation('header');
  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-white z-[999]"
      style={{ touchAction: 'none' }}
    >
      <header className="sticky top-0 bg-white z-50 h-[62px] md:h-[80px]">
        <nav className="mx-auto flex items-center justify-between px-[20px] h-full">
          <img
            src={appLogo}
            className="w-[64px] h-[44px]"
            onClick={() => {
              navigate('/');
            }}
            alt="HeyJuni Logo"
          />
          <div onClick={toggleMenu}>
            <img
              src={closeNavMenu}
              className="w-[19px] h-[12px]"
              alt="Close Icon"
            />
          </div>
        </nav>
      </header>
      <div className="flex flex-col gap-[36px] px-[28px] pt-[42px] text-3xl font-extrabold">
        <MobileNavLinks onClick={toggleMenu} />
        <Button
          label={t`book-session`}
          className="font-semibold text-lg w-full h-[50px] rounded-[20px]"
          onClick={() => {
            navigate('/booking');
            toggleMenu();
          }}
        />
      </div>
    </div>
  );
}
