import React from 'react';

export default function TheMentalTollOfSexualViolence() {
  return (
    <div>
      <img
        src="media/blog/articles/the-mental-toll-of-sexual-violence.webp"
        alt="the-mental-toll-of-sexual-violence"
        className="w-full"
      />
      <p className="mt-5 text-[15px] md:text-lg">
        Sexual violence (SV) is a deeply traumatic experience that can have
        lasting effects on mental health. Those who have been assaulted often
        face a complex range of emotions, from shame and anger to anxiety and
        depression. For many, the impact is long-term, influencing their
        relationships, self-perception, and day-to-day lives. Recognizing the
        mental health effects of sexual violence is essential as it can open
        pathways to healing, empowerment, and growth.
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        This article dives into the mental health consequences of sexual
        violence, with interactive tools and prompts to help you or your loved
        ones understand, process, and find support. Remember, there's no "right"
        way to feel or heal—every journey is unique.
      </p>
      <p className="text-xl md:text-4xl font-bold mt-16">
        The Immediate Effects: Trauma Response
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        After experiencing SV, many often go into "survival mode," a response
        rooted in our biology. This can manifest as:
      </p>
      <ol className="mt-5 list-disc list-inside inline-block text-[15px] md:text-lg">
        <li>
          Shock and Disbelief: People may feel numb or detached, unable to
          process what happened. This is a natural response, as the mind tries
          to shield itself from overwhelming pain.
        </li>
        <li>
          Hyperarousal (Fight or Flight): Many experience heightened anxiety,
          making them hyper-aware of their surroundings, as if they're in
          constant danger.
        </li>
        <li>
          {' '}
          Avoidance: Some might avoid certain places, people, or situations that
          remind them of the assault.
        </li>
      </ol>
      <p className="mt-5 text-[15px] md:text-lg">
        Take a moment to check in with yourself or someone around you who has
        experienced SV. Have you noticed any patterns or triggers that seem
        linked to a specific memory? Writing these down can be a first step in
        recognizing and eventually reducing these responses.*
      </p>
      <p className="text-xl md:text-4xl font-bold mt-16">
        Common Forms of Sexual Violence
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        Sexual violence can take many forms, and understanding these
        distinctions can help in recognizing and addressing them:
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        1. Sexual Harassment
      </p>
      <p className="text-[15px] md:text-lg">
        Often manifesting in workplaces, schools, and even public spaces, sexual
        harassment includes unwelcome advances, comments, or actions of a sexual
        nature. This might look like making unwanted sexual jokes, sending
        explicit texts, or repeatedly asking for dates despite being turned
        down. While it may be dismissed by some as "harmless," sexual harassment
        can lead to lasting psychological and emotional harm.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        2. Sexual Assault and Rape
      </p>
      <p className="text-[15px] md:text-lg">
        Sexual assault is any unwanted sexual contact, ranging from groping to
        more invasive forms of assault. Rape is a specific type of sexual
        assault that involves forced or non-consensual penetration. Both can
        have serious physical, mental, and emotional repercussions. Importantly,
        acts like rape aren't about sexual gratification; they're about control,
        power, and stripping the person being assaulted of their autonomy.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        3. Coercion and Manipulation
      </p>
      <p className="text-[15px] md:text-lg">
        Sometimes, sexual violence isn't violent in the traditional sense but
        relies on manipulation or pressure. Coercion can involve making someone
        feel obligated or intimidated into sexual acts through guilt, blackmail,
        or emotional manipulation.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        4. Online Sexual Harassment and Image-Based Abuse
      </p>
      <p className="text-[15px] md:text-lg">
        With the rise of digital spaces, sexual violence has extended online.
        Image-based abuse (such as non-consensual sharing of intimate images)
        and online harassment are forms of sexual violence that can be just as
        harmful and invasive as physical acts.
      </p>

      <p className="text-xl md:text-4xl font-bold mt-16">
        Social & Relational Impact
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        Sexual violence affects not just the individual but also their
        interactions and relationships with others. Those affected might
        experience:
      </p>
      <ol className="mt-5 list-disc list-inside inline-block text-[15px] md:text-lg">
        <li>
          Difficulty Trusting Others: People who have experienced SV may
          struggle to trust people, especially in romantic relationships. The
          fear of betrayal or harm can lead to feelings of isolation and
          avoidance.
        </li>
        <li>
          Relationship Strain: For those already in relationships, SV can put
          significant stress on their partner or loved ones, as they navigate
          their own emotions and communication needs.
        </li>
        <li>
          Social Withdrawal: Due to feelings of shame or fear of judgment, those
          affected may avoid social situations or distance themselves from
          friends and family.
        </li>
      </ol>
      <p className="mt-5 text-[15px] md:text-lg">
        Think about a relationship that feels safe to you, whether with a
        friend, family member, or therapist. How does this person make you feel
        supported? Reflecting on this can help identify qualities and
        relationships that aid healing.
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        Understanding the mental health impact of sexual violence is a critical
        step toward fostering awareness, compassion, and meaningful support. The
        emotional scars left by sexual violence can be profound, but recovery is
        possible with the right tools, resources, and community. Recognizing and
        validating the range of emotions and challenges survivors face can help
        reduce stigma and pave the way for healing.
      </p>
    </div>
  );
}
