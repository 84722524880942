import React, { useContext, useState } from 'react';
import { PreferredMode } from '@source/types';

import { useBookingContext } from './BookingContext';
import Option from './components/Option';
import { useTranslation } from 'react-i18next';

export function Step09() {
  const { t } = useTranslation('booking');
  const { setCurrentStep, dataList09, setDataList09 } = useBookingContext();

  const options = [
    {
      label: t`questions.question-9.options.option-1`,
      value: PreferredMode.TEXT,
    },
    {
      label: t`questions.question-9.options.option-2`,
      value: PreferredMode.VOICE,
    },
    {
      label: t`questions.question-9.options.option-3`,
      value: PreferredMode.VIDEO,
    },
  ];

  return (
    <>
      <h1 className="booking-step-title">{t`questions.question-9.title`}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-[30px] md:mt-[55px] gap-[15px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList09.includes(option.value)}
            onClick={() => {
              setDataList09([option.value]);
            }}
            themeColor="md:bg-[#D8CFFC]"
            mobileThemeColor="bg-[#D8FFF3]"
            height="md:h-[75px]"
            mobileHeight="h-[60px]"
          />
        ))}
      </div>
    </>
  );
}
