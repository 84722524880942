import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Configure i18next with namespaces
i18n
  .use(HttpBackend) // Enables loading translations via HTTP
  .use(LanguageDetector) // Detects the user's language
  .use(initReactI18next) // Connects i18n to React
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    ns: [
      'about',
      'become-a-listener',
      'blog',
      'booking',
      'common',
      'faq',
      'footer',
      'header',
      'home',
      'learn-more',
      'meet-the-team',
      'mission-and-vision',
      'navigation',
      'pricing',
      'subscribe',
      'crisis-helpline',
      'contact-us',
    ], // Declare namespaces
    defaultNS: 'common', // Default namespace
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Path for translation files
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
