import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@components/Button';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';

import instagram from '@assets/icons/shared/instagram.svg';
import linkedIn from '@assets/icons/shared/linked-in.svg';
import tiktok from '@assets/icons/shared/tiktok.svg';
import youtube from '@assets/icons/shared/youtube.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NormalObject from '@components/NormalObject';
import { trpc } from '@src/App';

export default function Unsubscribe() {
  const navigate = useNavigate();
  const { t } = useTranslation('unsubscribe');

  // Get unsubscribeKey from URL
  const [searchParams] = useSearchParams();
  const unsubscribeKey = searchParams.get('unsubscribeKey');

  const options = useOptions();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const [isUnsubscribeClicked, setIsUnsubscribeClicked] = useState(false);
  const [status, setStatus] = useState<Status>(Status.THINKING);

  const unsubscribeMutation = trpc.subscription.unsubscribe.useMutation();

  const unsubscribe = async () => {
    if (!unsubscribeKey) {
      alert('Invalid unsubscribe key');
      return;
    }
    const unsubscribeReason = selectedOption;
    if (!unsubscribeReason) return;
    try {
      await unsubscribeMutation.mutateAsync({
        unsubscribeKey,
        unsubscribeReason,
      });
      setStatus(Status.UNSUBSCRIBED);
    } catch (error) {
      alert(`Failed to unsubscribe, please try again later. Error: ${error}`);
    }
  };

  return (
    <div className="relative">
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>Unsubscribe - HeyJuni</title>
        <meta
          name="description"
          content="We're sorry to see you go. Let us know why you're unsubscribing and how we can improve."
        />
        <meta
          name="keywords"
          content="unsubscribe, email preferences, HeyJuni, stop receiving emails"
        />
        <meta property="og:title" content="Unsubscribe from HeyJuni" />
        <meta
          property="og:description"
          content="Manage your email preferences and let us know why you're unsubscribing."
        />
      </Helmet>
      <div
        className={clsx(
          'relative flex flex-col h-screen md:h-auto items-start md:items-center justify-between md:justify-center md:bg-[#AF9CFB] md:py-[28px] overflow-hidden'
        )}
        style={
          window.innerWidth < 768
            ? { height: `${window.innerHeight - 62}px`, overflow: 'auto' }
            : undefined
        }
      >
        <img
          src={`unsubscribe/background.png`}
          alt="Background"
          className="absolute top-0 left-0 w-full object-cover"
        />

        <div
          className={clsx(
            'relative flex flex-col justify-between bg-white pt-[25px] px-[28px] md:rounded-[40px] md:shadow-lg z-10 w-full md:w-[83.4375%] md:px-[120px] md:py-[40px] h-auto md:h-auto md:min-h-[calc(100vh-146px)]'
          )}
        >
          <img
            src={`unsubscribe/${!isUnsubscribeClicked || status === Status.STAY ? 'green' : 'purple'}-graphic-shape.png`}
            alt="Background"
            className="absolute top-0 left-1/2 transform -translate-x-1/2 z-[-1]"
          />

          {!isUnsubscribeClicked && (
            <div className="space-y-10">
              <div className="text-center font-bold text-4xl leading-[40px]">
                {t('love-to-see-stay')}
              </div>
              <div className="flex flex-row gap-5">
                <div className="w-1/2">
                  <div className="font-semibold text-lg leading-[22px]">
                    {t('leaving-means-missing')}
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="font-semibold text-lg leading-[22px]">
                    {t('understand-decision')}
                  </div>
                  <div className="md:mt-5">
                    {options.map((option) => (
                      <label
                        key={option.value}
                        className="flex items-center mb-3 cursor-pointer"
                      >
                        <input
                          type="radio"
                          name="unsubscribe-reason"
                          value={option.value}
                          checked={selectedOption === option.value}
                          onChange={() => setSelectedOption(option.value)}
                          className="peer hidden"
                        />
                        <div className="mr-5 relative w-6 h-6 border-2 border-gray-400 rounded-full flex items-center justify-center">
                          <div
                            className={clsx(
                              'absolute w-3 h-3 rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
                              {
                                'bg-purple-500':
                                  selectedOption === option.value,
                              }
                            )}
                          ></div>
                        </div>
                        <span className="text-lg">{option.label}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {isUnsubscribeClicked && <StatusContent status={status} />}

          {!isUnsubscribeClicked && (
            <div
              className={clsx(
                'flex gap-5',
                selectedOption ? 'justify-center' : 'justify-start'
              )}
            >
              <CustomButton
                handleAction={() => navigate('/')}
                label={t('like-to-stay')}
              />
              {selectedOption && (
                <CustomButton
                  handleAction={() => setIsUnsubscribeClicked(true)}
                  label={
                    <span>
                      {t('wish')}
                      <span className="underline">{t('unsubscribe')}</span>.
                    </span>
                  }
                  className="bg-[#FFC700] hover:bg-[#FFC700]"
                />
              )}
            </div>
          )}

          {isUnsubscribeClicked && status === Status.THINKING && (
            <div className={'flex gap-5 justify-center'}>
              <CustomButton
                handleAction={() => setStatus(Status.STAY)}
                label={t('change-my-mind')}
              />
              <CustomButton
                handleAction={unsubscribe}
                label={t('decide-to-unsubscribe')}
                className="bg-[#FFC700] hover:bg-[#FFC700]"
              />
            </div>
          )}

          {isUnsubscribeClicked && status === Status.UNSUBSCRIBED && (
            <div className={'flex gap-5 justify-center'}>
              <CustomButton
                handleAction={() => navigate('/')}
                label={t('back-home')}
              />
            </div>
          )}

          {isUnsubscribeClicked && status === Status.STAY && (
            <div className="space-y-5">
              <div className="text-center font-semibold text-lg">
                {t('explore')}
              </div>
              <div className={'flex gap-5 justify-center'}>
                <CustomButton
                  handleAction={() => navigate('/blog/highlights')}
                  label={t('explore-blog')}
                />
              </div>
              <div className="flex flex-row justify-center gap-5 pt-5">
                {socialLinks.map((link) => (
                  <a
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={link.alt}
                  >
                    <NormalObject
                      data={link.src}
                      className="h-6 md:h-[20px] md:w-[20px] transition-transform duration-100 transform hover:scale-110"
                    />
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

interface CustomButtonProps {
  handleAction: () => void;
  label: string | React.ReactNode;
  className?: string;
}

function CustomButton(props: CustomButtonProps) {
  const { className, handleAction, label } = props;

  return (
    <Button
      label={label}
      className={clsx(
        'w-1/2 h-[50px] md:h-[60px] px-[30px] rounded-[24px] font-semibold text-lg md:text-lg hover:bg-[#FEE60C] active:bg-[#977CFF]',
        className
      )}
      onClick={handleAction}
    />
  );
}

enum Status {
  THINKING = 'THINKING',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  STAY = 'STAY',
}

interface StatusContentProps {
  status: Status;
}

function StatusContent(props: StatusContentProps) {
  const { status } = props;
  const { t } = useTranslation('unsubscribe');

  const iconSrc = {
    [Status.THINKING]: 'unsubscribe/sad-icon.png',
    [Status.UNSUBSCRIBED]: 'unsubscribe/missed-icon.png',
    [Status.STAY]: 'unsubscribe/glad-icon.png',
  };

  return (
    <div className="flex flex-col gap-10 items-center">
      <div className="relative">
        <img src={iconSrc[status]} alt={t(`titles.${status}`)} />
        {status === Status.STAY && (
          <>
            <img
              className="absolute -top-1 -left-4"
              src="unsubscribe/star-icon.png"
              alt={'Star icon'}
            />
            <img
              className="absolute -right-4 -bottom-1"
              src="unsubscribe/star-icon.png"
              alt={'Star icon'}
            />
          </>
        )}
      </div>
      <div className="font-bold text-4xl">{t(`titles.${status}`)}</div>
      <div className="text-lg text-center">
        {status !== Status.STAY ? (
          t(`descriptions.${status}`)
        ) : (
          <>
            <div>{t(`descriptions.${status}`).split('. ')[0] + '.'}</div>
            <div>{t(`descriptions.${status}`).split('. ')[1]}</div>
          </>
        )}
      </div>
    </div>
  );
}

function useOptions() {
  const { t } = useTranslation('unsubscribe');

  const optionValues = [
    'NO_EMAILS',
    'TOO_FREQUENT',
    'DONT_REMEMBER',
    'NO_INTEREST',
    'NOT_RELEVANT',
    'DECLUTTER',
  ];

  const options = optionValues.map((value) => ({
    label: t(`options.${value}`),
    value,
  }));

  return options;
}

const socialLinks = [
  {
    href: 'https://www.instagram.com/heyjuni_official/',
    src: instagram,
    alt: 'Instagram',
  },
  {
    href: 'https://www.youtube.com/@HeyJuni_official',
    src: youtube,
    alt: 'YouTube',
  },
  {
    href: 'https://www.linkedin.com/company/heyjuni/posts/?feedView=all',
    src: linkedIn,
    alt: 'LinkedIn',
  },
  {
    href: 'https://www.tiktok.com/discover/heyjuni',
    src: tiktok,
    alt: 'TikTok',
  },
];
