import closeIcon from '@assets/icons/shared/close-icon.svg';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

interface TermConditionContentProps {
  onClose: () => void;
}

export function TermConditionContent(props: TermConditionContentProps) {
  const { t } = useTranslation('booking');
  const { onClose } = props;

  return (
    <div className="relative p-[32px] max-h-[80vh]">
      <div
        className="absolute right-[23px] top-[23px] cursor-pointer w-[15px] h-[15px] md:w-auto md:h-auto flex items-center justify-center"
        onClick={onClose}
      >
        <NormalObject
          data={closeIcon}
          className="w-full h-full object-contain"
        />
      </div>
      <div className="text-[15px] md:text-lg overflow-auto max-h-[calc(80vh-44px)]">
        <p className="text-2xl font-bold">1. Use of the Service</p>
        <br />
        <p>
          IMPORTANT - PLEASE READ THESE TERMS CAREFULLY. BY USING THIS SERVICE,
          YOU ACKNOWLEDGE THAT YOU HAVE READ, COMPREHENDED, ACCEPTED AND AGREED
          WITH THESE TERMS OF SERVICE. IN ORDER TO USE THE SERVICE, YOU MUST
          AGREE TO THE TERMS OF SERVICE THAT ARE SET OUT BELOW. YOU FURTHER
          AGREE TO THE REPRESENTATIONS MADE BY YOURSELF BELOW. IF YOU DO NOT
          AGREE TO THE TERMS OF SERVICE, PLEASE REFRAIN FROM USING THIS SERVICE
          FURTHER.
        </p>
        <br />
        <p>
          The Terms of Service stated herein constitute a legally binding
          agreement between you and HeyJuni.
        </p>
        <br />
        <p>
          HeyJuni("HeyJuni," "we," "us" and "our") operates the Platform
          https://heyjuni.com. We provide a service which enables users to have
          secure messaging and engage third-party listeners who are trained to
          respond to chat requests.
        </p>
        <br />
        <p>
          By using the Service, and downloading, installing or using any
          associated software or application supplied by us, you hereby
          expressly acknowledge and agree to be bound by the Terms of Service,
          and any future amendments and additions.
        </p>
        <br />
        <p>
          We reserve the right to modify, vary and change the Terms of Service
          or its policies relating to the Service without prior notice at any
          time as it deems fit. Such modifications, variations and or changes to
          the Terms of Service or its policies relating to the Service shall be
          effective upon the posting of an updated version as may be updated and
          revised by us from time to time.
        </p>
        <br />
        <p>
          You agree that it shall be your responsibility to review the Terms of
          Service regularly. The continued use of the Service after any such
          changes, whether or not reviewed by you, shall constitute your consent
          and acceptance to such changes.
        </p>
        <br />
        <p>
          THE PLATFORM AND SERVICE SHOULD NOT BE USED FOR MEDICAL EMERGENCIES.
          IF YOU ARE FACING A MEDICAL EMERGENCY, YOU SHOULD CONTACT "995" OR
          YOUR LOCAL EMERGENCY MEDICAL SERVICES PROVIDER IMMEDIATELY. LISTENERS
          ARE NOT TRAINED OR QUALIFIED TO ASSIST THOSE IN CRISIS.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">2. Listener Service</p>
        <br />
        <p>
          HeyJuni is a digital mental healthcare platform which enables speakers
          to interact using messaging communication technology where the
          speakers and the Listeners are at different physical locations.
        </p>
        <br />
        <p>
          Listeners go through our in-house training program and solely provide
          emotional comfort and support. The services you receive from the
          Listeners are not intended to replace a counselling, psychiatrist or
          primary care physician relationship. You should seek further help or
          follow-up care when needed, and continue to consult with your primary
          care physician and other healthcare professionals. The Listeners are
          independent providers who are neither our employees nor agents nor
          representatives, therefore we cannot predict or assess the competence
          of, or appropriateness for your needs, of the Listeners. The
          Platform's role is limited to enabling the "Chat With Listeners"
          Services for emotional comfort, therefore we are not responsible for
          any interactions with Listeners(whether provided through the Platform
          or not).
        </p>
        <p>
          While using the Services, you are required to comply with all
          applicable statutes, orders, regulations, rules and other laws. You
          are prohibited to, or assist any other persons to:
        </p>
        <ol className="list-disc list-inside inline-block">
          <li>
            use the Services to send harassing, abusive, or threatening
            messages;
          </li>
          <li>
            soliciting passwords or personal information for commercial or
            unlawful purposes, including hidden pages, links or images,
            providing instructional information about illegal activities, or
            which threatens our relationships with our partners, customers or
            suppliers;
          </li>
          <li>
            attempt to conceal or misrepresent the identity of the speaker, or
            otherwise invade someone's privacy;
          </li>
          <li>
            disrupt the normal flow of the Services, including any dialogue on
            the Services or otherwise act in a manner that negatively affects
            other participants;
          </li>
          <li>
            transmit through the Services any information, data, text, files,
            links, software, or other materials that are unlawful, harmful,
            threatening, abusive, harassing, defamatory, vulgar, obscene,
            pornographic, hateful, racially, ethnically or otherwise
            objectionable;
          </li>
          <li>
            use the Services to stalk, harass, abuse, mock, ridicule,
            intimidate, disparage, defame, threaten, defraud, or otherwise
            mistreat or harm any person or class of people;
          </li>
          <li>
            use the Services that is intentionally misleading, false, or
            otherwise inappropriate or with the purpose of harming others,
            regardless of whether the Content or its dissemination is unlawful;
          </li>
          <li>
            use the Services that has any risk or possibility of exploiting,
            harming, or endangering the health or well-being of children or
            other minors, such as images of Children in sexualized costumes,
            poses, or a sexual fetishistic context, or which identifies,
            directly or indirectly, alleged victims of child sexual
            exploitation, or for the purpose of exploiting, harming or
            attempting to exploit or harm Children in any way;
          </li>
          <li>
            use the Services which may be discriminatory towards a person's or
            class of people's race, religion, color, age, ethnicity, national
            origin, disability, physical, or mental characteristics, sexual
            orientation, gender expression, gender identity, family status,
            medical or genetic condition, personality characteristics, or
            physical appearance, including through the material distortion of
            the behavior of any such person or class of people in a manner that
            causes or is likely to cause that person or class of people physical
            or psychological harm;
          </li>
          <li>
            use the Services in such a way that damages the image or rights of
            HeyJuni, other users or third parties;
          </li>
          <li>
            send spam or other direct marketing communications or posting,
            transmitting or linking to any unsolicited advertising, promotional
            materials, or any other forms of solicitation or commercial content;
          </li>
          <li>
            transmit executable programming of any kind, including viruses,
            spyware, trojan horses, Easter eggs, or any other form of computer
            programming or disabling mechanism;
          </li>
          <li>
            use any robot, spider or other automatic program or device, or
            manual process to monitor, copy, summarize, or otherwise extract
            information from this Services in whole or in part, including,
            without limitation, creating any frames at any other Services
            pertaining to any portions of this Services;
          </li>
          <li>
            attempt to circumvent, disable or otherwise interfere with
            security-related features of the Services that prevent or restrict
            use or copying of any Content or enforce limitations on use of the
            Services; or
          </li>
          <li>
            intentionally or unintentionally perform or promote any activity
            that would violate any applicable local, provincial/state, national
            or international law, including, but not limited to, any regulations
            having the force of law while using or accessing the Services.
          </li>
        </ol>
        <br />
        <br />
        <p className="text-2xl font-bold">3. Representations</p>
        <br />
        <p>
          By using the Service and/or registering an account, you must be at
          least 13 years old for the speaker and 18 years old for the listener,
          as a "natural person" in your country of residence.
        </p>
        <br />
        <p>
          You expressly represent and warrant that you are eligible for using
          this service If you are below the age, you must seek express consent
          from your legal guardian such as your parent or legal guardian and
          they must consent to the use of the Service. You hereby confirm that
          you are legally able to consent to receive the Service or have the
          consent of a parent or guardian, and are legally able to enter into a
          contract. We will not be held accountable for misrepresentation of age
          and does not verify personal information due to our anonymity values.
        </p>
        <br />
        <p>
          We are open broadly to everyone, but you cannot use HeyJuni if you are
          a convicted sex offender or are prohibited from receiving our
          products, services or software under applicable laws.
        </p>
        <br />
        <p>
          By using the Service, you further represent and warrant that you have
          the right, authority and capacity to use the Service and to abide by
          the Terms of Service (or to do so on behalf of a minor child of whom
          you are a parent or legal guardian). You further confirm and agree
          that all the information that you provided in or through the Platform,
          and the information that you will provide in or through the Platform
          in the future, is accurate, true, current and complete. Furthermore,
          you agree that during the term of this Agreement, you will make sure
          to maintain and update this information so it will continue to be
          accurate, current and complete.
        </p>
        <br />
        <p>
          Your use of the Service is for your own sole, personal use or that of
          your minor child for whom you are a parent/legal guardian. You
          undertake not to authorize others to use your identity or account, and
          you may not assign or otherwise transfer your account to any other
          person or entity. You further acknowledge and agree that we will hold
          you liable and responsible for any damage or loss incurred as a result
          of the use of your Account Access by any person whether authorized by
          you or not, and you agree to indemnify us for any such damage or loss.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">
          4. Access Rights and Registration of Account
        </p>
        <br />
        <p>
          Subject to you complying with these Terms of Service and the
          registration requirements, we grant to you a limited, non-exclusive,
          non-transferable right to access the Platform and use the Services
          solely for your personal non-commercial use and only as permitted
          under these Terms of Service and any separate agreements you may have
          entered into with us. We reserve the right, in our sole discretion, to
          deny or suspend the use of the Services to anyone for any reason.
        </p>
        <br />
        <p>You agree that you will not, and will not attempt to:</p>
        <ol className="list-disc list-inside inline-block">
          <li>
            impersonate any person or entity, or otherwise misrepresent your
            affiliation with a person or entity;
          </li>
          <li>
            use the Services to violate any applicable law, regulations or
            guidelines;
          </li>
          <li>
            reverse engineer, disassemble, decompile, or translate any software
            or other components of the Services;
          </li>
          <li>
            distribute viruses or other harmful computer code through the
            Platform or
          </li>
          <li>
            otherwise use the Services in any manner that exceeds the scope of
            use granted above. In addition, you agree to refrain from abusive
            language when communicating with Listeners through the Platform.
          </li>
        </ol>
        <br />
        <br />
        <p>
          In order to access certain Services through this Platform you need to
          register an account. You agree to provide accurate and complete
          information when you register and to keep that information updated and
          accurate. When registering an account, you shall not use as an account
          name the name of another person with the intent to impersonate that
          person. We reserve the right to refuse registration of an account or
          cancel it at our discretion.
        </p>
        <br />
        <p>
          You agree that you are responsible for all activities under your User
          Account, and for maintaining the confidentiality of your password and
          restricting access to your devices so that others may not access our
          Services in violation of these Terms. Each time you use a password or
          identification, you will be deemed to be authorized to access, confirm
          your agreement on the updated Terms of Service from time to time, and
          use the Services in a manner consistent with these Terms. We have no
          obligation to investigate the authorization or source of any such
          access or use of the Services. You will be solely responsible for all
          access to and use of the Services by anyone using the password and
          identification originally assigned to you whether or not such access
          to and use of the Services is actually authorized by you, including
          without limitation, all communications and transmissions and all
          obligations (including, without limitation, financial obligations)
          incurred through such access or use. You agree to immediately notify
          us of any unauthorized use of your password or identification or any
          other breach or threatened breach of the Services' security that you
          become aware of involving or relating to the Services by contacting
          HeyJuni. We reserve the right to take any and all actions we deem
          necessary or reasonable to maintain the security of our Services and
          your User Account, including without limitation, terminating your User
          Account, changing your password or requesting information to authorize
          transactions on your User Account. We are not responsible for any loss
          or damage arising from any breach of these obligations.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">5. Privacy and Security</p>
        <br />
        <p>
          Protecting and safeguarding any information you provide through the
          Platform is extremely important to us. Information about our security
          and privacy practices can be found on our Privacy Policy available at
          000website.
        </p>
        <br />
        <p>
          BY AGREEING TO THIS AGREEMENT AND/OR BY USING THE SERVICE, YOU ARE
          ALSO AGREEING TO THE TERMS OF THE PRIVACY POLICY. THE PRIVACY POLICY
          IS INCORPORATED INTO AND DEEMED A PART OF THIS AGREEMENT. THE SAME
          RULES THAT APPLY REGARDING CHANGES AND REVISIONS OF THIS AGREEMENT
          ALSO APPLY TO CHANGES AND REVISIONS OF THE PRIVACY POLICY.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">6. Personal Data Protection</p>
        <br />
        <p>
          You agree and consent to us using and processing your Personal Data
          for the Purposes and in the manner as set out in our Privacy Policy
          and outlined below.
        </p>
        <br />
        <p>
          For the purposes of this Agreement, "Personal Data" means information
          about you, from which you are identifiable, including but not limited
          to your email address and phone number which you have provided to the
          us in registration forms, application forms or any other similar forms
          and/or any information about you that has been or may be collected,
          stored, used and processed by us from time to time and includes
          sensitive personal data such as data relating to health, religious or
          other similar beliefs.
        </p>
        <br />
        <p>
          The provision of your Personal Data is voluntary. However, if you do
          not provide us with your Personal Data, we may not be able to process
          your Personal Data for the Purposes outlined below and may cause us to
          be unable to allow you to use the Service or the Platform.
        </p>
        <br />
        <p>
          We may use and process your Personal Data for business and activities
          of the Company which shall include, without limitation the following
          (collectively referred to as the "Purpose"):
        </p>
        <ol className="list-disc list-inside inline-block">
          <li>
            To perform our obligations in respect of any contract entered into
            with you;
          </li>
          <li>
            To provide you with any services pursuant to the Terms of Service
            herein;
          </li>
          <li>
            Process, manage or verify your application/registration for the
            Service pursuant to the Terms of Service herein;
          </li>
          <li>
            To validate and/or process payments pursuant to the Terms of Service
            herein;
          </li>
          <li>
            To develop, enhance and provide what is required pursuant to the
            Terms of Service herein to meet your needs;
          </li>
          <li>
            To process any refunds, rebates and or charges pursuant to the Terms
            of Service herein;
          </li>
          <li>
            To facilitate or enable any checks as may be required pursuant to
            the Terms of Service herein;
          </li>
          <li>To respond to questions, comments and feedback from you;</li>
          <li>
            To communicate with you for any of the purposes listed herein;
          </li>
          <li>
            For internal administrative purposes, such as auditing, data
            analysis, database records;
          </li>
          <li>
            For purposes of detection, prevention and prosecution of crime;
          </li>
          <li>For us to comply with its obligations under law.</li>
        </ol>
        <br />
        <br />
        <p>
          As our information technology storage facilities and servers may be
          located in other jurisdictions, your Personal Data may be transferred
          to, stored, used and processed in a jurisdiction other than Singapore
          provided the location has an equivalent or similar data protection
          laws as Singapore.
        </p>
        <br />
        <p>
          We may engage other companies, service providers or individuals to
          perform functions on our behalf, and consequently may provide access
          or disclose your Personal Data to third parties including but not
          limited to those listed below:
        </p>
        <ol className="list-disc list-inside inline-block">
          <li>Information technology (IT) service providers;</li>
          <li>Data entry service providers;</li>
          <li>Storage facility providers;</li>
          <li>Insurance providers;</li>
          <li>Any professional advisors and external auditors;</li>
          <li>
            Regulatory and governmental authorities in order to comply with
            statutory and government requirements.
          </li>
          <li>Listeners and support personnel.</li>
        </ol>
        <br />
        <br />
        <p>
          Subject to any exceptions under applicable laws, you may at any time
          hereafter request for access to, or correction or rectification of
          your Personal Data or limit the processing of your Personal Data, or
          seek further information from us by using the support contact details
          provided on the Platform.
        </p>
        <br />
        <p>
          By submitting your information you consent to the use of that
          information as set out in the form of submission/registration and this
          Terms of Service.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">7. Intellectual Property</p>
        <br />
        <p>
          We, where applicable, shall own all rights, title and interests,
          including all related intellectual property rights, in and to the
          Platform and all its contents, including without limitation the
          Software, text, materials, compilation of information, images, videos,
          displays, audio and design and any suggestions, ideas, enhancement
          requests, feedback, recommendations or other information provided by
          you or any other party relating to the Service/Platform.
        </p>
        <br />
        <p>
          The Terms of Service do not constitute a sale agreement and do not
          convey to you any rights of ownership in or related to the Service or
          any intellectual property rights owned by the Company and/or its
          licensors. The Company name, the Company logo, and certain other
          material on the Platform constitute trademarks or other intellectual
          property rights of the Company or its licensors/providers or other
          parties and no right or license is granted to use them.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">8. Warranties and Disclaimers</p>
        <br />
        <p>
          You expressly agree that use of the Service is at your sole risk. Both
          the Platform and Services are provided on an "AS IS" and "AS
          AVAILABLE" basis. We do not warrant that access to this Platform will
          be uninterrupted or error-free or that defects in the Platform will be
          corrected. We expressly disclaims all warranties of any kind, whether
          express or implied, including, but not limited to any warranties or
          merchantability, fitness for a particular use or purpose,
          non-infringement, title, operability, condition, quiet enjoyment,
          value and accuracy of data.
        </p>
        <br />
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE US AND
          AGREE TO HOLD US HARMLESS FROM ANY AND ALL CAUSES OF ACTION AND CLAIMS
          OF ANY NATURE RESULTING FROM THE SERVICES OR THE PLATFORM, INCLUDING
          (WITHOUT LIMITATION) ANY ACT, OMISSION, OPINION, RESPONSE, INFORMATION
          AND/OR SERVICE OF ANY LISTENERS AND/OR ANY OTHER CONTENT OR
          INFORMATION ACCESSIBLE THROUGH THE PLATFORM.
        </p>
        <br />
        <p>
          YOU UNDERSTAND, AGREE AND ACKNOWLEDGE THAT WE SHALL NOT BE LIABLE TO
          YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
          SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES.
        </p>
        <br />
        <p>
          YOU UNDERSTAND, AGREE AND ACKNOWLEDGE THAT OUR AGGREGATE LIABILITY FOR
          DAMAGES ARISING WITH RESPECT TO THIS AGREEMENT AND ANY AND ALL USE OF
          THE PLATFORM WILL NOT EXCEED THE TOTAL AMOUNT OF MONEY PAID BY YOU OR
          ON YOUR BEHALF THROUGH THE PLATFORM IN THE 12 MONTH PERIOD PRIOR TO
          THE DATE OF THE CLAIM.
        </p>
        <br />
        <p>
          If the applicable law does not allow the limitation of liability as
          set forth above, the limitation will be deemed modified solely to the
          extent necessary to comply with applicable law. This section
          (limitation of liability) shall survive the termination or expiration
          of this Agreement.
        </p>
        <br />
        <p>
          By using our Service you hereby agree and acknowledge that we do not
          provide medical advice, psychiatric diagnosis or treatment. We are not
          medical or health services organizations or forums.We do not have any
          licensed profession and do not interfere with registered counselling
          or any other licensed profession. You further acknowledge that none of
          the content on the Platform should be considered as medical advice or
          an endorsement, representation or warranty that any particular
          suggestion, medication or treatment is safe, appropriate, or effective
          for you. You should disregard any such advice if delivered through the
          platform and should not avoid or delay in obtaining in-person care
          from your doctor or other qualified professional because of
          information or advice you received through the platform.
        </p>
        <br />
        <p>
          The information presented on the us is in no way intended as medical
          and/or psychiatric advice, is not provided for the purpose of
          diagnostics or treating medical and/or psychiatric problems nor is it
          a substitute for medical and/or psychiatric treatment.
        </p>
        <br />
        <p>
          There are potential risks associated with the use of HeyJuni. These
          risks include, but may not be limited to:
        </p>
        <ol className="list-disc list-inside inline-block">
          <li>
            Information transmitted may not be sufficient (e.g. lack of
            disclosure by the user) to allow for appropriate support given by
            the Listener.
          </li>
          <li>
            Delays in communication could occur due to failures of the
            electronic equipment or communication networks.
          </li>
          <li>
            A lack of full access to all your personal information may result in
            wrong support in rare cases.
          </li>
          <li>
            Security protocols could fail, causing a breach of privacy of
            personal health information.
          </li>
          <li>
            We reserve the right at our discretion, to remove any Listener or
            Speaker from us at any time if any Listener or Speaker fails to
            maintain a certain code of conduct or rating that we impose on such
            Listener from time to time.
          </li>
          <li>
            By using the Service, you further understand, agree and acknowledge
            that you have the following rights with respect to the use of our
            Platform and the limitations of our service as follows:
          </li>
        </ol>
        <br />
        <br />
        <p>
          We do not verify the skills, degrees, qualifications, credentials or
          background of any Listeners. We make no representation or warranty
          whatsoever as to (a) the accuracy or availability of the Listening
          Platform, (b) the willingness or ability of the Listener, (c) the
          willingness or ability of any Listener, (d) whether the Member shall
          find a Listener useful or satisfactory, (e) whether the Member shall
          find a Listener's answer relevant, useful, accurate or satisfactory,
          (f) whether the listening of the Listener will be helpful, (g) whether
          the support of the Listener will be responsive or relevant to the
          Member's question, or (h) whether the Listener's advice will otherwise
          be suitable to the Member's needs.
        </p>
        <br />
        <p>
          You understand that the information disclosed by you during the chat
          is generally confidential. You also understand that the dissemination
          of any personally identifiable images or information from the
          interaction shall not occur without the other party's written consent.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">9. Limitation of Liability</p>
        <br />
        <p>
          YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN
          NO EVENT WILL HEYJUNI OR ITS OFFICERS, EMPLOYEES, DIRECTORS, PARENTS,
          SUBSIDIARIES, AFFILIATES, AGENTS OR LICENSORS BE LIABLE FOR ANY
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
          INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES, PROFITS,
          GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES ARISING OUT OF OR
          RELATED TO YOUR USE OF THE PLATFORM OR THE SERVICES, REGARDLESS OF
          WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE
          AND STRICT LIABILITY), WARRANTY, STATUTE OR OTHERWISE RELATING IN ANY
          WAY TO THE PLATFORM, SERVICES, ANY INFORMATION OR MATERIALS ON OR
          OBTAINED THROUGH THE PLATFORM.
        </p>
        <br />
        <p>
          In no event shall our total liability to you (or for any minor for
          whom you are responsible) for any and all damages, losses, costs,
          expenses and fees exceed the amount you have paid to us. To the extent
          that we may not, as a matter of applicable law, disclaim any implied
          warranty or limit our liabilities, the scope and duration of such
          warranty and the extent of our liability will be the minimum permitted
          under such applicable law.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">10. Indemnity</p>
        <br />
        <p>
          You agree to indemnify, defend and hold harmless the Company, its
          officers, directors, employees, agents, representatives, subsidiaries,
          affiliates, licensors, partners and suppliers, harmless from and
          against any claim, actions, demands, liabilities, costs, expenses and
          settlements, including without limitation reasonable legal and
          accounting fees ("Claims"), resulting from, or alleged to result from,
          any use of the Platform, Services and/or Software or any breach of
          these Terms of Service. In addition, you agree to indemnify, defend
          and hold harmless your Listener from and against any third-party
          claims resulting from your lack of adherence with the support of the
          Listener or any breach of these Terms of Service.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">
          11. Payment, Cancellation and Refunds
        </p>
        <br />
        <p className="font-semibold">a. Payment:</p>
        <br />
        <p>
          If you purchase any product or services on HeyJuni, you agree to pay
          all fees and charges (including all applicable GST) in accordance with
          the applicable fees, charges and payment terms. You may choose to pay
          for the Service by any of the payment methods available at the
          Platform. HeyJuni reserves the right to make changes to the fees and
          charges from time to time without advance notice. By providing the
          required payment or payment information, you agree that HeyJuni may
          invoice all fees and charges due and the payment will be processed
          automatically upon confirmation of booking. You shall be responsible
          for any finance or other charges imposed by your payment provider
          (such as credit card provider or financial institution) and for
          resolving any disputes with your payment provider (such as your credit
          card company) on your own.
        </p>
        <br />
        <p>
          In case you make a payment through the Platform or make any payment to
          us, this payment is made to the Listener for the Service. HeyJuni may
          charge the Listener by taking a portion of this payment for the use
          and operation of the Platform ("Platform Use Fees"). HeyJuni will not
          be deemed as the Therapist of any Therapy Services regardless of
          payment. Furthermore, the payment for the use of the Platform is made
          by the Listener and not by you.
        </p>
        <br />
        <p>
          HeyJuni reserves the right to suspend the processing of any
          transaction where HeyJuni reasonably believe that the transaction may
          be fraudulent, illegal or involve any criminal activity or where
          HeyJuni reasonably believe you to be in breach of the Terms of
          Service.
        </p>
        <br />
        <p className="font-semibold">b. Cancellation:</p>
        <br />
        <p>
          Please ensure that you would like to purchase the service. Once the
          payment is conducted, HeyJuni currently does not allow cancellation
          after the booking and payment is made. You may only reschedule your
          session to a later date.
        </p>
        <br />
        <p>
          Should you subscribe to a subscription service on the Platform, you
          will be able to cancel your subscription. Any type of subscription you
          choose will continue and automatically renew until you cancel the
          membership. By choosing a recurring Membership Service, you
          acknowledge that such paid services have a recurring payment and you
          accept responsibility for all recurring charges prior to cancellation.
          You can cancel the subscription to the service at any time for any
          reason. Your membership must be cancelled before it renews in order to
          avoid the next billing cycle.
        </p>
        <br />
        <p>
          HeyJuni reserves the right to change our subscription or adjust the
          prices of our services. Any changes to your membership services will
          only take effect following proper notice to you.
        </p>
        <br />
        <p>
          Free Trials: Occasionally, HeyJuni offers free trials for our Listener
          Services. Once a free trial expires, the paid membership will commence
          following a notice to you.
        </p>
        <br />
        <p className="font-semibold">c. Refunds:</p>
        <br />
        <p>
          Strictly no refunds are available for any Services which have been
          provided or when the booking and payment have been made.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">12. Termination</p>
        <br />
        <p>
          You may deactivate your account and end your registration at any time
          for any reason.We may suspend or terminate your use of the Platform,
          your account and/or registration for any reason at any time. Without
          prejudice to the generality of the foregoing, you hereby agree that we
          are entitled to terminate your account immediately in the event that
          you are in breach of any of the terms or policies. For the avoidance
          of doubt, the termination shall not require us to compensate,
          reimburse or cover any costs, fees or expenses incurred by you in
          connection with the use of the Service.
        </p>
        <br />
        <p>
          Following termination or deactivation, you will not have further
          access to your account or the Service. In the case of
          termination/deactivation by us, you remain liable for all amounts due
          up to and including the date of termination/deactivation.
        </p>
        <br />
        <p>
          The provisions of these Terms concerning protection of intellectual
          property rights, prohibited use, user-submitted content, disclaimers,
          limitations of liability, indemnity, and disputes, as well as any
          other provisions that by their nature should survive, shall survive
          any such termination.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">13. General</p>
        <br />
        <p>
          No joint venture, partnership, employment, or agency relationship
          exists between you, us or any third-party provider as a result of the
          Terms of Service.
        </p>
        <br />
        <p>
          Except as otherwise provided in this Terms, if any provision contained
          in this Terms is in conflict with, or inconsistent with, any provision
          in any of the other additional terms, the provision contained in
          additional terms shall govern and control.
        </p>
        <br />
        <p>
          If any provision of the Terms of Service is held to be invalid or
          unenforceable, such provision shall be struck and the remaining
          provisions shall be enforced to the fullest extent under law.
        </p>
        <br />
        <p>
          The failure of HeyJuni to enforce any right or provision in the Terms
          of Service shall not constitute a waiver of such right or provision
          unless acknowledged and agreed to by us in writing.
        </p>
        <br />
        <p>
          When you visit our Services or send emails or text messages to us, you
          are communicating with us electronically. You consent to receive
          communications from us electronically. We will communicate with you by
          email, text message, push notifications services or by posting notices
          on our Services. You agree that all agreements, notices, disclosures
          and other communications that we provide to you electronically satisfy
          any legal requirement that such communications be in writing.
        </p>
        <br />
        <p>
          The Terms of Service comprises the entire agreement between you and us
          and supersedes all prior or contemporaneous negotiations or
          discussions, whether written or oral (if any) between the parties
          regarding the subject matter contained herein.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">14. Governing Law & Disputes</p>
        <br />
        <p>
          PLEASE READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO
          ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK
          RELIEF FROM US. YOU UNDERSTAND THAT, ABSENT THIS MANDATORY PROVISION,
          YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. YOU
          FURTHER UNDERSTAND THAT THE RIGHTS TO DISCOVERY AND APPEALS MAY BE
          MORE LIMITED IN ARBITRATION THAN IN COURT. IF YOU ARE LOCATED IN THE
          EU OR THE UK, THESE PROVISIONS WILL NOT AFFECT YOUR RIGHT TO RESOLVE
          DISPUTES IN YOUR LOCAL COURT OR TRIBUNAL.
        </p>
        <br />
        <p>
          These Terms of Service shall be governed by Singapore law, without
          regard to the choice or conflicts of law provisions of any
          jurisdiction.
        </p>
        <br />
        <p>
          <span className="font-semibold">a. Initial Dispute Resolution.</span>{' '}
          We want to address your concerns without needing a formal legal case.
          Most concerns may be quickly resolved in this manner. For any Dispute
          with us, you agree to first contact HeyJuni and attempt to resolve the
          Dispute with us informally to address any concerns you may have
          regarding your use of the Services. Your Notice of Dispute must be
          individual to you and must include, as applicable, your name, your
          email address, and your residential address. The Notice of Dispute
          also must explain the facts of the Dispute as you understand them and
          tell us what you want us to do to resolve the issue. You agree to use
          best efforts to settle any Dispute, claim, question, or disagreement
          directly through consultation and good faith negotiations, and you
          agree that a Notice of Dispute containing all of the information
          required above, followed by at least 60 days of good faith
          negotiation, shall be a precondition to either party initiating a
          lawsuit or arbitration. A Notice of Dispute will not be valid unless
          it contains all of the information required by this paragraph. If you
          commence an arbitration without having previously provided a valid and
          compliant Notice of Dispute, you and we agree that the applicable
          arbitration provider (or the arbitrator, if one has been appointed)
          must suspend the arbitration pending compliance with this paragraph.
          You and we authorize the arbitration provider or the arbitrator to
          decide summarily whether the party that commenced an arbitration
          complied with these notice requirements, relying solely on this
          Agreement and the Notice of Dispute provided (if any). All statutes of
          limitation shall be tolled while the parties engage in the informal
          Dispute resolution process required by this paragraph.
        </p>
        <br />
        <p>
          <span className="font-semibold">
            b. Agreement to Binding Arbitration.
          </span>{' '}
          Any disputes, actions, claims or causes of action arising out of or in
          connection with these Terms of Service or the Service shall be
          referred to the Singapore International Arbitration Centre, in
          accordance with the Rules of the SIAC as modified or amended from time
          to time by a sole arbitrator appointed by the mutual agreement of you
          and us. If you and we are unable to agree on an arbitrator, the
          Arbitrator shall be appointed by the President of SIAC in accordance
          with the Rules. The seat and venue of the arbitration shall be
          Singapore, in the English language and the fees of the Arbitrator
          shall be borne equally by you and us, provided that the Arbitrator may
          require that such fees be borne in such other manner as the Arbitrator
          determines is required in order for this arbitration clause to be
          enforceable under applicable law. Notwithstanding the above, if you
          are a Partner, you may choose to submit a Dispute for mediation at the
          Singapore Mediation Centre and/or Small Claims Tribunal, subject to
          their respective rules and guidelines.
        </p>
        <br />
        <p>
          <span className="font-semibold">
            c. Class Action and Class Arbitration Waiver.
          </span>{' '}
          You and we each further agree that any arbitration shall be conducted
          in our respective individual capacities only and not as a class
          action, and you and we each expressly waive our respective right to
          file a class action or seek relief on a class basis. If any court or
          arbitrator determines that the class action waiver set forth in this
          paragraph is void or unenforceable for any reason or that arbitration
          can proceed on a class basis, then the arbitration provision set forth
          above shall be deemed null and void in its entirety and the parties
          shall be deemed to have not agreed to arbitrate Disputes.
        </p>
        <br />
        <p>
          <span className="font-semibold">d. Exceptions.</span> Notwithstanding
          the parties' agreement to resolve all Disputes through arbitration,
          either party may seek relief (i) in a small claims court for Disputes
          or claims within the scope of that court's jurisdiction, and (ii) any
          Disputes relating to intellectual property rights, obligations, or any
          infringement claims.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">15. Changes to these Terms</p>
        <br />
        <p>
          We reserve the right to modify these Terms, at any time without prior
          notice, at our sole discretion. We will use reasonable efforts to
          notify you of such changes. However, it is your responsibility to
          check the "Last Updated" legend at the top of this page periodically
          to see when these Terms were last revised. When changes are made to
          these Terms, they will become immediately effective when published on
          this web page unless otherwise noted. If we modify these Terms
          materially, to the extent permissible under applicable law, such
          modification will be effective upon the earlier of (i) your first use
          of the Services with actual notice of such change, (ii) 30 days from
          the posting of such change. We may send you notice of the updated
          Terms at our sole discretion, and the manner of the notification may
          include, for example, email, posted notice on the Services, or another
          reasonable manner. Your continued access and use of the Services
          following any such change constitutes your acceptance to be bound by
          the revised Terms then in effect. You agree that you will review these
          Terms periodically and that you shall be bound by these Terms and any
          modifications to it. Since our Services are evolving over time, we may
          also change or discontinue all or any part of the Services, at any
          time and without notice.
        </p>
        <br />
        <p>
          These Terms will identify the date of the last update. "Disputes"
          means any dispute, action, controversy, or claim arising out of or
          relating to any aspect of these Terms, whether based on contract,
          tort, statute, fraud, misrepresentation, or any other legal or
          equitable basis and regardless of whether a claim arises during or
          after the termination of these Terms.
        </p>
        <br />
        <br />
        <p className="text-2xl font-bold">16. Contact us</p>
        <br />
        <p>
          We operate a helpdesk and will always try to resolve any queries,
          complaints or disputes when they first arise. Please provide details
          of any queries, complaints or comments by completing the{' '}
          <a href="/contact-us" className="underline">
            Contact Us
          </a>{' '}
          form.
        </p>
      </div>
    </div>
  );
}
