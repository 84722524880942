import Button from '@components/Button';
import Accordion from '@components/Accordion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FaqSection() {
  const { t } = useTranslation('learn-more');
  const navigate = useNavigate();
  const items = [
    {
      title: t`faq.items.item-1.title`,
      content: (
        <>
          <p>{t`faq.items.item-1.p1`}</p>
          <br />
          <p className="text-2xl font-bold">{t`faq.items.item-1.p2`}</p>
          <ol className="list-decimal list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">{t`faq.items.item-1.p3`}</span>{' '}
              {t`faq.items.item-1.p4`}
            </li>
            <li className="mb-4">
              <span className="font-bold">{t`faq.items.item-1.p5`}</span>{' '}
              {t`faq.items.item-1.p6`}
            </li>
            <li className="mb-4">
              <span className="font-bold">{t`faq.items.item-1.p7`}</span>{' '}
              {t`faq.items.item-1.p8`}
            </li>
            <li className="mb-4">
              <span className="font-bold">{t`faq.items.item-1.p9`}</span>{' '}
              {t`faq.items.item-1.p10`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`faq.items.item-2.title`,
      content: (
        <>
          <p>{t`faq.items.item-2.p1`}</p>
          <br />
          <p>{t`faq.items.item-2.p2`}</p>
          <br />
          <p>{t`faq.items.item-2.p3`}</p>
        </>
      ),
    },
    {
      title: t`faq.items.item-3.title`,
      content: <div>{t`faq.items.item-3.p1`}</div>,
    },
    {
      title: t`faq.items.item-4.title`,
      content: (
        <div>
          {t`faq.items.item-4.p1`}{' '}
          <a href="mailto:anna@heyjuni.com" className="text-blue-800">
            anna@heyjuni.com
          </a>{' '}
          {t`faq.items.item-4.p2`}
        </div>
      ),
    },
    {
      title: t`faq.items.item-5.title`,
      content: (
        <div>
          <p>{t`faq.items.item-5.p1`}</p>
          <ol className="list-decimal list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">{t`faq.items.item-5.p2`}</span>{' '}
              {t`faq.items.item-5.p3`}
            </li>
          </ol>
          <p>{t`faq.items.item-5.p4`}</p>
        </div>
      ),
    },
  ];

  return (
    <section className="relative bg-white mt-[64px] md:mt-0 md:pt-[64px] md:pb-[120px] md:px-[76px] mb-[40px] md:mb-[20px] overflow-hidden">
      <h2 className="text-5xl leading-[48px] md:text-7xl font-extrabold md:leading-[65px] text-center">
        {t`faq.title`}
      </h2>

      <div className="md:mt-[20px] px-[30px] md:px-[100px]">
        <Accordion items={items} />
      </div>

      <div className="text-center mt-[104px] md:mt-[136px]">
        <div className="font-bold text-xl md:text-4xl mb-[20px]">
          {t`faq.cta.title`}
        </div>
        <Button
          label={t`faq.cta.button`}
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={() => navigate('/faq?selected=Users')}
        />
      </div>
    </section>
  );
}
