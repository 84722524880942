import { useState, useEffect } from 'react';
import { SubscribeSection } from '../Home/subscribe-section';
import { SearchSection } from './search-section';
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export enum BlogCategory {
  HIGHLIGHT = 'highlights',
  ARTICLE = 'articles',
  VIDEO = 'videos',
  EVENT = 'events',
}

// Utility function to get a valid category
function getValidCategory(category: string): BlogCategory {
  return Object.values(BlogCategory).includes(category as BlogCategory)
    ? (category as BlogCategory)
    : BlogCategory.HIGHLIGHT;
}

export default function Blog() {
  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState<string>(() => {
    const category = location.pathname.split('/')[2];
    return getValidCategory(category);
  });

  useEffect(() => {
    const category = location.pathname.split('/')[2];
    const validCategory = getValidCategory(category);

    if (category && validCategory !== selectedOption) {
      setSelectedOption(validCategory);
    }
  }, [location.pathname, selectedOption]);

  return (
    <>
      {/* Add Helmet for SEO */}
      {/* TODO: Will put Helmet meta into a separate component */}
      <Helmet>
        <title>
          Blog | Resources about HeyJuni, Counseling, Support & Therapy
        </title>
        <meta
          name="description"
          content="Browse through our videos, articles and social posts that provide information about HeyJuni, common mental health topics, support, therapy and explore healthy ways to cope with emotional stress and life challenges."
        />
        <meta
          name="keywords"
          content="HeyJuni Blog, Mental Health, Articles, Videos, Events, Peer Support"
        />
        <meta property="og:title" content="Blog - HeyJuni" />
        <meta
          property="og:description"
          content="Discover the latest updates, insights, and events on HeyJuni's blog, designed to inspire and educate about mental health."
        />
      </Helmet>

      <SearchSection selectedOption={selectedOption} />
      <Outlet />
      <SubscribeSection />
    </>
  );
}
