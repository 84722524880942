import { ComingBanner } from '@components/ComingBanner';
import linkedIn from '@assets/icons/shared/linked-in.svg';
import tiktok from '@assets/icons/shared/tiktok.svg';
import youtube from '@assets/icons/shared/youtube.svg';
import facebook from '@assets/icons/shared/facebook.svg';
import instagram from '@assets/icons/shared/instagram.svg';
import { Helmet } from 'react-helmet';
import NormalObject from '@components/NormalObject';

function VnBooking() {
  return (
    <div className="overflow-hidden">
      {/* Helmet for SEO */}
      <Helmet>
        <title>
          HeyJuni | Anonymous, 1-on-1 Mental Support - Within Minutes
        </title>
        <meta
          name="description"
          content="HeyJuni cung cấp dịch vụ chăm sóc sức khỏe tinh thần 1 kèm 1, giúp phụ nữ vượt qua khủng hoảng, lo âu, và trầm cảm một cách nhanh chóng và hiệu quả. Đặt lịch để gặp gỡ người đồng hành ngay hôm nay."
        />
        <meta
          name="keywords"
          content="Chăm sóc sức khỏe tinh thần, Tư vấn tâm lý, Hỗ trợ 1 kèm 1, Phụ nữ, Lo âu, Trầm cảm, Tư vấn viên"
        />
        <meta
          property="og:title"
          content="HeyJuni | Chăm Sóc Sức Khỏe Tinh Thần 1 Kèm 1"
        />
        <meta
          property="og:description"
          content="HeyJuni cung cấp dịch vụ chăm sóc sức khỏe tinh thần cho phụ nữ qua dịch vụ tư vấn 1 kèm 1. Gặp gỡ người đồng hành ngay lập tức để giải tỏa lo âu và khủng hoảng tâm lý."
        />
      </Helmet>
      <div
        className="relative flex justify-center"
        style={{ height: 'calc(100vh - 90px)' }}
      >
        <ComingBanner />

        <div
          className="absolute top-0 left-1/2 bg-[#d5faef] rounded-full"
          style={{
            zIndex: -1,
            width: 'calc(100vh + 110px)',
            height: 'calc(100vh + 110px)',
            transform: 'translateX(-50%) translateY(-100px)',
          }}
        ></div>

        <div className="w-full md:w-1/2 flex flex-col items-center px-8 md:px-0 pt-16 text-center text-lg md:text-xl gap-4">
          <h1 className="mb-4">Hello bạn! 💜</h1>
          <p>
            Chắc bạn đang muốn đặt lịch rồi đúng không? Hóng một chút nữa nhé,
            HeyJuni sắp ra mắt rồi!
          </p>
          <p>
            Với sứ mệnh mang đến dịch vụ chăm sóc sức khỏe tinh thần với chi phí
            hợp lý dành riêng cho nữ giới, HeyJuni đang hoàn thiện những bước
            cuối cùng để có thể đồng hành cùng bạn theo cách tốt nhất.
          </p>
          <p>
            Chúng mình háo hức được bật mí thêm trên social media của mình. Bạn
            nhớ ghé qua và theo dõi chúng mình nhé!
          </p>
          <div className="flex space-x-8 mt-6">
            {socialLinks.map((link) => (
              <a
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                key={link.alt}
              >
                <NormalObject
                  data={link.src}
                  className="h-6 md:h-[35px] md:w-[35px] transition-transform duration-100 transform hover:scale-110"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VnBooking;

const socialLinks = [
  {
    href: 'https://www.linkedin.com/company/heyjuni/posts/?feedView=all',
    src: linkedIn,
    alt: 'LinkedIn',
  },
  {
    href: 'https://www.instagram.com/heyjuni_vietnam/',
    src: instagram,
    alt: 'Instagram',
  },
  {
    href: 'https://www.youtube.com/@HeyJuni_official',
    src: youtube,
    alt: 'YouTube',
  },
  {
    href: 'https://www.tiktok.com/@heyjuni_vietnam',
    src: tiktok,
    alt: 'TikTok',
  },
  {
    href: 'https://www.facebook.com/profile.php?id=61573192533648',
    src: facebook,
    alt: 'Facebook',
  },
];
