import headingMobile from '@assets/footages/contact-us/heading-mobile.svg';
import NormalObject from '@components/NormalObject';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useState, ChangeEvent, FormEvent } from 'react';
import { trpc } from '@src/App';

export default function ContactUs() {
  const { t } = useTranslation('contact-us');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validateForm = () => {
    let newErrors: Record<string, string> = {};
    Object.keys(formData).forEach((field) => {
      if (!formData[field as keyof typeof formData].trim()) {
        newErrors[field] = t(`error-${field}`) || 'This field is required';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const contactUsMutation = trpc.contactUs.createMessage.useMutation();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      await contactUsMutation.mutateAsync(formData);
      setSubmitted(true);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
      });
      setTimeout(() => setSubmitted(false), 7000);
    }
  };

  return (
    <>
      <Helmet>
        <title>HeyJuni - Contact Us</title>
        <meta
          name="description"
          content="Get in touch with HeyJuni. Contact us for inquiries, feedback, or support via email or phone. We're here to help!"
        />
      </Helmet>

      <div className="relative pt-[58px] md:pt-[90px] px-0 pb-[150px]">
        <div
          className="hidden md:block absolute top-0 right-0"
          style={{ zIndex: -1 }}
        >
          <img
            src="media/contact-us/contact-us-heading.webp"
            alt="heading background"
          />
        </div>
        <div
          className="md:hidden absolute top-0 left-0 transform translate-y-[22px]"
          style={{ zIndex: -1 }}
        >
          <NormalObject data={headingMobile} />
        </div>

        <div className="max-w-[752px] px-[28px] md:px-[76px]">
          <p className="text-6xl md:text-8xl font-extrabold leading-[58px] md:leading-[85px]">
            {t`title`}
          </p>

          <p className="hidden md:block mt-[50px] text-4xl font-bold">
            {t`contact-email`} | {t`contact-phone`}
          </p>

          <div className="md:hidden mt-[28px] text-[28px] leading-[36px] font-bold flex gap-[15px] items-center">
            <div className="border-2 border-l-2 border-black self-stretch my-[10px]"></div>
            <div>
              <div>{t`contact-email`}</div>
              <div>{t`contact-phone`}</div>
            </div>
          </div>

          <div className="mt-[70px] md:mt-[50px] text-[15px] md:text-lg">
            <p>{t`description-1`}</p>
            <br />
            <p>{t`description-2`}</p>
          </div>
          <form onSubmit={handleSubmit} className="text-[15px] md:text-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] md:gap-[24px]">
              <input
                type="text"
                id="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder={t`first-name`}
                className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500"
              />
              {errors.firstName && (
                <p className="text-red-500">{t`errors.first-name`}</p>
              )}
              <input
                type="text"
                id="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder={t`last-name`}
                className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500"
              />
              {errors.lastName && (
                <p className="text-red-500">{t`errors.last-name`}</p>
              )}
            </div>

            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={t`email`}
              className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500 mt-[30px] md:mt-[50px]"
            />
            {errors.email && <p className="text-red-500">{t`errors.email`}</p>}

            <input
              type="text"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder={t`subject`}
              className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500 mt-[30px] md:mt-[50px]"
            />
            {errors.subject && (
              <p className="text-red-500">{t`errors.subject`}</p>
            )}

            <textarea
              id="message"
              value={formData.message}
              onChange={handleChange}
              placeholder={t`message`}
              rows={4}
              className="w-full p-2 border-b-2 border-[#181818] bg-transparent outline-none placeholder-gray-500 mt-[30px] md:mt-[50px]"
            />
            {errors.message && (
              <p className="text-red-500">{t`errors.message`}</p>
            )}

            <div className="mt-[30px] md:mt-[50px] text-center md:text-left">
              <button
                type="submit"
                className="bg-[#FFE816] hover:bg-[#9DFFE2] active:bg-[#D8CFFC] text-black hover:text-white active:text-black px-[30px] text-lg md:text-2xl rounded-[20px] md:rounded-[24px] h-[50px] md:h-[60px] font-semibold"
              >
                {t`submit`}
              </button>
            </div>
          </form>
        </div>

        {submitted && (
          <div className="fixed z-[9999] w-[90%] md:max-w-[460px] bottom-4 left-1/2 transform -translate-x-1/2 bg-green-500 bg-opacity-90 text-white py-2 px-4 rounded-lg shadow-lg flex items-center space-x-2 animate-toast">
            <svg
              className="w-6 h-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M9 11l3 3L22 4"></path>
            </svg>
            <span>{t`form-submitted-successfully`}</span>
          </div>
        )}
      </div>
    </>
  );
}
