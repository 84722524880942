import Button from '@components/Button';
import { useEffect, useState } from 'react';
import state1Icon from '@assets/footages/landing/finding-stability.svg';
import state2Icon from '@assets/footages/landing/building-resilience.svg';
import state3Icon from '@assets/footages/landing/managing-obstacles.svg';
import state4Icon from '@assets/footages/landing/focusing-on-recovery.svg';
import closeIcon from '@assets/icons/shared/close-icon.svg';
import { trpc } from '@src/App';
import { QuizAnalyzeResponse } from '@source/types/quiz';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

interface QuizContentProps {
  handleClose: () => void;
}

export function QuizContent(props: QuizContentProps) {
  const { i18n, t } = useTranslation('home');
  const navigate = useNavigate();
  const { handleClose } = props;
  const [index, setIndex] = useState(1);

  const [response, setResponse] = useState<number[]>([]);
  const [result, setResult] = useState<QuizAnalyzeResponse>();

  const quiz = trpc.quiz.getAllQuestionsWithOptions.useQuery(i18n.language);
  const analyzeResponseMutation = trpc.quiz.analyzeResponse.useMutation();

  const updateResult = async () => {
    const result = await analyzeResponseMutation.mutateAsync({
      answers: response.map((score, index) => ({
        questionId: index,
        response: score,
      })),
      language: i18n.language,
    });
    setResult(result);
  };

  useEffect(() => {
    if (response.length === 10) {
      updateResult();
    }
  }, [response]);

  const severityIcons = [state1Icon, state2Icon, state3Icon, state4Icon];

  return (
    <div
      className={clsx(
        `bg-white box-border h-[506px] md:h-auto md:min-h-[720px] p-[27px] md:py-[45px] md:px-0 md:w-[47%] mx-auto`
      )}
    >
      {quiz?.data && (
        <div>
          <div
            className="absolute right-[23px] top-[23px] w-[20px] h-[20px] md:w-auto md:h-auto md:p-[18px] cursor-pointer"
            onClick={() => {
              handleClose();
              setIndex(1);
            }}
          >
            <NormalObject
              data={closeIcon}
              className="w-full h-full object-contain"
            />
          </div>

          {index === quiz.data.questions.length + 1 ? (
            <>
              {result ? (
                <div className="flex flex-col items-center justify-center px-4 py-8 md:py-[15px] gap-[20px] md:gap-[50px]">
                  <p className="text-sm font-semibold md:text-3xl">
                    {t`quiz.conclusion`}
                  </p>
                  <div className="flex flex-col items-center md:gap-[29px]">
                    <img
                      src={severityIcons[result.severity - 1]}
                      className="w-[120px] h-[120px] md:w-[180px] md:h-[180px]"
                      alt="Severity Icon"
                    />

                    <h2 className="text-center text-xl font-bold md:font-extrabold md:text-5xl md:leading-[45px] mt-[30px] md:mt-0">
                      {result.title}
                    </h2>

                    <p className="text-center max-w-[420px] font-regular text-[13px] md:text-lg md:leading-[26px] mt-[10px] md:mt-0">
                      {result.message}
                    </p>
                  </div>

                  <Button
                    label={t`quiz.book-appointment`}
                    className="h-[50px] md:h-[60px] rounded-[20px] md:rounded-[24px] px-[30px] md:px-[30px] font-semibold text-lg md:text-2xl"
                    onClick={() => navigate('/booking')}
                  />
                </div>
              ) : (
                <Loading />
              )}
            </>
          ) : (
            <>
              <h2 className="text-sm text-center font-semibold md:font-extrabold md:text-5xl md:leading-[45px] mb-[20px] md:mb-[40px]">
                {t`quiz.question`} {index} {t`quiz.of`}{' '}
                {quiz.data.questions.length}
              </h2>

              <div className="flex flex-col gap-[20px] md:gap-[24px] font-regular md:font-semibold text-base md:text-2xl md:leading-[30px]">
                <p className="text-center min-h-[74px]">
                  {quiz.data.questions[index - 1].content}
                </p>
                {quiz.data.options.map((option, i) => (
                  <Button
                    key={option.score}
                    label={option.label}
                    className={clsx(
                      'rounded-[10px] h-[40px] md:h-[60px]',
                      i + 1 === response[index - 1] && '!bg-[#D8CFFC]'
                    )}
                    onClick={() => {
                      setResponse((prevResponse) => {
                        const updatedResponse = [...prevResponse];
                        const targetIndex = index - 1;

                        // Update the score at the target index or append if it doesn't exist
                        if (updatedResponse[targetIndex] !== undefined) {
                          updatedResponse[targetIndex] = option.score;
                        } else {
                          updatedResponse.push(option.score);
                        }

                        return updatedResponse;
                      });
                      setIndex((prev) => {
                        return prev + 1;
                      });
                    }}
                  />
                ))}
              </div>

              <div className="flex justify-center gap-[20px] mt-6 md:mt-[40px]">
                <button
                  className="text-gray-500 text-sm underline font-semibold md:text-base bg-transparent"
                  onClick={() => {
                    setIndex((prev) => {
                      if (prev - 1 > 0) return prev - 1;
                      return 1;
                    });
                  }}
                >
                  {t`quiz.previous`}
                </button>

                <button
                  className={clsx(
                    'text-gray-500 text-sm underline font-semibold md:text-base bg-transparent'
                  )}
                  disabled={!response[index - 1]}
                  onClick={() => {
                    setIndex((prev) => prev + 1);
                  }}
                >
                  {t`quiz.next`}
                </button>
                {/* TODO: Keep this stable */}
              </div>
            </>
          )}
        </div>
      )}

      {!quiz?.data && <Loading />}
    </div>
  );
}

function Loading() {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="spinner"></div>
    </div>
  );
}
