import { useTranslation } from 'react-i18next';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const options = [
    { value: 'en', label: 'English' },
    { value: 'vn', label: 'Tiếng Việt' },
  ];

  function changeLanguage(lang: string) {
    i18n.changeLanguage(lang);
    localStorage.setItem('preferredLanguage', lang); // Save to local storage
  }

  return (
    <>
      {options.map((option) => (
        <div
          key={option.value}
          onClick={() => changeLanguage(option.value)}
          style={{
            cursor: 'pointer',
            fontWeight: i18n.language === option.value ? 'bold' : 'normal',
          }}
        >
          {option.label}
        </div>
      ))}
    </>
  );
}
