import Accordion from '@components/Accordion';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function HelpSeekersFAQ() {
  const { t } = useTranslation('faq');

  const items = [
    {
      title: t`categories.users.items.item-1.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-1.p1`}</p>
          <br />
          <p>{t`categories.users.items.item-1.p2`}</p>
          <br />
          <p>{t`categories.users.items.item-1.p3`}</p>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-2.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-2.p1`}</p>
          <ol className="list-disc list-inside inline-block">
            <br />
            <li>
              <span className="font-bold">{t`categories.users.items.item-2.p2`}</span>{' '}
              {t`categories.users.items.item-2.p3`}
            </li>
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-2.p4`}
              </span>{' '}
              {t`categories.users.items.item-2.p5`}
            </li>
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-2.p6`}
              </span>{' '}
              {t`categories.users.items.item-2.p7`}
            </li>
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-2.p8`}
              </span>{' '}
              {t`categories.users.items.item-2.p9`}
            </li>
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-2.p10`}
              </span>{' '}
              {t`categories.users.items.item-2.p11`}
            </li>
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-2.p12`}
              </span>{' '}
              {t`categories.users.items.item-2.p13`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-3.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-3.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-4.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-4.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-5.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-5.p1`}</p>
          <br />
          <p>
            {t`categories.users.items.item-5.p2`}{' '}
            <a href="mailto:anna@heyjuni.com" className="text-blue-800">
              anna@heyjuni.com
            </a>{' '}
            {t`categories.users.items.item-5.p3`}
          </p>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-6.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-6.p1`}</p>
          <br />
          <ol className="list-disc list-inside inline-block">
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-6.p2`}
              </span>{' '}
              {t`categories.users.items.item-6.p3`}
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-6.p4`}
              </span>{' '}
              {t`categories.users.items.item-6.p5`}
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-6.p6`}
              </span>{' '}
              {t`categories.users.items.item-6.p7`}
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-6.p8`}
              </span>{' '}
              {t`categories.users.items.item-6.p9`}
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-6.p10`}
              </span>{' '}
              {t`categories.users.items.item-6.p11`}
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-6.p12`}
              </span>{' '}
              {t`categories.users.items.item-6.p13`}
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-6.p14`}
              </span>{' '}
              {t`categories.users.items.item-6.p15`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-7.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-7.p1`}</p>
          <ol className="list-disc list-inside inline-block">
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-7.p2`}
              </span>{' '}
              {t`categories.users.items.item-7.p3`}
            </li>
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-7.p4`}
              </span>{' '}
              {t`categories.users.items.item-7.p5`}
            </li>
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-7.p6`}
              </span>{' '}
              {t`categories.users.items.item-7.p7`}
            </li>
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-7.p8`}
              </span>{' '}
              {t`categories.users.items.item-7.p9`}
            </li>
            <br />
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-7.p10`}
              </span>{' '}
              {t`categories.users.items.item-7.p11`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-8.title`,
      content: (
        <div>
          <p>{t`categories.users.items.item-8.p1`}</p>
          <ol className="list-disc list-inside inline-block">
            <li className="mb-4">{t`categories.users.items.item-8.p2`}</li>
          </ol>
          <p>{t`categories.users.items.item-8.p3`}</p>
        </div>
      ),
    },
    {
      title: t`categories.users.items.item-9.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-9.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-10.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-10.p1`}</p>
          <br />
          <p className="text-2xl font-bold">
            {t`categories.users.items.item-10.p2`}
          </p>
          <ol className="list-decimal list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">
                {t`categories.users.items.item-10.p3`}
              </span>{' '}
              {t`categories.users.items.item-10.p4`}
            </li>
            <li className="mb-4">
              <span className="font-bold">
                {t`categories.users.items.item-10.p5`}
              </span>{' '}
              {t`categories.users.items.item-10.p6`}
            </li>
            <li className="mb-4">
              <span className="font-bold">
                {t`categories.users.items.item-10.p7`}
              </span>{' '}
              {t`categories.users.items.item-10.p8`}
            </li>
            <li className="mb-4">
              <span className="font-bold">
                {t`categories.users.items.item-10.p9`}
              </span>{' '}
              {t`categories.users.items.item-10.p10`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-11.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-11.p1`}</p>
          <br />
          <p>{t`categories.users.items.item-11.p2`}</p>
          <ol className="list-decimal list-inside inline-block">
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-11.p3`}
              </span>{' '}
              {t`categories.users.items.item-11.p4`}
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-11.p5`}
              </span>{' '}
              {t`categories.users.items.item-11.p6`}
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-11.p7`}
              </span>{' '}
              {t`categories.users.items.item-11.p8`}
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-11.p9`}
              </span>{' '}
              {t`categories.users.items.item-11.p10`}
            </li>
          </ol>
          <br />
          <p>{t`categories.users.items.item-11.p11`}</p>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-12.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-12.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-13.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-13.p1`}</p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p2`}
            </span>{' '}
            {t`categories.users.items.item-13.p3`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p4`}
            </span>{' '}
            {t`categories.users.items.item-13.p5`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p6`}
            </span>{' '}
            {t`categories.users.items.item-13.p7`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p8`}
            </span>{' '}
            {t`categories.users.items.item-13.p9`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p10`}
            </span>{' '}
            {t`categories.users.items.item-13.p11`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p12`}
            </span>{' '}
            {t`categories.users.items.item-13.p13`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p14`}
            </span>{' '}
            {t`categories.users.items.item-13.p15`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p16`}
            </span>{' '}
            {t`categories.users.items.item-13.p17`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p18`}
            </span>{' '}
            {t`categories.users.items.item-13.p19`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p20`}
            </span>{' '}
            {t`categories.users.items.item-13.p21`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p22`}
            </span>{' '}
            {t`categories.users.items.item-13.p23`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p24`}
            </span>{' '}
            {t`categories.users.items.item-13.p25`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p26`}
            </span>{' '}
            {t`categories.users.items.item-13.p27`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p28`}
            </span>{' '}
            {t`categories.users.items.item-13.p29`}
          </p>
          <br />
          <p>
            <span className="font-bold">
              {t`categories.users.items.item-13.p30`}
            </span>{' '}
            {t`categories.users.items.item-13.p31`}
          </p>
          <br />
          <p>{t`categories.users.items.item-13.p33`}</p>
        </>
      ),
    },
    {
      title: t`categories.users.items.item-14.title`,
      content: (
        <>
          <p>{t`categories.users.items.item-14.p1`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-14.p2`}
              </span>{' '}
              <a href="mailto:anna@heyjuni.com" className="text-blue-800">
                {t`categories.users.items.item-14.p3`}
              </a>
            </li>
            <li>
              <span className="font-bold">
                {t`categories.users.items.item-14.p4`}
              </span>{' '}
              <a
                href="https://wa.me/+6590894520"
                target="_blank"
                className="text-blue-800"
              >
                {t`categories.users.items.item-14.p5`}
              </a>
            </li>
          </ol>
          <br />
          <p>{t`categories.users.items.item-14.p6`}</p>
        </>
      ),
    },
  ];

  return (
    <>
      <p className="text-5xl font-extrabold mb-10">{t`categories.users.title`}</p>
      <Accordion items={items} />
    </>
  );
}
