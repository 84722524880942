import z from 'zod';
import { BookingFormDataSchema } from './createBookingRequest';
import { UserBasicInfoWithIdSchema } from '../user';

export const ConfirmBookingRequestInputSchema = z.object({
    bookingRequestId: z.number(),
});
export type ConfirmBookingRequestInput = z.infer<typeof ConfirmBookingRequestInputSchema>;

export const MeetingDetailsSchema = z.object({
    googleMeetLink: z.string(),
    calendarEventId: z.string(),
});

export const BookingRequestSchema = z.object({
    id: z.number(),
    formData: BookingFormDataSchema,
    preferredDateTime: z.coerce.date(),
    meetingDetails: MeetingDetailsSchema.nullable(),
    clientUser: UserBasicInfoWithIdSchema,
    volunteerUser: UserBasicInfoWithIdSchema.nullable(),
    checkoutSessionId: z.string().nullable(),
    doneAt: z.coerce.date().nullable(),
    cancelledAt: z.coerce.date().nullable(),
    createdAt: z.coerce.date(),
    language: z.string(),
});
export type BookingRequest = z.infer<typeof BookingRequestSchema>;

export const BookingRequestsSchema = z.array(BookingRequestSchema);
export type BookingRequests = z.infer<typeof BookingRequestsSchema>;

export enum BookingRequestStatus {
    UNSCHEDULED = 'UNSCHEDULED', // When the booking request is created, and no payment has been made
    PENDING = 'PENDING', // Initial status, when the booking request is created
    ASSIGNED = 'ASSIGNED', // When a volunteer is assigned to the booking request
    IN_PROGRESS = 'IN_PROGRESS', // After the preferredDateTime has passed, and the meeting has not been marked as completed or cancelled, and 5 minutes before the preferredDateTime
    COMPLETED = 'COMPLETED', // After the meeting has been marked as completed, if doneAt column is not null
    CANCELLED = 'CANCELLED', // After the meeting has been marked as cancelled, if cancelledAt column is not null
    STALED = 'STALED', // When the preferredDateTime has passed for more than 1 hour, and the meeting has not been marked as completed or cancelled
    ARCHIVED = 'ARCHIVED', // When the booking request has been completed or cancelled for more than 100 days
}
export const BookingRequestStatusSchema = z.nativeEnum(BookingRequestStatus);

export const GetBookingRequestsPagingInputSchema = z.object({
    status: BookingRequestStatusSchema,
});
export type GetBookingRequestsPagingInput = z.infer<typeof GetBookingRequestsPagingInputSchema>;

export const CancelBookingRequestInputSchema = z.object({
    bookingRequestId: z.number(),
});
export type CancelBookingRequestInput = z.infer<typeof CancelBookingRequestInputSchema>;

export const DoneBookingRequestInputSchema = z.object({
    bookingRequestId: z.number(),
});
export type DoneBookingRequestInput = z.infer<typeof DoneBookingRequestInputSchema>;
