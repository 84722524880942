import React from 'react';

export default function HowSexualViolenceHappensInFamiliarSettings() {
  return (
    <div>
      <img
        src="media/blog/articles/how-sexual-violence-happens-in-familiar-settings.webp"
        alt="how-sexual-violence-happens-in-familiar-settings"
        className="w-full"
      />
      <p className="mt-5 text-[15px] md:text-lg">
        Sometimes, sexual violence doesn't look like what we might expect. It's
        not always in dark alleyways or through obvious physical force; it can
        happen in seemingly familiar, everyday situations, with people we might
        already know and even be in a relationship with. Consider these
        examples:
      </p>
      <ol className="list-disc list-inside inline-block text-[15px] md:text-lg">
        <li>
          A student feels pressured into a relationship with a professor who has
          a say in their grades or academic advancement. This power dynamic can
          make it difficult for the student to reject advances or seek help.
        </li>
        <li>
          An employee receives sexually suggestive texts from their supervisor
          and feels that speaking out might cost them their job or compromise
          their standing at work.
        </li>
        <li>
          A young adult shares intimate images with a partner who then posts
          them online without consent. This is image-based abuse, which often
          has severe mental health consequences for the person experiencing it.
        </li>
      </ol>
      <p className="text-xl md:text-4xl font-bold mt-16">
        Let's try to identify if these situations are sexual violence:
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        1. A person consents to sex with a condom, but their partner secretly
        removes it midway.
      </p>
      <p className="text-[15px] md:text-lg">
        Though some may downplay this as a "miscommunication," it's a serious
        violation of consent, as the initial agreement was based on using
        protection. This act, known as "stealthing," is considered sexual
        assault in many places and can have physical, emotional, and legal
        repercussions.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        2. One partner is aware of an STI but chooses not to inform the other
        before engaging in sexual activity.
      </p>
      <p className="text-[15px] md:text-lg">
        If someone knowingly has an STI but chooses not to disclose it before
        sexual contact, they are violating their partner's right to make an
        informed decision. This breach of trust, while sometimes viewed as a
        "personal choice," is actually a form of sexual violence. Withholding
        information about STIs prevents the partner from giving fully informed
        consent, exposing them to health risks without their knowledge.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        3. One partner encourages the other to drink heavily before suggesting
        they engage in sexual activity, or initiate sexual advances while the
        other is intoxicated.
      </p>
      <p className="text-[15px] md:text-lg">
        If someone pressures their partner to drink heavily or become
        intoxicated before engaging in sexual activities, they are taking
        advantage of the partner's impaired state. Similarly, consent given
        under intoxication, especially if one person deliberately pressures the
        other to drink, is invalid. This situation is unfortunately common but
        often seen as a "blurred line" when, in reality, it involves
        manipulation and a lack of genuine consent.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        4. One partner asks the other for consent after they've already said no,
        to see if they can change their mind.
      </p>
      <p className="text-[15px] md:text-lg">
        Some situations involve partners who initially say "no" or express
        discomfort, but after persistent pressure, they give in. This pressure
        can be subtle, involving guilt-tripping, emotional manipulation, or
        making the person feel obligated to comply. While some view this as
        "convincing" or "persuasion," the repeated pressure violates the
        principle of free consent, as the person's decision was influenced by
        undue coercion. This also applies to situations that are not limited to
        intercourse, such as asking someone out multiple times till they agree
        to go on a date.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        5. One partner captures video or photos of a sexual encounter without
        the other's knowledge.
      </p>
      <p className="text-[15px] md:text-lg">
        Recording intimate acts or taking explicit photos without the other
        person's knowledge or consent may not involve physical force, but it's
        still a profound invasion of autonomy and privacy. Some may think that
        "no harm is done" if the content is not shared, yet capturing someone in
        a vulnerable situation without permission is a severe violation of trust
        and consent.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        6. Initiating sexual acts while the other person is asleep or
        unconscious
      </p>
      <p className="text-[15px] md:text-lg">
        Some people may misinterpret familiarity or a close relationship as
        implicit permission to engage in sexual acts while their partner is
        asleep or unconscious. Consent, however, requires conscious and active
        participation, meaning any sexual activity with a person who is asleep
        or otherwise unconscious is non-consensual and constitutes sexual
        assault. Even in committed relationships, consent must be given for each
        specific instance.
      </p>
      <p className="text-[15px] md:text-lg mt-2">
        In some relationships, partners may previously agree that certain types
        of intimacy are welcome while one is asleep—an arrangement based on
        clear communication and mutual comfort. However, without such prior and
        ongoing agreement, assuming consent in these scenarios can lead to
        serious violations of trust and personal boundaries. This example
        underlines that consent should never be presumed, even within close or
        established relationships, as it fundamentally requires full awareness
        and active participation.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        7. Ignoring boundaries
      </p>
      <p className="text-[15px] md:text-lg">
        In some cases, a partner may agree to certain sexual activities but set
        clear boundaries for others. If, during the encounter, one partner
        decides to push these limits or "try" an act that wasn't agreed upon,
        it's a breach of consent. This could include attempting specific acts
        that were previously rejected or dismissing the partner's boundaries,
        which can lead to feelings of violation and assault.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        8. A 25-year-old office worker dates a 20-year-old, university freshman
      </p>
      <p className="text-[15px] md:text-lg">
        Grooming is a process in which a person builds trust with someone—often
        a younger or more vulnerable individual—to manipulate them into a sexual
        relationship over time. While grooming is most often associated with
        younger victims, it can happen at any age, especially when there's a
        significant age or power gap involved. For example, an older colleague
        might offer mentorship, constant support, and gifts to a younger
        co-worker, gradually breaking down their personal boundaries and leading
        to an inappropriate relationship. Or, in another case, a person might
        target someone fresh out of high school, positioning themselves as a
        guide and protector, all while subtly steering the relationship in a
        sexual direction.
      </p>
      <p className="text-[15px] md:text-lg mt-2">
        Though this dynamic is especially common among younger individuals who
        are still learning about boundaries and trust, anyone vulnerable due to
        a power imbalance—whether in age, experience, or status—can fall prey to
        grooming. Grooming is insidious because it unfolds subtly over time,
        making it difficult for the victim to recognize the manipulation until
        they are already entangled. This example underscores the importance of
        recognizing that coercion and manipulation, even when not immediately
        obvious, still constitute a violation of consent and autonomy.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        **9. One partner initiates a previously agreed-upon sexual act another
        day
      </p>
      <p className="text-[15px] md:text-lg">
        Imagine a couple who previously agreed to certain intimate activities,
        such as a particular sexual act, on one occasion. However, during a
        later encounter, one partner assumes the same level of consent applies
        and initiates the same act without checking in or ensuring their partner
        is comfortable with it in that moment. Even though there was consent in
        the past, consent must be re-established every time, as comfort levels
        and personal boundaries can change.
      </p>
      <p className="text-[15px] md:text-lg mt-2">
        This scenario is often perceived as "grey," but assuming past consent
        without seeking active, ongoing agreement dismisses the partner's right
        to make an informed decision each time. This situation highlights that
        consent should always be current, specific to the moment, and
        communicated clearly, as assuming prior consent undermines the autonomy
        and well-being of the other person.
      </p>

      <p className="text-xl md:text-4xl font-bold mt-16">It's NOT your fault</p>
      <ol className="mt-5 list-decimal list-inside inline-block text-[15px] md:text-lg">
        <li>
          These situations highlight that sexual violence can affect anyone and
          doesn't always conform to stereotypes. Many times, the people who have
          experienced sexual violence are blamed or made to feel shame for what
          happened to them, but they should know that no matter the
          circumstances, they're not alone, and support is available.
        </li>
        <li>
          Remember: No means No, Maybe means No, and Yes means Yes—only when
          given freely, enthusiastically, and without pressure.
        </li>
        <li>Moving Toward Empowerment and Support.</li>
      </ol>

      <p className="text-xl md:text-4xl font-bold mt-16">
        Driving Awareness and Change
      </p>
      <ol className="mt-5 list-decimal list-inside inline-block text-[15px] md:text-lg">
        <li>
          Understanding its various forms, recognizing the wide-reaching impact,
          and fostering a compassionate and informed dialogue can all help
          dismantle the stigma and barriers that silence so many.
        </li>
        <li>
          Remember, sexual violence thrives in secrecy and isolation. By
          educating ourselves and others, by being open, and by showing empathy,
          we build stronger, safer, and more resilient communities. For those
          who have experienced this form of violence, know that you are not
          alone, and healing is possible. And for everyone else, we can all play
          a part in promoting respect, consent, and dignity for all. Together,
          we can make a difference, one conversation at a time.
        </li>
      </ol>
    </div>
  );
}
