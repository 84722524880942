import Button from '@components/Button';
import Accordion from '@components/Accordion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FaqSection() {
  const { t } = useTranslation('become-a-listener');
  const navigate = useNavigate();
  const items = [
    {
      title: t`faq.items.item-1.title`,
      content: (
        <>
          <p>{t`faq.items.item-1.content.p1`}</p>
          <p>{t`faq.items.item-1.content.p2`}</p>
          <p>{t`faq.items.item-1.content.p3`}</p>
          <br />
          <p className="font-bold">{t`faq.items.item-1.content.core-modules.title`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              <span className="font-semibold">
                {t`faq.items.item-1.content.core-modules.items.item-1.title`}{' '}
              </span>
              {t`faq.items.item-1.content.core-modules.items.item-1.description`}
            </li>
            <li>
              <span className="font-semibold">
                {t`faq.items.item-1.content.core-modules.items.item-2.title`}{' '}
              </span>
              {t`faq.items.item-1.content.core-modules.items.item-2.description`}
            </li>
            <li>
              <span className="font-semibold">
                {t`faq.items.item-1.content.core-modules.items.item-3.title`}{' '}
              </span>
              {t`faq.items.item-1.content.core-modules.items.item-3.description`}
            </li>
            <li>
              <span className="font-semibold">
                {t`faq.items.item-1.content.core-modules.items.item-4.title`}{' '}
              </span>
              {t`faq.items.item-1.content.core-modules.items.item-4.description`}
            </li>
            <li>
              <span className="font-semibold">
                {t`faq.items.item-1.content.core-modules.items.item-5.title`}{' '}
              </span>
              {t`faq.items.item-1.content.core-modules.items.item-5.description`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`faq.items.item-2.title`,
      content: (
        <div>
          <p>{t`faq.items.item-2.content.p1`}</p>
          <br />
          <p className="text-3xl font-semibold">
            {t`faq.items.item-2.content.bringing-values.title`}
          </p>
          <ul className="list-disc list-inside">
            <li>{t`faq.items.item-2.content.bringing-values.items.item-1`}</li>
            <li>{t`faq.items.item-2.content.bringing-values.items.item-2`}</li>
            <li>{t`faq.items.item-2.content.bringing-values.items.item-3`}</li>
            <li>{t`faq.items.item-2.content.bringing-values.items.item-4`}</li>
            <li>{t`faq.items.item-2.content.bringing-values.items.item-5`}</li>
          </ul>
          <br />
          <p className="text-3xl font-semibold">
            {t`faq.items.item-2.content.criteria.title`}
          </p>
          <ul className="list-disc list-inside">
            <li>{t`faq.items.item-2.content.criteria.items.item-1`}</li>
            <li>{t`faq.items.item-2.content.criteria.items.item-2`}</li>
            <li>{t`faq.items.item-2.content.criteria.items.item-3`}</li>
            <li>{t`faq.items.item-2.content.criteria.items.item-4`}</li>
            <li>{t`faq.items.item-2.content.criteria.items.item-5`}</li>
            <li>{t`faq.items.item-2.content.criteria.items.item-6`}</li>
          </ul>
        </div>
      ),
    },
    {
      title: t`faq.items.item-3.title`,
      content: (
        <div>
          <ul className="list-disc list-inside">
            <li>{t`faq.items.item-3.content.item-1`}</li>
            <li>{t`faq.items.item-3.content.item-2`}</li>
          </ul>
        </div>
      ),
    },
    {
      title: t`faq.items.item-4.title`,
      content: (
        <div>
          <ul className="list-disc list-inside">
            <li>{t`faq.items.item-4.content.item-1`}</li>
            <li>{t`faq.items.item-4.content.item-2`}</li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <section className="relative bg-white md:pt-[64px] md:pb-[120px] md:px-[76px] mb-[40px] md:mb-[20px] overflow-hidden">
      <div className="text-5xl leading-[48px] md:text-7xl font-extrabold md:leading-[65px] text-center">
        <h2>{t`faq.title`}</h2>
      </div>

      <div className="md:mt-[20px] px-[30px] md:px-[100px]">
        <Accordion items={items} />
      </div>

      <div className="text-center md:mt-[32px]">
        <div className="font-bold text-xl md:text-4xl mb-[20px]">
          {t`faq.cta.title`}
        </div>
        <Button
          label={t`faq.cta.button`}
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={() => navigate(`/faq?selected=Peer Counselor`)}
        />
      </div>
    </section>
  );
}
