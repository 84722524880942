import React, { useContext, useState } from 'react';
import { CauseOfAnxiety } from '@source/types';

import { useBookingContext } from './BookingContext';
import Option from './components/Option';
import { useTranslation } from 'react-i18next';

export function Step03() {
  const { t } = useTranslation('booking');
  const { setCurrentStep, dataList03, setDataList03 } = useBookingContext();
  const options = [
    {
      label: t`questions.question-3.options.option-1`,
      value: CauseOfAnxiety.FAMILY_CONFLICT,
    },
    {
      label: t`questions.question-3.options.option-2`,
      value: CauseOfAnxiety.FEAR_OF_FLYING,
    },
    {
      label: t`questions.question-3.options.option-3`,
      value: CauseOfAnxiety.PUBLIC_SPEAKING,
    },
    {
      label: t`questions.question-3.options.option-4`,
      value: CauseOfAnxiety.SEXUAL_ABUSE_OR_HARASSMENT,
    },
    {
      label: t`questions.question-3.options.option-5`,
      value: CauseOfAnxiety.WORK_OR_SCHOOL_STRESS,
    },
    {
      label: t`questions.question-3.options.option-6`,
      value: CauseOfAnxiety.BULLYING,
    },
    {
      label: t`questions.question-3.options.option-7`,
      value: CauseOfAnxiety.ANXIETY_DUE_TO_LIFE_CHANGES,
    },
    {
      label: t`questions.question-3.options.option-8`,
      value: CauseOfAnxiety.FINANCIAL_STRESS,
    },
    {
      label: t`questions.question-3.options.option-9`,
      value: CauseOfAnxiety.OTHER,
    },
  ];

  return (
    <>
      <div className="booking-step-title">{t`questions.question-3.title`}</div>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] md:gap-[20px] mt-[30px] md:mt-[40px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList03.includes(option.value)}
            onClick={() => {
              setDataList03([option.value]);
            }}
            themeColor="md:bg-[#D8CFFC]"
            mobileThemeColor="bg-[#D8FFF3]"
            height="md:h-[75px]"
            mobileHeight="h-[70px]"
            shadow="md:shadow-[0px_4px_8px_rgba(0,0,0,0.25)]"
          />
        ))}
      </div>
    </>
  );
}
