import React from 'react';
import ArticleCard from './article-card';
import { useTranslation } from 'react-i18next';

export default function ArticlesList() {
  const { t } = useTranslation('blog');

  const articles = [
    {
      image: 'media/blog/articles/what-is-sexual-violence-thumbnail.webp',
      title:
        'The First Step to Breaking the Stigma Is to Understand Sexual Violence',
      description:
        'Sexual violence is a challenging topic, often surrounded by silence, stigma, and misunderstandings.',
      date: '06/12/24',
      author: 'Akshita Bhatia',
      slug: 'what-is-sexual-violence',
      alt: `Close up of a young woman's face with closed eyes. She looks sentimental, turning toward her inner self to reflect and understand.`,
    },
    {
      image:
        'media/blog/articles/how-sexual-violence-happens-in-familiar-settings-thumbnail.webp',
      title:
        'It Happens Here Too: Examples of Sexual Violence in Everyday Situations',
      description: `Sometimes, sexual violence doesn't look like what we might expect. It's not always in dark alleyways or through obvious physical force...`,
      date: '06/12/24',
      author: 'Akshita Bhatia',
      slug: 'how-sexual-violence-happens-in-familiar-settings',
      alt: 'A silhouette of a woman from the side sitting on the floor, looking down, and holding her face. It symbolizes her frustrations, sadness and trauma.',
    },
    {
      image:
        'media/blog/articles/the-mental-toll-of-sexual-violence-thumbnail.webp',
      title:
        'What Is the Impact of Sexual Violence on Mental Health and Well-being?',
      description:
        'Sexual violence (SV) is a deeply traumatic experience that can have lasting effects on mental health.',
      date: '06/12/24',
      author: 'Akshita Bhatia',
      slug: 'the-mental-toll-of-sexual-violence',
      alt: 'A young woman sitting on the floor hugging her knees crying. This symbolizes her traumatic experiences.',
    },
    {
      image:
        'media/blog/articles/healing-coping-and-building-resilience-thumbnail.webp',
      title: 'Recovery and Resilience: Coping Strategies After Sexual Violence',
      description:
        'If you or someone you care about has experienced sexual violence, remember that the journey to recovery is not linear, nor is it one-size-fits-all.',
      date: '06/12/24',
      author: 'Akshita Bhatia',
      slug: 'healing-coping-and-building-resilience',
      alt: `A close up portrait of a young woman's face with big eyes, looking sentimental, sad but also a bit hopeful. `,
    },
  ];

  return (
    <section className="px-[28px] md:px-[76px] mb-[40px] md:mb-[76px]">
      <h2 className="text-5xl md:text-7xl font-extrabold leading-[48px] md:leading-[65px] mb-[40px]">
        {t('tabs.articles.title')}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {articles.map((article, index) => (
          <ArticleCard
            key={index}
            image={article.image}
            title={article.title}
            description={article.description}
            date={article.date}
            author={article.author}
            slug={article.slug}
            alt={article.alt}
          />
        ))}
      </div>
    </section>
  );
}
