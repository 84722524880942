import Accordion from '@components/Accordion';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ListenerFAQ() {
  const { t } = useTranslation('faq');

  const items = [
    {
      title: t`categories.peer-counselor.items.item-1.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-1.p1`}</p>
          <br />
          <p>
            {t`categories.peer-counselor.items.item-1.p2`}{' '}
            <a href="mailto:anna@heyjuni.com" className="text-blue-800">
              anna@heyjuni.com
            </a>{' '}
            {t`categories.peer-counselor.items.item-1.p3`}
          </p>
          <br />
          <p>{t`categories.peer-counselor.items.item-1.p4`}</p>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-2.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-2.p1`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>{t`categories.peer-counselor.items.item-2.p2`}</li>
            <li>{t`categories.peer-counselor.items.item-2.p3`}</li>
          </ol>
          <br />
          <br />
          <p>{t`categories.peer-counselor.items.item-2.p4`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>{t`categories.peer-counselor.items.item-2.p5`}</li>
            <li>{t`categories.peer-counselor.items.item-2.p6`}</li>
            <li>{t`categories.peer-counselor.items.item-2.p7`}</li>
          </ol>
          <br />
          <br />
          <p>{t`categories.peer-counselor.items.item-2.p8`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>{t`categories.peer-counselor.items.item-2.p9`}</li>
            <li>{t`categories.peer-counselor.items.item-2.p10`}</li>
            <li>{t`categories.peer-counselor.items.item-2.p11`}</li>
          </ol>
          <br />
          <br />
          <p>{t`categories.peer-counselor.items.item-2.p12`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>{t`categories.peer-counselor.items.item-2.p13`}</li>
            <li>{t`categories.peer-counselor.items.item-2.p14`}</li>
            <li>{t`categories.peer-counselor.items.item-2.p15`}</li>
          </ol>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-3.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-3.p1`}</p>
          <ol className="list-decimal list-inside inline-block">
            <br />
            <li>
              <span className="font-bold">{t`categories.peer-counselor.items.item-3.p2`}</span>{' '}
              {t`categories.peer-counselor.items.item-3.p3`}
            </li>
            <br />
            <li>
              <span className="font-bold">{t`categories.peer-counselor.items.item-3.p4`}</span>{' '}
              {t`categories.peer-counselor.items.item-3.p5`}
            </li>
            <br />
            <li>
              <span className="font-bold">{t`categories.peer-counselor.items.item-3.p6`}</span>{' '}
              {t`categories.peer-counselor.items.item-3.p7`}
            </li>
            <br />
            <li>
              <span className="font-bold">{t`categories.peer-counselor.items.item-3.p8`}</span>{' '}
              {t`categories.peer-counselor.items.item-3.p9`}
            </li>
            <br />
            <li>
              <span className="font-bold">{t`categories.peer-counselor.items.item-3.p10`}</span>{' '}
              {t`categories.peer-counselor.items.item-3.p11`}
            </li>
            <br />
            <li>
              <span className="font-bold">{t`categories.peer-counselor.items.item-3.p12`}</span>{' '}
              {t`categories.peer-counselor.items.item-3.p13`}
            </li>
            <br />
            <li>
              <span className="font-bold">{t`categories.peer-counselor.items.item-3.p14`}</span>{' '}
              {t`categories.peer-counselor.items.item-3.p15`}
            </li>
            <br />
            <li>
              <span className="font-bold">{t`categories.peer-counselor.items.item-3.p16`}</span>{' '}
              {t`categories.peer-counselor.items.item-3.p17`}
            </li>
            <br />
            <li>
              <span className="font-bold">{t`categories.peer-counselor.items.item-3.p18`}</span>{' '}
              {t`categories.peer-counselor.items.item-3.p19`}
            </li>
          </ol>
          <br />
          <p>{t`categories.peer-counselor.items.item-3.p20`}</p>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-4.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-4.p1`}</p>
          <br />
          <p>{t`categories.peer-counselor.items.item-4.p2`}</p>
          <br />
          <p className="font-bold">{t`categories.peer-counselor.items.item-4.p3`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>
              <span className="font-semibold">
                {t`categories.peer-counselor.items.item-4.p4`}:
              </span>{' '}
              {t`categories.peer-counselor.items.item-4.p5`}
            </li>
            <li>
              <span className="font-semibold">{t`categories.peer-counselor.items.item-4.p6`}</span>{' '}
              {t`categories.peer-counselor.items.item-4.p7`}
            </li>
            <li>
              <span className="font-semibold">
                {t`categories.peer-counselor.items.item-4.p8`}
              </span>{' '}
              {t`categories.peer-counselor.items.item-4.p9`}
            </li>
            <li>
              <span className="font-semibold">
                {t`categories.peer-counselor.items.item-4.p10`}
              </span>{' '}
              {t`categories.peer-counselor.items.item-4.p11`}
            </li>
            <li>
              <span className="font-semibold">
                {t`categories.peer-counselor.items.item-4.p12`}
              </span>{' '}
              {t`categories.peer-counselor.items.item-4.p13`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-5.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-5.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-6.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-6.p1`}</p>
          <br />
          <p>{t`categories.peer-counselor.items.item-6.p2`}</p>
          <br />
          <p>{t`categories.peer-counselor.items.item-6.p3`}</p>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-7.title`,
      content: (
        <div>
          <p>{t`categories.peer-counselor.items.item-7.p1`}</p>
          <br />
          <p className="text-3xl font-semibold">
            {t`categories.peer-counselor.items.item-7.p2`}
          </p>
          <ul className="list-disc list-inside">
            <li>{t`categories.peer-counselor.items.item-7.p3`}</li>
            <li>{t`categories.peer-counselor.items.item-7.p4`}</li>
            <li>{t`categories.peer-counselor.items.item-7.p5`}</li>
            <li>{t`categories.peer-counselor.items.item-7.p6`}</li>
            <li>{t`categories.peer-counselor.items.item-7.p7`}</li>
          </ul>
          <br />
          <p className="text-3xl font-semibold">
            {t`categories.peer-counselor.items.item-7.p8`}
          </p>
          <ul className="list-disc list-inside">
            <li>{t`categories.peer-counselor.items.item-7.p9`}</li>
            <li>{t`categories.peer-counselor.items.item-7.p10`}</li>
            <li>{t`categories.peer-counselor.items.item-7.p11`}</li>
            <li>{t`categories.peer-counselor.items.item-7.p12`}</li>
            <li>{t`categories.peer-counselor.items.item-7.p13`}</li>
            <li>{t`categories.peer-counselor.items.item-7.p14`}</li>
          </ul>
        </div>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-8.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-8.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-9.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-9.p1`}</p>
          <br />
          <p>{t`categories.peer-counselor.items.item-9.p2`}</p>
          <br />
          <p>{t`categories.peer-counselor.items.item-9.p3`}</p>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-10.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-10.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-11.title`,
      content: (
        <>
          <p>{t`categories.peer-counselor.items.item-11.p1`}</p>
          <br />
          <p className="font-bold">{t`categories.peer-counselor.items.item-11.p2`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>{t`categories.peer-counselor.items.item-11.p3`}</li>
            <li>{t`categories.peer-counselor.items.item-11.p4`}</li>
            <li>{t`categories.peer-counselor.items.item-11.p5`}</li>
            <li>{t`categories.peer-counselor.items.item-11.p6`}</li>
          </ol>
          <br />
          <br />
          <p className="font-bold">{t`categories.peer-counselor.items.item-11.p7`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>{t`categories.peer-counselor.items.item-11.p8`}</li>
            <li>{t`categories.peer-counselor.items.item-11.p9`}</li>
            <li>{t`categories.peer-counselor.items.item-11.p10`}</li>
            <li>{t`categories.peer-counselor.items.item-11.p11`}</li>
            <li>{t`categories.peer-counselor.items.item-11.p12`}</li>
          </ol>
          <br />
          <br />
          <p className="font-bold">{t`categories.peer-counselor.items.item-11.p13`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>{t`categories.peer-counselor.items.item-11.p14`}</li>
            <li>{t`categories.peer-counselor.items.item-11.p15`}</li>
            <li>{t`categories.peer-counselor.items.item-11.p16`}</li>
          </ol>
          <br />
          <br />
          <p>{t`categories.peer-counselor.items.item-11.p17`}</p>
        </>
      ),
    },
    {
      title: t`categories.peer-counselor.items.item-12.title`,
      content: (
        <div>
          <ul className="list-disc list-inside">
            <li>{t`categories.peer-counselor.items.item-12.p1`}</li>
            <li>{t`categories.peer-counselor.items.item-12.p2`}</li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      <p className="text-5xl font-extrabold mb-10">
        {t`categories.peer-counselor.title`}
      </p>
      <Accordion items={items} />
    </>
  );
}
