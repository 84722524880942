import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';

import { StepProgressBar } from './components/StepProgressBar';
import MainLayout from '@src/layouts/MainLayout';
import Button from './components/Button';

import { Step01 } from './step-01';
import { BookingProvider, useBookingContext } from './BookingContext';
import { Step02 } from './step-02';
import { Step03 } from './step-03';
import { Step04 } from './step-04';
import { Step05 } from './step-05';
import { Step06 } from './step-06';
import { Step07 } from './step-07';
import { Step08 } from './step-08';
import { Step09 } from './step-09';
import { Step10 } from './step-10';
import { Step11 } from './step-11';
import { Step12 } from './step-12';
import { Step13 } from './step-13';
import { TermConditionContent } from './components/TermConditionContent';

import { PopUp } from '@components/Popup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useScrollToTopFunction } from '@src/hooks/useScrollToTop';

import './index.css';
import { BookingTimeType } from '@src/types/booking';
import { BookNowReady } from './components/BookNowReady';
import { useTranslation } from 'react-i18next';

export default function Booking() {
  return (
    <BookingProvider>
      <MainLayout>
        <BookingContent />
      </MainLayout>
    </BookingProvider>
  );
}

function BookingContent() {
  const { t, i18n } = useTranslation('booking');

  const [searchParams] = useSearchParams();
  const locale = searchParams.get('locale');

  useEffect(() => {
    if (locale && ['vn', 'en'].includes(locale)) {
      localStorage.setItem('preferredLanguage', locale);
      i18n.changeLanguage(locale);
      console.log('i18n.language', i18n.language);
    }
  }, [locale]);
  // TODO: Use reducer here
  const {
    currentStep,
    setCurrentStep,
    address,
    setAddress,
    anxiousReason,
    setAnxiousReason,
    dataList03,
    dataList04,
    dataList06,
    dataList07,
    dataList09,
    status,

    email,
    isChecked,
    isEmailValid,

    age,
    gender,

    bookingTimeType,
    selectedTimezone,
    selectedDate,
    startTime,
  } = useBookingContext();

  const [showTermCondition, setShowTermCondition] = useState(false);
  const [openBookNowPopup, setOpenBookNowPopup] = useState(false);

  const navigate = useNavigate();

  const scrollToTop = useScrollToTopFunction();

  const currentStepForm = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <Step01 />;
      case 2:
        return <Step02 />;
      case 3:
        return <Step03 />;
      case 4:
        return <Step04 />;
      case 5:
        return <Step05 />;
      case 6:
        return <Step06 />;
      case 7:
        return <Step07 />;
      case 8:
        return <Step08 />;
      case 9:
        return <Step09 />;
      case 10:
        return <Step10 setOpenBookNowPopup={setOpenBookNowPopup} />;
      case 11:
        return <Step11 setShowTermCondition={setShowTermCondition} />;
      case 12:
        return <Step12 />;
      case 13:
        return <Step13 />;
      default:
        return <>Mock screen</>;
    }
  }, [currentStep]);

  const handleSubmit = () => {
    if (currentStep === 12) navigate(`/booking?page=success`);
    if (currentStep + 1 <= 13) setCurrentStep(currentStep + 1);
    scrollToTop();
  };

  const showNextButton = useMemo(() => {
    switch (currentStep) {
      case 1:
        return !!address;
      case 2:
        return true;
      case 3:
        return dataList03.length > 0;
      case 4:
        return dataList04.length > 0;
      case 5:
        return true;
      case 6:
        return dataList06.length > 0;
      case 7:
        return true;
      case 8:
        return age !== 'Select Category' && gender !== 'Select Category';
      case 9:
        return dataList09.length > 0;
      case 10:
        if (bookingTimeType === BookingTimeType.BOOK_NOW) return true;
        else if (bookingTimeType === BookingTimeType.SCHEDULE_SESSION) {
          return selectedTimezone && selectedDate && startTime;
        }
        return false;
      case 11:
        return !!email && !!isChecked && isEmailValid;
      default:
        return true;
    }
  }, [
    currentStep,
    address,
    dataList03,
    dataList04,
    dataList06,
    dataList07,
    dataList09,
    status,
    email,
    isChecked,
    age,
    gender,
    bookingTimeType,
    selectedTimezone,
    selectedDate,
    startTime,
  ]);

  const isFirstScreen = currentStep === 1;
  const isPaymentScreen = currentStep === 12;
  const isConfirmScreen = currentStep === 13;
  const isExpandableScreen = [8, 10, 13].includes(currentStep);

  return (
    <>
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>HeyJuni - Book Your Session</title>
        <meta
          name="description"
          content="Book your peer support session with HeyJuni. Follow the guided steps to connect with a counselor for your mental health needs."
        />
        <meta
          name="keywords"
          content="HeyJuni Booking, Peer Support, Mental Health, Online Sessions, Schedule"
        />
        <meta property="og:title" content="Book Your Session - HeyJuni" />
        <meta
          property="og:description"
          content="Schedule your peer support session with HeyJuni. Our step-by-step process ensures a seamless booking experience."
        />
      </Helmet>
      <div
        className={clsx(
          'relative flex flex-col h-screen md:h-auto items-start md:items-center justify-between md:justify-center md:bg-[#AF9CFB] md:py-[28px]',
          {
            'md:min-h-0': [8].includes(currentStep),
          }
        )}
        style={
          window.innerWidth < 768
            ? { height: `${window.innerHeight - 62}px`, overflow: 'auto' }
            : undefined
        }
      >
        {/* NOTE: Change pb from 40 -> 20 to fit all screen */}
        <div
          className={clsx(
            'relative flex flex-col justify-between bg-white pt-[25px] px-[28px] md:rounded-[24px] md:shadow-lg z-10 w-full md:w-[83.4375%] md:px-[64px] md:pt-[30px] md:pb-[30px]',
            {
              'h-auto md:h-auto md:min-h-[calc(100vh-146px)]':
                isExpandableScreen,
            },
            {
              'md:h-[calc(100vh-146px)] md:min-h-[548px] md:max-h-[680px]':
                !isExpandableScreen,
            }
          )}
        >
          <div className="flex-1 flex flex-col">
            <StepProgressBar total={13} currentStep={currentStep} />
            <div className="mt-[16px] md:mt-[30px] flex-1 flex flex-col items-between">
              {currentStepForm}
            </div>

            {/* Next and Back Button in desktop screen */}
            {!isConfirmScreen && (
              <div
                className={clsx('hidden md:flex justify-between', {
                  '!justify-end': isFirstScreen,
                })}
              >
                {!isFirstScreen && (
                  <Button
                    label={t`navigation.back`}
                    variant="outlined"
                    onClick={() => {
                      if (currentStep - 1 > 0) setCurrentStep(currentStep - 1);
                      scrollToTop();
                    }}
                  />
                )}

                {showNextButton && !isPaymentScreen && (
                  <Button label={t`navigation.next`} onClick={handleSubmit} />
                )}
              </div>
            )}

            {/* Return home button for desktop, mobile screen */}
            {isConfirmScreen && (
              <div className="w-full flex flex-row justify-center">
                <Button
                  label={t`cta`}
                  onClick={() => {
                    navigate('/');
                  }}
                  className="mt-[20px] md:mt-[32px] w-[304px] md:w-[400px]"
                />
              </div>
            )}
          </div>

          {isPaymentScreen && (
            <div className={clsx('flex md:hidden mt-[30px] flex-col gap-2')}>
              <Button
                label="Back"
                variant="outlined"
                onClick={() => {
                  if (currentStep - 1 > 0) setCurrentStep(currentStep - 1);
                  scrollToTop();
                }}
              />
            </div>
          )}
        </div>

        {/* Next and Back Button in mobile screen */}
        {!isConfirmScreen && (
          <div
            className={clsx('md:hidden flex justify-between p-[28px] w-full', {
              hidden: isPaymentScreen,
              '!justify-end': isFirstScreen,
            })}
          >
            {!isFirstScreen && (
              <Button
                label="Back"
                variant="outlined"
                onClick={() => {
                  if (currentStep - 1 > 0) setCurrentStep(currentStep - 1);
                  scrollToTop();
                }}
              />
            )}

            {showNextButton && !isPaymentScreen && (
              <Button label="Next" onClick={handleSubmit} />
            )}
          </div>
        )}

        {/* Open conditions and terms pop-up */}
        <PopUp
          isOpen={showTermCondition}
          onClose={() => setShowTermCondition(false)}
          isGlobalOverlap={true}
          className="rounded-[17px] md:rounded-[15px] w-5/6 md:w-auto"
        >
          <TermConditionContent onClose={() => setShowTermCondition(false)} />
        </PopUp>

        {/* Open book now pop-up */}
        <PopUp
          isOpen={openBookNowPopup}
          onClose={() => setOpenBookNowPopup(false)}
          isGlobalOverlap={true}
          className="rounded-[17px] w-[94%] md:w-[55%]"
        >
          <BookNowReady onClose={() => setOpenBookNowPopup(false)} />
        </PopUp>
      </div>
    </>
  );
}
