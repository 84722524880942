import appLogo from '@assets/logo.svg';
import { Link } from 'react-router-dom';
import { PopUp } from '@components/Popup';
import ClickableObject from '../ClickableObject';
import languageIcon from '@assets/icons/shared/language.svg';
import arrowDownIcon from '@assets/icons/shared/arrow-down.svg';
import mobileMenuIcon from '@assets/icons/shared/menu-icon.svg';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import Button from '@components/Button';
import LanguageSwitcher from './LanguageSwitcher';

const DesktopNavLinks = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation('header');
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  // Call the custom hook with isOpenMenu to block or allow scrolling

  const aboutRef = useRef(null);

  return (
    <>
      <span
        className="relative inline-flex items-center gap-[10px] cursor-pointer"
        onClick={(e) => {
          if (isOpenMenu) {
            setIsOpenMenu(false);
          } else setIsOpenMenu(true);
        }}
        ref={aboutRef}
      >
        {t`about`}
        <ClickableObject data={arrowDownIcon} className="h-[6px] w-[10px]" />
        <PopUp
          isOpen={isOpenMenu}
          onClose={() => setIsOpenMenu(false)}
          baseRef={aboutRef}
        >
          <div className="flex flex-col p-[20px] gap-[15px] font-regular text-lg leading-[23px]">
            <Link to="/team-juni">{t`team-hey-juni`}</Link>
            <Link to="/mission-and-vision">{t`mission-vision`}</Link>
          </div>
        </PopUp>
      </span>
      <Link to="/learn-more">{t`our-services`}</Link>
      <Link to="/become-a-listener">{t`become-peer-counselor`}</Link>
      <Link to="/pricing">{t`pricing`}</Link>
      <Link to="/blog">{t`blog`}</Link>
    </>
  );
};

export default function CommonHeader({
  toggleMenu,
  navigate,
}: {
  toggleMenu: () => void;
  navigate: (path: string) => void;
}) {
  const { t } = useTranslation('header');
  const { i18n } = useTranslation();

  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const languageRef = useRef(null);

  return (
    <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50 h-[62px] md:h-[90px]">
      <nav
        id="desktop-header"
        className="mx-auto flex items-center justify-between md:px-[33px] md:py-[23px] h-full"
      >
        <div className="flex items-center gap-[50px] pl-[20px] md:pl-0">
          <img
            src={appLogo}
            className="w-[64px] h-[44px] md:w-[74px] md:h-[50px] cursor-pointer"
            onClick={() => navigate('/')}
            alt="HeyJuni Logo"
          />
          <ul className="hidden sm:flex space-x-[30px] font-regular text-lg">
            <DesktopNavLinks />
          </ul>
        </div>
        <div className="flex items-center space-x-[30px]">
          <Button
            label={t`book-session`}
            className="font-semibold text-base md:text-xl h-[34px] md:h-[44px] leading-[18px] md:leading-[30px] rounded-[10px] md:rounded-[15px] px-[15px] md:px-[20px]"
            onClick={() => navigate('/booking')}
          />
          <div
            className="flex items-center space-x-[10px] cursor-pointer"
            onClick={(e) => {
              if (isOpenLanguage) {
                setIsOpenLanguage(false);
              } else setIsOpenLanguage(true);
            }}
            ref={languageRef}
          >
            <ClickableObject
              data={languageIcon}
              className="h-[20px] w-[20px]"
            />
            <span className="font-regular text-lg leading-[40px]">
              {i18n.language}
            </span>
            <ClickableObject
              data={arrowDownIcon}
              className="h-[6px] w-[10px]"
            />

            <PopUp
              isOpen={isOpenLanguage}
              onClose={() => setIsOpenLanguage(false)}
              baseRef={languageRef}
              right={20}
            >
              <div className="flex flex-col p-[20px] gap-[15px] font-regular text-lg leading-[23px]">
                <LanguageSwitcher />
              </div>
            </PopUp>
          </div>
        </div>
        <div className="block sm:hidden">
          <ClickableObject
            data={mobileMenuIcon}
            onClick={toggleMenu}
            className="w-[19px] h-[12px] mr-[20px]"
          />
        </div>
      </nav>
    </header>
  );
}
