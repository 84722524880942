import React, { useContext } from 'react';

import { useBookingContext } from './BookingContext';
import { useTranslation } from 'react-i18next';

export function Step01() {
  const { t } = useTranslation('booking');
  const { address, setAddress, setCurrentStep } = useBookingContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setCurrentStep(2);
    }
  };

  return (
    <div>
      <h1 className="text-4xl md:text-5xl font-extrabold mb-[16px] md:mb-[24px]">
        {t`questions.question-1.title`}
      </h1>

      <p className="font-regular text-base md:text-lg mb-[16px] md:mb-[24px]">
        {t`questions.question-1.body-1`}
      </p>
      <p className="font-regular text-base md:text-lg mb-[16px] md:mb-[24px]">
        {t`questions.question-1.body-2`}
      </p>

      <div>
        <h2 className="text-base md:text-3xl font-semibold my-[16px] md:my-[24px]">
          {t`questions.question-1.question`}
        </h2>
        <input
          type="text"
          placeholder={t`questions.question-1.placeholder`}
          className="w-full border-b border-black focus:outline-none focus:border-black placeholder-gray-500 font-regular text-base md:text-lg"
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
}
