import React from 'react';

export default function WhatIsSexualViolence() {
  return (
    <div>
      <img
        src="media/blog/articles/what-is-sexual-violence.webp"
        alt="what-is-sexual-violence"
        className="w-full"
      />
      <p className="mt-5 text-[15px] md:text-lg">
        Sexual violence is a challenging topic, often surrounded by silence,
        stigma, and misunderstandings. It's also insidious, not always explicit
        or easy to recognize —yet its effects are deeply felt. It's crucial to
        deepen our understanding of what sexual violence is and what it could
        look like. Awareness is the first step toward identifying, confronting,
        and ultimately supporting those impacted. Beyond the legal definitions,
        it's about human experiences, the impact of trauma, and, ultimately,
        finding paths toward healing and empowerment for those who have
        experienced sexual violence. In this article, we'll explore the nuances
        of sexual violence, how it shows up in daily life, and why talking about
        it matters — for everyone.
      </p>
      <p className="text-xl md:text-4xl font-bold mt-16">
        Defining Sexual Violence
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        At its core, sexual violence is any sexual act or attempt to obtain a
        sexual act by coercion, unwanted sexual advances, or acts directed
        against a person's sexuality using force, harassment, manipulation, or
        without their consent. Sexual violence spans a range of behaviors, from
        unwanted touching and inappropriate comments to more severe acts like
        assault and rape. It's important to recognize that sexual violence isn't
        limited to any single demographic; it can happen to people of all
        genders, ages, races, and socioeconomic backgrounds. One of the central
        factors in sexual violence is consent. Consent must be freely given,
        informed, enthusiastic, and can be withdrawn at any time. If someone is
        under duress, impaired, or feels pressured into a sexual situation,
        genuine consent isn't possible. A lack of consent turns any interaction
        into an act of sexual violence.
      </p>
      <p className="text-xl md:text-4xl font-bold mt-16">
        Common Forms of Sexual Violence
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        Sexual violence can take many forms, and understanding these
        distinctions can help in recognizing and addressing them:
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        1. Sexual Harassment
      </p>
      <p className="text-[15px] md:text-lg">
        Often manifesting in workplaces, schools, and even public spaces, sexual
        harassment includes unwelcome advances, comments, or actions of a sexual
        nature. This might look like making unwanted sexual jokes, sending
        explicit texts, or repeatedly asking for dates despite being turned
        down. While it may be dismissed by some as "harmless," sexual harassment
        can lead to lasting psychological and emotional harm.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        2. Sexual Assault and Rape
      </p>
      <p className="text-[15px] md:text-lg">
        Sexual assault is any unwanted sexual contact, ranging from groping to
        more invasive forms of assault. Rape is a specific type of sexual
        assault that involves forced or non-consensual penetration. Both can
        have serious physical, mental, and emotional repercussions. Importantly,
        acts like rape aren't about sexual gratification; they're about control,
        power, and stripping the person being assaulted of their autonomy.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        3. Coercion and Manipulation
      </p>
      <p className="text-[15px] md:text-lg">
        Sometimes, sexual violence isn't violent in the traditional sense but
        relies on manipulation or pressure. Coercion can involve making someone
        feel obligated or intimidated into sexual acts through guilt, blackmail,
        or emotional manipulation.
      </p>
      <p className="mt-5 text-[15px] md:text-lg font-semibold">
        4. Online Sexual Harassment and Image-Based Abuse
      </p>
      <p className="text-[15px] md:text-lg">
        With the rise of digital spaces, sexual violence has extended online.
        Image-based abuse (such as non-consensual sharing of intimate images)
        and online harassment are forms of sexual violence that can be just as
        harmful and invasive as physical acts.
      </p>
      <p className="text-xl md:text-4xl font-bold mt-16">
        Why Recognizing Sexual Violence is Important?
      </p>
      <p className="mt-5 text-[15px] md:text-lg">
        Sexual violence isn't just a "private matter" or something that happens
        in rare cases. It affects millions worldwide, with ripple effects that
        touch families, workplaces, communities, and beyond. When we fail to
        recognize and confront sexual violence, we contribute to a culture that
        allows it to persist. By acknowledging it, speaking out, and showing
        support, we foster a safer and more inclusive society.
      </p>
    </div>
  );
}
