import React, { useState } from 'react';
import { OutcomeExpectation } from '@source/types';
import { useBookingContext } from './BookingContext';
import Option from './components/Option';
import { useTranslation } from 'react-i18next';

export function Step06() {
  const { t } = useTranslation('booking');
  const { setCurrentStep, dataList06, setDataList06 } = useBookingContext();

  // Updated options based on the image
  const options = [
    {
      label: t`questions.question-6.options.option-1`,
      value: OutcomeExpectation.OPEN_UP,
    },
    {
      label: t`questions.question-6.options.option-2`,
      value: OutcomeExpectation.HELP_TO_CALM,
    },
    {
      label: t`questions.question-6.options.option-3`,
      value: OutcomeExpectation.LEARN_TECHNIQUES,
    },
    {
      label: t`questions.question-6.options.option-4`,
      value: OutcomeExpectation.NEED_ENCOURAGEMENT,
    },
    {
      label: t`questions.question-6.options.option-5`,
      value: OutcomeExpectation.UNDERSTAND_TRIGGERS,
    },
    {
      label: t`questions.question-6.options.option-6`,
      value: OutcomeExpectation.OTHER,
    },
  ];

  return (
    <>
      <h1 className="booking-step-title">{t`questions.question-6.title`}</h1>
      <div className="grid grid-cols-2 sm:grid-cols-2 gap-[10px] md:gap-x-[15px] md:gap-y-[20px] mt-[30px] md:mt-[40px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList06.includes(option.value)}
            onClick={() => {
              setDataList06([option.value]);
            }}
            themeColor="md:bg-[#D8CFFC]"
            mobileThemeColor="bg-[#D8FFF3]"
            // height="md:h-[90px]"
            height="md:h-[80px]" // Change to fit the screen
            mobileHeight="h-[110px]"
            shadow="md:shadow-[0px_4px_8px_rgba(0,0,0,0.25)]"
          />
        ))}
      </div>
    </>
  );
}
