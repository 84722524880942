import { Helmet } from 'react-helmet';
import { IntroSection } from './intro-section';
import { LetJoinSection } from './let-join-section';
import { SubscribeSection } from './subscribe-section';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function VnHome() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('preferredLanguage', 'vn');
    i18n.changeLanguage('vn');
    navigate('/');
  }, []);

  return (
    <div className="overflow-hidden">
      {/* Helmet for SEO */}
      <Helmet>
        <title>
          HeyJuni | Anonymous, 1-on-1 Mental Support - Within Minutes
        </title>
        <meta
          name="description"
          content="We help women overcome personal struggles, emotional trauma, anxiety, and depression through 24/7 online peer counseling. Get matched in 15 minutes with a Listener to talk anonymously."
        />
        <meta
          name="keywords"
          content="Workflow, Quiz, Comparison, Offers, Testimonials, Get Involved, Subscribe"
        />
        <meta property="og:title" content="Heyjuni - Home" />
        <meta
          property="og:description"
          content="Discover our services, workflow, and offers. Join our community today!"
        />
      </Helmet>

      <IntroSection />
      <LetJoinSection />
      <SubscribeSection />
    </div>
  );
}

export default VnHome;
