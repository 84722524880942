import closeIcon from '@assets/icons/shared/close-icon.svg';
import PaperPlaneIcon from '@assets/icons/shared/paper-plane-icon.svg';
import Button from '@components/Button';
import { useBookingContext } from '../BookingContext';
import { BookingTimeType } from '@src/types/booking';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

interface BookNowReadyProps {
  onClose: () => void;
}

export function BookNowReady(props: BookNowReadyProps) {
  const { t } = useTranslation('booking');
  const { onClose } = props;

  const { setBookingTimeType } = useBookingContext();

  return (
    <div className="relative">
      <div
        className="absolute right-[24px] top-[24px] md:right-[35px] md:top-[30px] cursor-pointer w-[15px] h-[15px] md:w-auto md:h-auto flex items-center justify-center"
        onClick={onClose}
      >
        <NormalObject
          data={closeIcon}
          className="w-[15px] h-[15px] object-contain"
        />
      </div>

      {/* Popup content */}
      <div className="flex flex-col items-center justify-center w-full p-[30px] bg-white text-center space-y-[24px]">
        <div className="flex flex-col items-center">
          <NormalObject data={PaperPlaneIcon} />
          <div className="text-xl md:text-3xl leading-[26px] font-bold mt-[10px] md:mt-[20px]">
            {t`questions.question-10.get-ready-popup.p1`}
          </div>
        </div>

        <p className="text-[15px] md:text-lg">
          {t`questions.question-10.get-ready-popup.p2`} <br />
          <span className="font-semibold text-black underline">15</span>{' '}
          {t`questions.question-10.get-ready-popup.p3`}
        </p>
        <p className="text-[15px] md:text-lg">
          {t`questions.question-10.get-ready-popup.p4`}
        </p>

        <Button
          label="Okay"
          className="h-[50px] rounded-[24px] md:rounded-[20px] px-[56px] md:px-[56px] font-semibold text-lg"
          onClick={() => {
            onClose();
            setBookingTimeType(BookingTimeType.BOOK_NOW);
          }}
        />
      </div>
    </div>
  );
}
