import healing01StepImg from '@assets/footages/learn-more/healing-step01.svg';
import healing02StepImg from '@assets/footages/learn-more/healing-step02.svg';
import healing03StepImg from '@assets/footages/learn-more/healing-step03.svg';
import healing04StepImg from '@assets/footages/learn-more/healing-step04.svg';
import healing05StepImg from '@assets/footages/learn-more/healing-step05.svg';

import clsx from 'clsx';
import { Swipe } from '@components/Swipe';
import { MobileSwipe } from '@components/MobileSwipe';

export type Card_ = {
  title: string;
  description: string;
  image: string;
  imageMobile: string;
  alt: string;
};
export type Cards = Card_[];
export type SwipeProps = {
  cards: Cards;
};

const currentStepList = [
  healing01StepImg,
  healing02StepImg,
  healing03StepImg,
  healing04StepImg,
  healing05StepImg,
];

const desktopCurrentStepList = [healing05StepImg, ...currentStepList, null];

const CARD_WIDTH = 664;
const GAP = 76;
const BOUNDARY_PADDING = 76;

export function HealingSwipe({ cards }: SwipeProps) {
  const desktopCards = [
    {
      title: 'Find Empowerment, A Sense of Peace, and Inner Strength',
      description:
        "Talking to HeyJuni's Peer Counselors enables emotional growth and resilience. By sharing your feelings, you'll experience relief and gain insights into the triggers and signs of your distress. Through supportive conversations, you'll develop effective coping and grounding strategies, building your ability to navigate life's challenges.",
      image: 'media/learn-more/healing05.webp',
      imageMobile: 'media/learn-more/healing05-mobile.webp',
      alt: 'A profile image of a young woman looking reflective, gazing inward as she embraces her journey toward inner strength and resilience.',
    },
    ...cards,
    {
      title: '',
      description: '',
      image: '',
      imageMobile: '',
      alt: '',
    },
  ];

  return (
    <Swipe
      currentStepList={desktopCurrentStepList}
      cardWidth={CARD_WIDTH}
      gap={GAP}
      boundaryPadding={BOUNDARY_PADDING}
    >
      {desktopCards.map((card, index) => {
        const className =
          index === 0
            ? 'md:pr-[38px] md:pl-[76px]'
            : index === desktopCards.length - 1
              ? 'md:pl-[38px] md:pr-[76px]'
              : 'md:px-[38px]';

        return (
          <Card
            title={card.title}
            description={card.description}
            image={card.image}
            imageMobile={card.imageMobile}
            className={clsx('pb-[10px]', className)}
            alt={card.alt}
          />
        );
      })}
    </Swipe>
  );
}

interface CardProps {
  title: string;
  description: string;
  image: string;
  imageMobile: string;
  className?: string;
  alt: string;
}

function Card(props: CardProps) {
  const { title, description, image, imageMobile, className, alt } = props;
  const emptyCard = !title;
  const emptyCardWith = (window.innerWidth - 2 * GAP - CARD_WIDTH) / 2 - 16; // TODO: 16 is just a delta param, maybe have a more robust solution

  if (emptyCard) {
    return (
      <div className={clsx(className)}>
        <div
          className="relative md:w-[664px] md:h-[357px] overflow-hidden"
          style={{
            ...(window.innerWidth >= 768 && {
              width: `${emptyCardWith}px`,
            }),
          }}
        ></div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'hidden md:inline-flex flex-col gap-[28px] md:gap-[20px] items-center',
        className
      )}
    >
      <div className="w-[88%] h-[452px] md:w-[664px] md:h-[357px] flex flex-row overflow-hidden rounded-[20px] shadow-[0_5px_10px_rgba(0,0,0,0.1)]">
        <div className="flex-1">
          <div className="px-[12px] py-[8px] md:px-[20px] md:pt-[20px] font-semibold text-base md:text-3xl">
            {title}
          </div>
          <div className="px-[12px] py-[8px] md:p-[20px] font-regular text-sm md:text-lg">
            {description}
          </div>
        </div>
        <div className="relative">
          <img
            src={image}
            alt={alt}
            className="hidden md:block max-w-full max-h-full object-contain"
          />
          <img
            src={imageMobile}
            alt={alt}
            className="md:hidden max-w-full max-h-full object-contain"
          />
          <div className="absolute top-0 left-0 p-[10px] md:p-0 md:top-[20px] md:left-[20px] text-white font-semibold md:font-bold text-xl md:text-4xl"></div>
        </div>
      </div>
    </div>
  );
}

function MobileCards({ cards }: SwipeProps) {
  return (
    <>
      {cards.map((card, index) => {
        const className = '!inline-flex min-w-full';

        return (
          <Card
            title={card.title}
            description={card.description}
            image={card.image}
            imageMobile={card.imageMobile}
            className={clsx('pb-[10px]', className)}
            alt={card.alt}
          />
        );
      })}
    </>
  );
}

export function HealingSwipeMobile({ cards }: SwipeProps) {
  return (
    <MobileSwipe dataList={cards}>
      <MobileCards cards={cards} />
    </MobileSwipe>
  );
}
