import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function TalkToListenerSection() {
  const { t } = useTranslation('learn-more');
  const navigate = useNavigate();

  return (
    <section className="relative bg-[#151515] py-[50px] md:py-[80px] overflow-hidden">
      <div className="text-5xl leading-[48px] md:text-7xl font-extrabold md:leading-[65px] text-white text-center md:w-2/3 mx-auto">
        {t`talk.title`}
      </div>

      <div className="text-center mt-[20px]">
        <Button
          label={t`talk.cta`}
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={() => navigate('/booking')}
          // TODO: Put this className in css file
        />
      </div>
    </section>
  );
}
