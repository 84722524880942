import { ReactNode } from 'react';
import Button from '@components/Button';
import CheckIcon from '@assets/footages/pricing/check-icon.svg';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

interface CardProps {
  title: string;
  src: string;
  srcMobile: string;
  oldPrice: string;
  price: string;
  priceFrequency: ReactNode;
  description: string;
  features: (string | ReactNode)[];
  buttonText: string;
  onClick: () => void;
}

function Card({
  title,
  src,
  srcMobile,
  oldPrice,
  price,
  priceFrequency,
  description,
  features,
  buttonText,
  onClick,
}: CardProps) {
  const { t } = useTranslation('pricing');
  return (
    <div className="relative overflow-hidden bg-white rounded-lg shadow-lg py-8 px-5 flex flex-col items-center text-center border max-w-96">
      <div className="hidden md:block absolute top-0 left-0 w-full">
        <NormalObject data={src} className="w-full" />
      </div>

      <div className="md:hidden absolute top-0 left-0 w-full">
        <NormalObject data={srcMobile} className="w-full" />
      </div>

      <div className="flex-1">
        <h2 className="font-bold text-xl leading-7 mb-9">{title}</h2>

        <div className="flex flex-row items-start justify-center gap-2.5 mb-5">
          <div>
            <div className="font-extrabold text-5xl leading-10">{price}</div>
            <div className="text-left text-[#979797] font-semibold text-lg pt-1">
              {t('normally')} {oldPrice}
            </div>
          </div>

          <div className="font-semibold leading-[18px]">
            <div className="text-left">{priceFrequency}</div>
          </div>
        </div>

        <div className="mb-6">{description}</div>

        <h3 className="font-semibold mb-2.5">{t`plans.pay-as-you-go.features.expect`}</h3>

        <div className="text-left space-y-2.5">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-row items-start gap-3.5 text-sm"
            >
              <div className="w-5 h-5">
                <NormalObject data={CheckIcon} className="w-5 h-5" />
              </div>
              <div className="flex-1">{feature}</div>
            </div>
          ))}
        </div>
      </div>

      <Button
        label={buttonText}
        className="mt-12 h-8.5 md:h-15 rounded-lg md:rounded-xl px-7.5 font-semibold text-base md:text-2xl"
        onClick={onClick}
      />
    </div>
  );
}

export default Card;
