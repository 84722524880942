import React, { useState } from 'react';
import Option from './components/Option';
import { useBookingContext } from './BookingContext';
import { useTranslation } from 'react-i18next';

export function Step07() {
  const { t } = useTranslation('booking');
  const { setCurrentStep, dataList07, setDataList07 } = useBookingContext();

  const options = [
    { label: t`questions.question-7.options.option-1`, value: 'YES' },
    { label: t`questions.question-7.options.option-2`, value: 'NO' },
  ];

  return (
    // TODO: ?? Layout for mobile screen
    <>
      <h1 className="booking-step-title">{t`questions.question-7.title`}</h1>
      <div className="grid grid-cols-1 gap-[20px] mt-[30px] w-full mx-auto md:px-[204px]">
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={dataList07.includes(option.value)}
            onClick={() => setDataList07([option.value])}
            themeColor="md:bg-[#CCFBF1]"
            mobileThemeColor="bg-[#D8CFFC]"
            height="md:h-[62px]"
            mobileHeight="h-[50px]"
          />
        ))}
      </div>
    </>
  );
}
