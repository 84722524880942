import whyImage01 from '@assets/footages/become-a-listener/why-01.svg';
import whyImage02 from '@assets/footages/become-a-listener/why-02.svg';
import whyImage03 from '@assets/footages/become-a-listener/why-03.svg';
import whyImage04 from '@assets/footages/become-a-listener/why-04.svg';
import whyImage05 from '@assets/footages/become-a-listener/why-05.svg';
import whyImage06 from '@assets/footages/become-a-listener/why-06.svg';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

export function WhyBecomeSupportersSection() {
  const { t } = useTranslation('become-a-listener');
  const cardData = [
    {
      src: whyImage01,
      title: t`why.cards.card-1.title`,
      description: t`why.cards.card-1.description`,
    },
    {
      src: whyImage02,
      title: t`why.cards.card-2.title`,
      description: t`why.cards.card-2.description`,
    },
    {
      src: whyImage03,
      title: t`why.cards.card-3.title`,
      description: t`why.cards.card-3.description`,
    },
    {
      src: whyImage04,
      title: t`why.cards.card-4.title`,
      description: t`why.cards.card-4.description`,
    },
    {
      src: whyImage05,
      title: t`why.cards.card-5.title`,
      description: t`why.cards.card-5.description`,
    },
    {
      src: whyImage06,
      title: t`why.cards.card-6.title`,
      description: t`why.cards.card-6.description`,
    },
  ];

  return (
    <div className="mb-[84px] md:mb-0 md:px-[76px] md:py-[20px] text-center">
      <div className="text-center mb-[54px] md:mb-[42px] font-extrabold text-5xl md:text-7xl leading-[48px] md:leading-[65px]">
        <h2>{t`why.title`}</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-[60px] gap-y-[60px] md:gap-y-[80px] px-[68px] md:px-[40px] md:py-[40px]">
        {cardData.map((data, index) => (
          <Card
            key={index}
            src={data.src}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>
    </div>
  );
}

interface CardProps {
  src: string;
  title: string;
  description: string;
}

function Card(props: CardProps) {
  const { src, title, description } = props;
  return (
    <div
      className={`relative overflow-hidden text-center flex flex-col gap-[20px] items-center`}
    >
      <div className="md:min-h-[62px]">
        <NormalObject data={src} />
      </div>
      <h3 className="font-bold text-xl md:text-4xl md:leading-[40px]">
        {title}
      </h3>
      <p className="text-[15px] md:text-lg leading-[22px] md:leading-[26px]">
        {description}
      </p>
    </div>
  );
}
