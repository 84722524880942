import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useScrollToTop from '@src/hooks/useScrollToTop';
import CommonHeader from './CommonHeader';
import MobileMenu from './MobileMenu';

interface HeaderProps {}

// Main Header Component
export default function Header(props: HeaderProps) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'visible';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isMenuOpen]);

  // TODO: Put this somewhere else make more sense.
  useScrollToTop();

  return (
    <>
      {!isMenuOpen && (
        <CommonHeader toggleMenu={toggleMenu} navigate={navigate} />
      )}
      {isMenuOpen && <MobileMenu toggleMenu={toggleMenu} navigate={navigate} />}
    </>
  );
}
