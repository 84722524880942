import Button from '@components/Button';
import { openWhatsapp } from '@src/utils/open-whatsapp';
import { useTranslation } from 'react-i18next';

// TODO: Check css

export default function TrainingSection() {
  const { t } = useTranslation('become-a-listener');

  return (
    <section className="relative bg-white md:py-[40px] md:px-[76px] mb-[84px] overflow-hidden">
      <div className="md:hidden text-center mt-[40px] mb-[24px] font-extrabold text-5xl leading-[48px]">
        <h2>{t`training.title`}</h2>
      </div>

      <div className="md:hidden my-[24px] flex justify-center">
        <img
          src="media/become-a-listener/training-background.webp"
          className="w-[360px] h-[360px]"
          alt="A close up image of a computer, and hand holding a pencil, symbolizing online learning and training."
        />
      </div>

      <div className="md:w-[60%] md:py-[64px] md:pr-[75px]">
        <div className="hidden md:block md:mb-[24px] font-extrabold md:text-[56px] md:leading-[65px]">
          <h2>{t`training.title`}</h2>
        </div>

        <div className="px-[28px] md:p-0">
          <p className="text-[15px] md:text-lg">{t`training.description`}</p>
        </div>

        <div className="mt-[24px] md:mt-[40px] mb-[20px] md:mb-0 text-center md:text-left">
          <Button
            label={t`training.cta`}
            className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
            onClick={openWhatsapp}
          />
        </div>
      </div>

      {/* TODO: Fix this position later */}
      <div className="hidden md:block md:absolute md:top-[calc(50%+20px)] md:right-0 md:transform md:-translate-y-1/2">
        <img
          src="media/become-a-listener/training-background.webp"
          alt="A close up image of a computer, and hand holding a pencil, symbolizing online learning and training."
        />
      </div>
    </section>
  );
}
