import z from 'zod';

export const getQuestionsInputSchema = z.string().optional();
export type GetQuestionsInput = z.infer<typeof getQuestionsInputSchema>;

export const QuizQuestionSchema = z.object({
    order: z.number(),
    content: z.string(),
});
export type QuizQuestion = z.infer<typeof QuizQuestionSchema>;

export const QuizQuestionsSchema = QuizQuestionSchema.array();
export type QuizQuestions = z.infer<typeof QuizQuestionsSchema>;

export const QuizQuestionAnswerOptionSchema = z.object({
    label: z.string(),
    score: z.number().int(),
});
export type QuizQuestionAnswerOption = z.infer<typeof QuizQuestionAnswerOptionSchema>;

export const QuizQuestionAnswerOptionsSchema = z.array(QuizQuestionAnswerOptionSchema);
export type QuizQuestionAnswerOptions = z.infer<typeof QuizQuestionAnswerOptionsSchema>;

export const quizQuestionAnswerOptions: QuizQuestionAnswerOptions = [
    {
        score: 1,
        label: 'None of the time',
    },
    {
        score: 2,
        label: 'A little of the time',
    },
    {
        score: 3,
        label: 'Some of the time',
    },
    {
        score: 4,
        label: 'Most of the time',
    },
    {
        score: 5,
        label: 'All of the time',
    },
];

export const quizQuestionAnswerOptionsVN: QuizQuestionAnswerOptions = [
    {
        score: 1,
        label: 'Không lần nào',
    },
    {
        score: 2,
        label: 'Thỉnh thoảng',
    },
    {
        score: 3,
        label: 'Đôi khi',
    },
    {
        score: 4,
        label: 'Thường xuyên',
    },
    {
        score: 5,
        label: 'Luôn luôn',
    },
];

export const QuizSchema = z.object({
    questions: QuizQuestionsSchema,
    options: QuizQuestionAnswerOptionsSchema,
});
export type Quiz = z.infer<typeof QuizSchema>;

export const QuizAnswerSchema = z.object({
    questionId: z.number(),
    response: z.number(),
});
export type QuizAnswer = z.infer<typeof QuizAnswerSchema>;

export const QuizAnswersSchema = z.object({
    answers: z.array(QuizAnswerSchema),
    language: z.string().optional(),
});
export type QuizAnswers = z.infer<typeof QuizAnswersSchema>;

export const QuizAnalyzeResponseSchema = z.object({
    severity: z.number(),
    title: z.string(),
    message: z.string(),
});
export type QuizAnalyzeResponse = z.infer<typeof QuizAnalyzeResponseSchema>;
