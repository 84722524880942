import Accordion from '@components/Accordion';
import headingMobile from '@assets/footages/crisis-helpline/heading-mobile.svg';
import content from './content.json';
import './styles.css';
import NormalObject from '@components/NormalObject';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function CrisisHelpline() {
  const { t } = useTranslation('crisis-helpline');

  return (
    <>
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>HeyJuni - Crisis Helpline</title>
        <meta
          name="description"
          content="Find crisis helpline numbers worldwide in alphabetical order. Access essential resources for immediate help."
        />
        <meta
          name="keywords"
          content="crisis helpline, emergency contacts, mental health support, worldwide helpline"
        />
        <meta property="og:title" content="Crisis Helpline - HeyJuni" />
        <meta
          property="og:description"
          content="Explore a comprehensive list of crisis helplines organized by country and alphabetical order."
        />
      </Helmet>
      <div className="relative pt-[58px] md:pt-[90px] px-[28px] md:px-[76px] pb-[90px]">
        <div
          className="hidden md:block absolute left-0 top-0"
          style={{ zIndex: -1 }}
        >
          <img
            src="media/crisis-helpline/crisis-helpline-heading.webp"
            alt="heading-background"
            className="object-cover"
          />
        </div>

        <div
          className="md:hidden absolute left-0 top-0 w-full transform -translate-y-[62px]"
          style={{ zIndex: -1 }}
        >
          <NormalObject data={headingMobile} className="w-full object-cover" />
        </div>

        <div className="text-6xl md:text-8xl font-extrabold leading-[58px] md:leading-[85px]">
          {t`title`}
        </div>

        <div className="mt-[80px] md:mt-[144px] text-[15px] md:text-lg">
          <div className="mb-[36px] md:mb-[46px] font-bold md:font-extrabold text-xl md:text-5xl md:leading-[45px]">
            {t`description`}
          </div>
          <Accordion items={items} />
        </div>
      </div>
    </>
  );
}

const characters = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

const items = characters.map((char) => ({
  title: char.toUpperCase(), // Convert to uppercase for the title
  content: <EmergencyData dataKey={char} content={content} />,
}));

interface EmergencyDataProps {
  dataKey: string; // The key like "a", "b", etc.
  content: Record<string, any>;
}

function EmergencyData({ dataKey, content }: EmergencyDataProps) {
  const data = content[dataKey];

  if (!data) {
    return <p>...</p>;
  }

  return (
    <div className="space-y-[40px] md:space-y-[60px] mb-[20px] md:mb-[30px]">
      {Object.entries(data).map(([country, items]) => (
        <div key={country}>
          <div className="country-name">{country}</div>
          <div className="space-y-[20px]">
            {(items as string[]).map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
