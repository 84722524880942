import Button from '@components/Button';
import React, { useState } from 'react';
import { trpc } from '@src/App';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';

export function SubscribeSection() {
  const { t, i18n } = useTranslation('subscribe');
  const [email, setEmail] = React.useState('');
  const [subscribed, setSubscribed] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const subscriptionMutation =
    trpc.subscription.createSubscription.useMutation();

  const subscribe = async () => {
    if (!email) return; // Check if the email is not empty
    const emailSchema = z.string().email();
    const parseResult = emailSchema.safeParse(email);
    if (!parseResult.success) {
      // Check if the email is valid
      alert('Invalid email address');
      return;
    }

    try {
      setIsLoading(true);
      await subscriptionMutation.mutateAsync({
        email,
        locale: i18n.language,
      });
      setSubscribed(true);
    } catch (error) {
      console.error('Subscription failed:', error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className="bg-[#151515] text-white px-[28px] pt-[40px] md:pt-[93px] text-5xl font-black md:px-24 md:px-[76px] md:text-7xl leading-[48px] md:leading-[65px]">
        <div className="hidden md:block max-w-[640px]">
          <h2>{t`title`}</h2>
        </div>
        <div className="block md:hidden">
          <h2>{t`title`}</h2>
        </div>
      </div>

      <div className="bg-[#151515] px-[28px] md:px-[79px] pt-[50px] md:pt-[115px] pb-[40px] md:pb-[106px]">
        <div className="w-full md:ml-auto md:mr-0 max-w-[400px]">
          {subscribed ? (
            <div className="text-white text-center text-3xl my-20 transition-opacity delay-1000">
              {t`cta.success`}
            </div>
          ) : (
            <div className="relative transition-opacity delay-1000">
              <div className="hidden md:block absolute left-0 top-0 transform -translate-y-full z-100">
                <video
                  src="media/subscribe-arrow.mp4"
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-[160px] md:pb-8"
                />
              </div>

              <video
                src="media/subscribe-arrow.mp4"
                autoPlay
                loop
                muted
                playsInline
                className="md:hidden w-[160px] pb-8"
              />

              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-[#151515] text-white text-sm md:text-lg border-b-2 border-white focus:outline-none focus:border-b-2 focus:border-blue-500 w-full pb-2"
                placeholder={t`placeholder`}
              />
              <button
                className="bg-[#FFE816] hover:bg-[#977CFF] active:bg-[#D8CFFC] text-black hover:text-white active:text-black px-4 py-2 rounded-[24px] w-full mt-10 h-12 font-semibold text-lg"
                onClick={subscribe}
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : t`cta.button`}
              </button>
              <p className="text-white opacity-50 mt-[10px] text-sm font-regular">
                {t`cta.description`}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// TODO: Instead of loading..., use better one
