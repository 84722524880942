import Button from '@components/Button';

import becomeSupporterImg01 from '@assets/footages/become-a-listener/become-supporter-step-01.svg';
import becomeSupporterImg02 from '@assets/footages/become-a-listener/become-supporter-step-02.svg';
import becomeSupporterImg03 from '@assets/footages/become-a-listener/become-supporter-step-03.svg';
import becomeSupporterImg04 from '@assets/footages/become-a-listener/become-supporter-step-04.svg';
import { MobileSwipe } from '@components/MobileSwipe';
import { useTranslation } from 'react-i18next';
import { openGoogleForm } from '@src/utils/open-googleform';

type Step = {
  id: number;
  img: string;
  title: string;
  description: string;
  alt: string;
};
type Steps = Step[];

function MobileCards({ steps }: { steps: Steps }) {
  return (
    <>
      {steps.map((step) => (
        <div
          key={step.id}
          className="min-w-full flex flex-col items-center justify-center"
        >
          <img src={step.img} alt={step.alt} />
          <p className="font-extrabold text-5xl md:leading-[48px]">{step.id}</p>
          <p className="font-extrabold text-[28px] md:leading-[36px]">
            {step.title}
          </p>
          <p
            className="px-[40px] text-lg text-center"
            dangerouslySetInnerHTML={{ __html: step.description }}
          ></p>
        </div>
      ))}
    </>
  );
}

export function BecomeSupporterSection() {
  const { t } = useTranslation('become-a-listener');
  const steps = [
    {
      id: 1,
      img: becomeSupporterImg01,
      title: t`become-supporter.steps.step-1.title`,
      description: t`become-supporter.steps.step-1.description`,
      alt: 'A purple emojis face infront of a form, symbolizing filling out a form',
    },
    {
      id: 2,
      img: becomeSupporterImg02,
      title: t`become-supporter.steps.step-2.title`,
      description: t`become-supporter.steps.step-2.description`,
      alt: 'A purple emojis wearing headphones and talking, symbolizing a conversation or customer service.',
    },
    {
      id: 3,
      img: becomeSupporterImg03,
      title: t`become-supporter.steps.step-3.title`,
      description: t`become-supporter.steps.step-3.description`,
      alt: 'A purple emoji smiling with a book, symbolizing the happiness about learning. ',
    },
    {
      id: 4,
      img: becomeSupporterImg04,
      title: t`become-supporter.steps.step-4.title`,
      description: t`become-supporter.steps.step-4.description`,
      alt: 'A smiling purple emoji with a check mark, symbolizing success and a fresh start of something exciting.',
    },
  ];

  return (
    <div className="mb-[84px] md:mb-0 md:py-[64px]">
      <div className="hidden md:block text-center font-extrabold py-0 text-7xl leading-[65px]">
        <h2
          dangerouslySetInnerHTML={{ __html: t`become-supporter.title` }}
        ></h2>
      </div>
      <div className="md:hidden py-[20px] text-center font-extrabold text-5xl leading-[48px]">
        <h2
          dangerouslySetInnerHTML={{ __html: t`become-supporter.title-mobile` }}
        ></h2>
      </div>

      <div className="hidden md:flex flex-wrap md:py-[18px]">
        {steps.map((step) => (
          <div
            key={step.id}
            className="w-1/4 flex flex-col items-center gap-[16px]"
          >
            <img src={step.img} alt={step.alt} />
            <p className="font-extrabold md:text-5xl md:leading-[48px]">
              {step.id}
            </p>
            <p className="font-extrabold md:text-[28px] md:leading-[36px]">
              {step.title}
            </p>
            <p
              className="text-lg text-center md:max-w-[220px]"
              dangerouslySetInnerHTML={{ __html: step.description }}
            ></p>
          </div>
        ))}
      </div>

      <MobileSwipe dataList={steps}>
        <MobileCards steps={steps} />
      </MobileSwipe>

      <div className="flex flex-col items-center mt-[40px] md:mb-[54px]">
        <Button
          label={t`become-supporter.cta`}
          className="h-[50px] md:h-[60px] px-[30px] rounded-[20px] font-semibold text-lg md:text-2xl"
          onClick={openGoogleForm}
        />
      </div>
    </div>
  );
}
