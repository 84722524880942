import React, { useState } from 'react';
import { AgeGroup, Gender } from '@source/types';
import Option from './components/Option';
import { DropdownField } from './components/DropdownField';
import { useBookingContext } from './BookingContext';
import { useTranslation } from 'react-i18next';

// TODO: Refactor

const options01 = [
  AgeGroup['16_19'],
  AgeGroup['20_24'],
  AgeGroup['25_29'],
  AgeGroup['30_34'],
  AgeGroup['35_39'],
  AgeGroup['40_45'],
  AgeGroup['ABOVE_45'],
];

const options02 = [
  Gender.MALE,
  Gender.FEMALE,
  Gender.NON_BINARY,
  Gender.PREFER_NOT_TO_SAY,
  Gender.OTHER,
];

export function Step08() {
  const { t } = useTranslation('booking');
  const { age, setAge, gender, setGender } = useBookingContext();

  const optionLabels01 = {
    '16_19': '16-19',
    '20_24': '20-24',
    '25_29': '25-29',
    '30_34': '30-34',
    '35_39': '35-39',
    '40_45': '40-45',
    ABOVE_45: t`questions.question-8.options-1.above`,
  };

  const optionLabels02 = {
    MALE: t`questions.question-8.options-2.male`,
    FEMALE: t`questions.question-8.options-2.female`,
    NON_BINARY: t`questions.question-8.options-2.non-binary`,
    PREFER_NOT_TO_SAY: t`questions.question-8.options-2.prefer-not-to-say`,
    OTHER: t`questions.question-8.options-2.other`,
  };

  return (
    <div className="text-center w-full">
      <h1 className="text-base md:text-3xl font-semibold mb-6 md:mb-[15px]">
        {t`questions.question-8.title-1`}
      </h1>
      <DropdownField
        options={options01}
        labels={optionLabels01}
        selectedOption={age}
        onChangeSelection={setAge}
      />

      <h1 className="text-base md:text-3xl font-semibold mb-6 md:mb-[15px]">
        {t`questions.question-8.title-2`}
      </h1>
      <DropdownField
        options={options02}
        labels={optionLabels02}
        selectedOption={gender}
        onChangeSelection={setGender}
      />
    </div>
  );
}
