import ChipRadio from '@components/ChipRadio';
import { useEffect, useState } from 'react';
import headingMobile from '@assets/footages/faq/heading-mobile.svg';
import GeneralFAQ from '@components/FAQs/GeneralFAQ';
import HelpSeekersFAQ from '@components/FAQs/HelpSeekersFAQ';
import ListenersFAQ from '@components/FAQs/ListenersFAQ';
import PrivacyFAQ from '@components/FAQs/PrivacyFAQ';
import { useSearchParams } from 'react-router-dom';
import NormalObject from '@components/NormalObject';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function FAQ() {
  const { t } = useTranslation('faq');
  const [searchParams] = useSearchParams();

  const categories = [
    t`categories.general.title`,
    t`categories.users.title`,
    t`categories.peer-counselor.title`,
    t`categories.privacy.title`,
  ];

  const selected = searchParams.get('selected');

  const [selectedOption, setSelectedOption] = useState<string>(
    selected ?? categories[0]
  );

  const renderFAQContent = () => {
    switch (selectedOption) {
      case categories[0]:
        return <GeneralFAQ />;
      case categories[1]:
        return <HelpSeekersFAQ />;
      case categories[2]:
        return <ListenersFAQ />;
      case categories[3]:
        return <PrivacyFAQ />;
      default:
        return <div>{t`categories.fallback`}</div>;
    }
  };

  useEffect(() => {
    setSelectedOption(selected ?? categories[0]);
  }, [selected]);

  return (
    <>
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>FAQs | Get Answers About Heyjuni, Counseling, Therapy</title>
        <meta
          name="description"
          content="What is HeyJuni? · Who will be helping me? · Who are the Peer Counselors / Listeners? · How are Supporters vetted? · How much does it cost? · Is HeyJuni right for me? • Why not Therapy?"
        />
        <meta
          name="keywords"
          content="HeyJuni FAQs, Frequently Asked Questions, Privacy, Peer Counselor FAQs, General FAQs, Help Seekers FAQs"
        />
        <meta property="og:title" content="FAQs - HeyJuni" />
        <meta
          property="og:description"
          content="Explore HeyJuni's FAQ section for information about services, privacy, peer counselors, and help seekers. Get all your questions answered."
        />
      </Helmet>
      <div className="relative">
        <div
          className="hidden md:block absolute top-0 left-0"
          style={{ zIndex: -1 }}
        >
          <img src="media/faq/faq-heading.webp" alt="heading background" />
        </div>

        <div
          className="md:hidden absolute top-0 left-0 transform -translate-y-[31px] w-full"
          style={{ zIndex: -1 }}
        >
          <NormalObject data={headingMobile} className="w-full object-cover" />
        </div>

        <div className="py-[64px] px-[28px] md:px-0 md:py-[96px] md:w-[58%] mx-auto">
          <div className="font-black text-6xl leading-[58px] md:text-8xl md:leading-[85px]">
            <h1>{t`title`}</h1>
          </div>
          <div className="mt-12 font-medium">{t`description`}</div>
          <div className="mt-[50px] md:mt-[114px]">
            <ChipRadio
              options={categories}
              selectedOption={selectedOption}
              pathname="/faq"
            />
          </div>
          <div className="mt-[65px] md:mt-[33px]">{renderFAQContent()}</div>
        </div>
      </div>
    </>
  );
}
// max-w-3xl
