import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { HealingSwipe, HealingSwipeMobile } from './components/HealingSwipe';
import { useTranslation } from 'react-i18next';

export function HealingSection() {
  const { t } = useTranslation('learn-more');
  const navigate = useNavigate();
  const cards = [
    {
      title: t`healing.cards.card-1.title`,
      description: t`healing.cards.card-1.content`,
      image: 'media/learn-more/healing01.webp',
      imageMobile: 'media/learn-more/healing01-mobile.webp',
      alt: `A black and white image of a woman's profile. She looks down, wrapping her arms around her shoulders. From the side, her face looks sentimental yet hopeful.`,
    },
    {
      title: t`healing.cards.card-2.title`,
      description: t`healing.cards.card-2.content`,
      image: 'media/learn-more/healing02.webp',
      imageMobile: 'media/learn-more/healing02-mobile.webp',
      alt: 'A black and white image of a woman putting both hands on her face. She looks like she is crying and in desperation. This symbolizes the sadness, loneliness and frustrations that this woman is facing.',
    },
    {
      title: t`healing.cards.card-3.title`,
      description: t`healing.cards.card-3.content`,
      image: 'media/learn-more/healing03.webp',
      imageMobile: 'media/learn-more/healing03-mobile.webp',
      alt: 'Image of a young women holding a mobile phone in front of her face. This symbolizes communication, conversations and sharing. Talking and expressing feelings emotions is the first step toward healing.',
    },
    {
      title: t`healing.cards.card-4.title`,
      description: t`healing.cards.card-4.content`,
      image: 'media/learn-more/healing04.webp',
      imageMobile: 'media/learn-more/healing04-mobile.webp',
      alt: `Image of a young women covering her face with one hand. She looks sad but it seems like she's also ready to talk about the struggles she is facing.`,
    },
    {
      title: t`healing.cards.card-5.title`,
      description: t`healing.cards.card-5.content`,
      image: 'media/learn-more/healing05.webp',
      imageMobile: 'media/learn-more/healing05-mobile.webp',
      alt: 'A profile image of a young woman looking reflective, gazing inward as she embraces her journey toward inner strength and resilience.',
    },
  ];

  return (
    <section className="relative bg-white md:py-[64px] mb-[94px] md:mb-[40px]">
      <h2
        className="hidden md:block text-center font-extrabold text-7xl leading-[65px] mb-[28px]"
        dangerouslySetInnerHTML={{ __html: t`healing.title` }}
      ></h2>
      <h2
        className="md:hidden text-center font-extrabold text-5xl  leading-[48px] mb-[10px]"
        dangerouslySetInnerHTML={{ __html: t`healing.title-mobile` }}
      ></h2>
      <HealingSwipe cards={cards} />
      <HealingSwipeMobile cards={cards} />
      <div className="hidden md:block">
        <div className="text-center font-bold mt-[28px] mb-[16px] text-4xl">
          {t`healing.cta.title`}
        </div>
      </div>
      <div className="block md:hidden">
        <div
          className="text-center mt-[28px] mb-[8px] font-bold text-xl"
          dangerouslySetInnerHTML={{ __html: t`healing.cta.title-mobile` }}
        ></div>
      </div>
      <div className="text-center">
        <Button
          label={t`healing.cta.button`}
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={() => navigate('/booking')}
        />
      </div>
    </section>
  );
}
