import pricingBackgroundMobile from '@assets/footages/pricing/pricing-bg-mobile.svg';
import Card from './Card';
import { useNavigate } from 'react-router-dom';

import PlanBg01 from '@assets/footages/pricing/pay_as_you_go.svg';

import PlanBg01Mobile from '@assets/footages/pricing/pay_as_you_go-mobile.svg';

import './index.css';
import { openWhatsapp } from '@src/utils/open-whatsapp';
import NormalObject from '@components/NormalObject';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function Pricing() {
  const { t } = useTranslation('pricing');
  const navigate = useNavigate();

  const cardData = [
    {
      title: t`plans.pay-as-you-go.title`,
      src: PlanBg01,
      srcMobile: PlanBg01Mobile,
      price: t`plans.pay-as-you-go.price`,
      oldPrice: t`plans.pay-as-you-go.old-price`,
      priceFrequency: (
        <div className="max-w-[70px]">{t`plans.pay-as-you-go.note`}</div>
      ),
      description: t`plans.pay-as-you-go.description`,
      features: [
        t`plans.pay-as-you-go.features.247`,
        t`plans.pay-as-you-go.features.instant`,
        t`plans.pay-as-you-go.features.duration`,
        t`plans.pay-as-you-go.features.support`,
      ],
      buttonText: t`plans.pay-as-you-go.cta`,
    },
  ];

  return (
    <div className="relative">
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>How Much Does A Session With a Peer Counselor Cost?</title>
        <meta
          name="description"
          content="HeyJuni believes in fast yet affordable and accessible mental healthcare for everyone's wellbeing and overall health. Book a counseling session within 15 minutes for SG $29.00, anytime, anywhere."
        />
        <meta
          name="keywords"
          content="HeyJuni Pricing, Peer Support, Mental Health Plans, Pay As You Go, Monthly Plan, Unlimited Plan"
        />
        <meta property="og:title" content="Pricing Plans - HeyJuni" />
        <meta
          property="og:description"
          content="Discover HeyJuni's flexible pricing options for mental health support. Choose the plan that fits your needs and get started today."
        />
      </Helmet>

      <div
        className="hidden md:block absolute top-0 right-0"
        style={{ zIndex: -1 }}
      >
        <img
          src="media/pricing/pricing-heading.webp"
          alt="A hand typing on the computer keyboard symbolizing work and collaboration."
        />
      </div>

      <div
        className="md:hidden absolute top-0 right-0 w-full"
        style={{ zIndex: -1 }}
      >
        <NormalObject
          data={pricingBackgroundMobile}
          className="w-full h-full object-cover"
        />
      </div>

      <div className="px-[40px] md:px-[76px]">
        <div className="pt-[58px] md:pt-[88px] pb-[20px] md:pb-[40px] font-extrabold text-6xl leading-[58px] md:text-8xl md:leading-[85px] md:max-w-[916px]">
          <h1>{t`title`}</h1>
        </div>
        <div className="mb-[64px] md:mb-[48px] text-lg md:text-2xl md:leading-[32px] md:max-w-[870px]">
          <p>{t`description-1`}</p>
          <br />
          <p>{t`description-2`}</p>
        </div>
      </div>

      <div className="md:py-[20px] px-[30px] md:px-[60px] flex justify-center">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            src={card.src}
            srcMobile={card.srcMobile}
            oldPrice={card.oldPrice}
            price={card.price}
            priceFrequency={card.priceFrequency}
            description={card.description}
            features={card.features}
            buttonText={card.buttonText}
            onClick={() => {
              if (index === 0) {
                navigate('/booking');
              } else {
                openWhatsapp();
              }
            }}
          />
        ))}
      </div>

      <div className="pt-[20px] md:pt-[12px] pb-[40px] md:pb-[64px] px-[34px] md:px-[76px] text-[11px] md:text-[12px] leading-[16px] md:leading-[20px] text-[#00000080]">
        <span className="text-red-500">*</span>
        {t`note`}
      </div>
    </div>
  );
}
