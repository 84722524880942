import { z } from 'zod';

export const SubscriptionSchema = z.object({
    id: z.number(),
    email: z.string(),
    subscribedAt: z.date(),
    unsubscribeKey: z.string(),
    unsubscribedAt: z.date().nullable(),
    locale: z.string(),
});
export type Subscription = z.infer<typeof SubscriptionSchema>;

export const SubscriptionsSchema = z.array(SubscriptionSchema);
export type Subscriptions = z.infer<typeof SubscriptionsSchema>;

export const CreateSubscriptionSchema = z.object({
    email: z.string().email(),
    locale: z.string().optional().default('en'),
});
export type CreateSubscription = z.infer<typeof CreateSubscriptionSchema>;

export const UnsubscribeInputSchema = z.object({
    unsubscribeKey: z.string(),
    unsubscribeReason: z.string().optional(),
});
export type UnsubscribeInput = z.infer<typeof UnsubscribeInputSchema>;
