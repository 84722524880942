import { WomanSupportSection } from './woman-support-section';
import { SupportersDoSection } from './supporters-do-section';
import { BecomeSupporterSection } from './become-supporter-section';
import TrainingSection from './training-section';
import { WhyBecomeSupportersSection } from './why-become-supporters-section';
import FaqSection from './faq-section';
import JoinUsSection from './join-us-section';
import { MeetSupportersSection } from './meet-some-supporters-section';
import Button from '@components/Button';
import { openGoogleForm } from '@src/utils/open-googleform';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function BecomeAListener() {
  const { t } = useTranslation('become-a-listener');

  return (
    <>
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>Online Mental Support with Peer Counseling.</title>
        <meta
          name="description"
          content="Become a peer counselor with HeyJuni. Empower others, make lasting impact, and build a meaningful career supporting mental health & well-being."
        />
        <meta
          name="keywords"
          content="HeyJuni, Peer Counselor, Mental Health, Empowerment, Career in Mental Health"
        />
        <meta property="og:title" content="Become a Peer Counselor - HeyJuni" />
        <meta
          property="og:description"
          content="Step into a role where your empathy and life experiences become powerful tools for healing. Become a certified peer Counselor with HeyJuni."
        />
      </Helmet>

      <div className="relative px-[28px] md:px-[76px] pt-[46px] md:pt-[65px] w-full">
        <div
          className="hidden md:block absolute top-0 right-0"
          style={{ zIndex: -1 }}
        >
          <img
            src="media/become-a-listener/become-a-listener-heading.webp"
            alt="Become A Listener Heading"
          />
        </div>

        <div
          className="md:hidden absolute top-0 right-0 w-full"
          style={{ zIndex: -1 }}
        >
          <img
            src="media/become-a-listener/become-a-listener-heading-mobile.webp"
            alt="Become A Listener Heading"
            className="w-full"
          />
        </div>

        <div className="">
          <div className="text-[38px] md:text-8xl leading-[48px] md:leading-[75px] font-black md:max-w-[828px] max-w-[398px]">
            <h1>{t`hero.title`}</h1>
          </div>
          <div className="md:text-2xl md:leading-[32px] mt-[20px] md:mt-[40px] md:w-2/3 pr-[45px] md:pr-0">
            {t`hero.description`}
          </div>
          <Button
            label={t`hero.cta`}
            className="mt-[64px] mb-[52px] md:mt-[40px] md:mb-[64px] rounded-[20px] px-[30px] h-[50px] md:h-[60px] font-semibold text-lg md:text-2xl"
            onClick={openGoogleForm}
          />
        </div>
      </div>
      <WomanSupportSection />
      <SupportersDoSection />
      <MeetSupportersSection handleAction={openGoogleForm} />
      <BecomeSupporterSection />
      <TrainingSection />
      <WhyBecomeSupportersSection />
      <FaqSection />
      <JoinUsSection />
    </>
  );
}
