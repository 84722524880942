import { useEffect, useState } from 'react';

export function ComingBanner() {
  const [translateX, setTranslateX] = useState(0);
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateX((prev) => {
        if (prev <= -50) {
          setIsResetting(true); // Disable transition during reset
          return 0;
        }
        setIsResetting(false); // Enable transition for regular movement
        return prev - 0.5;
      });
    }, 300); // 200ms for smooth movement
    return () => clearInterval(interval);
  }, []);

  const content = 'COMING TO VIETNAM. '.repeat(20);

  return (
    <div className="absolute left-0 top-0 w-full overflow-hidden bg-[#181818] h-6 md:h-8 flex items-center">
      <div
        style={{
          transform: `translateX(${translateX}%)`,
          transition: isResetting ? 'none' : 'transform 0.3s linear', // No transition when resetting
          whiteSpace: 'nowrap',
        }}
        className="text-white text-[11px] md:text-base"
      >
        {content}
      </div>
    </div>
  );
}
