import Button from '@components/Button';
import { SupporterSwipe } from './components/SupporterSwipe';
import { useTranslation } from 'react-i18next';

interface MeetSupportersSectionProps {
  handleAction: () => void;
}

export function MeetSupportersSection(props: MeetSupportersSectionProps) {
  const { t } = useTranslation('become-a-listener');
  const { handleAction } = props;

  return (
    <section className="relative bg-white mb-[84px] md:mb-0 md:pt-[65px] md:pb-[40px]">
      <div className="hidden md:block">
        <div className="text-center font-extrabold text-7xl leading-[65px] mt-0 mb-[20px]">
          <h2>{t`meet-supporters.title`}</h2>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="text-center font-extrabold text-5xl leading-[48px] mt-[30px] mb-[20px]">
          <h2
            dangerouslySetInnerHTML={{
              __html: t`meet-supporters.title-mobile`,
            }}
          ></h2>
        </div>
      </div>
      <SupporterSwipe />
      <div className="text-center mt-[20px] md:mt-[28px] md:mb-[26px]">
        <Button
          label={t`meet-supporters.cta`}
          className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
          onClick={handleAction}
        />
      </div>
    </section>
  );
}
