import Button from '@components/Button';
import { openGoogleForm } from '@src/utils/open-googleform';
import { useTranslation } from 'react-i18next';

export function SupportersDoSection() {
  const { t } = useTranslation('become-a-listener');

  const cardData = [
    {
      src: 'media/become-a-listener/supporters-do-bg-01.webp',
      title: t`supporters-do.cards.card-1.title`,
      description: t`supporters-do.cards.card-1.description`,
    },
    {
      src: 'media/become-a-listener/supporters-do-bg-02.webp',
      title: t`supporters-do.cards.card-2.title`,
      description: t`supporters-do.cards.card-2.description`,
    },
    {
      src: 'media/become-a-listener/supporters-do-bg-03.webp',
      title: t`supporters-do.cards.card-3.title`,
      description: t`supporters-do.cards.card-3.description`,
    },
    {
      src: 'media/become-a-listener/supporters-do-bg-04.webp',
      title: t`supporters-do.cards.card-4.title`,
      description: t`supporters-do.cards.card-4.description`,
    },
    {
      src: 'media/become-a-listener/supporters-do-bg-02.webp',
      title: t`supporters-do.cards.card-5.title`,
      description: t`supporters-do.cards.card-5.description`,
    },
    {
      src: 'media/become-a-listener/supporters-do-bg-01.webp',
      title: t`supporters-do.cards.card-6.title`,
      description: t`supporters-do.cards.card-6.description`,
    },
  ];

  return (
    <div className="mb-[84px] md:mb-0 md:px-[76px] md:py-[40px] text-center">
      <div className="hidden md:block text-center mb-[28px] font-extrabold text-7xl leading-[65px]">
        <h2>{t`supporters-do.title`}</h2>
      </div>
      <div className="md:hidden mb-[20px] text-center px-[28px] font-extrabold text-5xl leading-[48px]">
        <h2>{t`supporters-do.title`}</h2>
      </div>

      <div className="px-[28px] md:p-0 grid grid-cols-1 md:grid-cols-2 gap-[20px] md:gap-[40px]">
        {cardData.map((data, index) => (
          <Card
            key={index}
            src={data.src}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>

      <Button
        label={t`supporters-do.cta`}
        className="mt-[20px] md:mt-[40px] h-[50px] md:h-[60px] px-[30px] font-semibold text-lg md:text-2xl rounded-[20px]"
        onClick={openGoogleForm}
      />
    </div>
  );
}

interface CardProps {
  src: string;
  title: string;
  description: string;
}

function Card(props: CardProps) {
  const { src, title, description } = props;
  return (
    <div
      className={`relative px-[28px] py-[20px] md:p-[32px] min-h-[160px] md:min-h-[264px] overflow-hidden text-left`}
    >
      <div
        className={`absolute top-0 left-0 w-full h-full`}
        style={{ zIndex: -1 }}
      >
        <img src={src} alt={title} className="w-full h-full object-cover" />
      </div>
      <h3 className="mb-[20px] md:mb-[32px] font-bold text-xl md:text-4xl md:leading-[40px]">
        {title}
      </h3>
      <p className="text-[15px] md:text-2xl leading-[22px] md:leading-[32px]">
        {description}
      </p>
    </div>
  );
}
