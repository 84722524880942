import { useState, useEffect } from 'react';
import icon from '@assets/footages/booking/booking-confirmed.svg';
import { useLocation } from 'react-router-dom';
import { trpc } from '@src/App';
import NormalObject from '@components/NormalObject';
import { useTranslation } from 'react-i18next';

export function Step13() {
  const { t, i18n } = useTranslation('booking');
  const location = useLocation();

  const [bookingDate, setBookingDate] = useState<string | null>(null);
  const [bookingTime, setBookingTime] = useState<string | null>(null);

  const confirmBookingMutation =
    trpc.bookingRequest.confirmBookingRequest.useMutation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let datetime = queryParams.get('datetime');

    if (datetime) {
      // Use decodeURIComponent to preserve any special characters like '+'
      datetime = decodeURIComponent(datetime);
      const localDate = new Date(datetime);

      const bookingRequestId = queryParams.get('bookingRequestId');
      bookingRequestId &&
        confirmBookingMutation.mutateAsync({
          bookingRequestId: Number(bookingRequestId),
        });

      if (!isNaN(localDate.getTime())) {
        const bookingDate_ = localDate.toLocaleDateString(
          i18n.language === 'vi' ? 'vi' : 'en-us',
          {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }
        );
        setBookingDate(bookingDate_);
        console.log(`localDate: ${localDate}`);
        console.log(`bookingDate_: ${bookingDate_}`);
        setBookingTime(
          localDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })
        );
      }
    }
  }, [location.search]);

  return (
    <>
      <div className="mx-auto mb-[16px] md:mb-[10px]">
        <NormalObject
          data={icon}
          className="w-[60px] h-[60px] md:w-[88px] md:h-[88px]"
        />
      </div>

      <div className="text-center mb-[24px]">
        <div className="font-extrabold text-4xl md:font-bold md:text-4xl leading-[36px] md:leading-[40px]">
          {t`questions.question-13.title-1`}
          <br />
          {t`questions.question-13.title-2`}
        </div>
        <div className="font-semibold text-base md:text-3xl md:leading-[30px] mt-[20px]">
          {t`questions.question-13.description-1`}{' '}
          <span>{bookingDate || '[DATE]'}</span>{' '}
          {t`questions.question-13.description-2`}{' '}
          <span>{bookingTime || '[TIME]'}</span>
        </div>
      </div>

      <div className="font-regular text-base md:text-lg">
        <div className="mb-6">
          <p>{t`questions.question-13.p-1`}</p>
          <ul className="list-disc list-inside">
            <li>{t`questions.question-13.p-2`}</li>
            <li>{t`questions.question-13.p-3`}</li>
          </ul>
        </div>

        <div className="mb-6">
          <p>
            {t`questions.question-13.p-4`}
            <br />
            {t`questions.question-13.p-5`}{' '}
            <a href="mailto:contact@heyjuni.com" className="underline">
              contact@heyjuni.com
            </a>
            .
          </p>
        </div>

        <p>{t`questions.question-13.p-6`}</p>
      </div>
    </>
  );
}
