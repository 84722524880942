import Accordion from '@components/Accordion';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PrivacyFAQ() {
  const { t } = useTranslation('faq');
  const items = [
    {
      title: t`categories.privacy.items.item-1.title`,
      content: (
        <>
          <p>{t`categories.privacy.items.item-1.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.privacy.items.item-2.title`,
      content: (
        <>
          <p>{t`categories.privacy.items.item-2.p1`}</p>
          <br />
          <p className="text-2xl font-bold">
            {t`categories.privacy.items.item-2.p2`}
          </p>
          <ol className="list-decimal list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">
                {t`categories.privacy.items.item-2.p3`}
              </span>{' '}
              {t`categories.privacy.items.item-2.p4`}
            </li>
            <li className="mb-4">
              <span className="font-bold">
                {t`categories.privacy.items.item-2.p5`}
              </span>{' '}
              {t`categories.privacy.items.item-2.p6`}
            </li>
            <li className="mb-4">
              <span className="font-bold">
                {t`categories.privacy.items.item-2.p7`}
              </span>{' '}
              {t`categories.privacy.items.item-2.p8`}
            </li>
            <li className="mb-4">
              <span className="font-bold">
                {t`categories.privacy.items.item-2.p9`}
              </span>{' '}
              {t`categories.privacy.items.item-2.p10`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`categories.privacy.items.item-3.title`,
      content: (
        <>
          <p>{t`categories.privacy.items.item-3.p1`}</p>
          <br />
          <p>
            {t`categories.privacy.items.item-3.p2`}{' '}
            <a href="mailto:anna@heyjuni.com" className="text-blue-800">
              anna@heyjuni.com
            </a>
          </p>
          <br />
          <p>{t`categories.privacy.items.item-3.p3`}</p>
        </>
      ),
    },
  ];

  return (
    <>
      <p className="text-5xl font-extrabold mb-10">
        {t`categories.privacy.title`}
      </p>
      <Accordion items={items} />
    </>
  );
}
