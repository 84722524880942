import Accordion from '@components/Accordion';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function GeneralFAQ() {
  const { t } = useTranslation('faq');

  const items = [
    {
      title: t`categories.general.items.item-1.title`,
      content: <>{t`categories.general.items.item-1.p1`}</>,
    },
    {
      title: t`categories.general.items.item-2.title`,
      content: (
        <>
          <p>{t`categories.general.items.item-2.p1`}</p>
          <ol className="list-disc list-inside inline-block">
            <li>{t`categories.general.items.item-2.p2`}</li>
            <li>{t`categories.general.items.item-2.p3`}</li>
            <li>{t`categories.general.items.item-2.p4`}</li>
            <li>{t`categories.general.items.item-2.p5`}</li>
            <li>{t`categories.general.items.item-2.p6`}</li>
            <li>{t`categories.general.items.item-2.p7`}</li>
            <li>{t`categories.general.items.item-2.p8`}</li>
            <li>{t`categories.general.items.item-2.p9`}</li>
            <li>{t`categories.general.items.item-2.p10`}</li>
            <li>{t`categories.general.items.item-2.p11`}</li>
            <li>{t`categories.general.items.item-2.p12`}</li>
            <li>{t`categories.general.items.item-2.p13`}</li>
          </ol>
          <p className="font-bold mt-4">{t`categories.general.items.item-2.p14`}</p>
          <ol className="list-decimal list-inside inline-block">
            <li className="mb-8">
              <span className="font-semibold">
                {t`categories.general.items.item-2.p15`}
              </span>
              <ol className="list-disc list-inside inline-block">
                <li>{t`categories.general.items.item-2.p16`}</li>
                <li>{t`categories.general.items.item-2.p17`}</li>
              </ol>
            </li>
            <li className="mb-8">
              <span className="font-semibold">
                {t`categories.general.items.item-2.p18`}
              </span>
              <ol className="list-disc list-inside inline-block">
                <li>{t`categories.general.items.item-2.p19`}</li>
                <li>{t`categories.general.items.item-2.p20`}</li>
                <li>{t`categories.general.items.item-2.p21`}</li>
              </ol>
            </li>
            <li className="mb-8">
              <span className="font-semibold">
                {t`categories.general.items.item-2.p22`}
              </span>
              <ol className="list-disc list-inside inline-block">
                <li>{t`categories.general.items.item-2.p23`}</li>
                <li>{t`categories.general.items.item-2.p24`}</li>
              </ol>
            </li>
          </ol>
          <p>{t`categories.general.items.item-2.p25`}</p>
        </>
      ),
    },
    {
      title: t`categories.general.items.item-3.title`,
      content: (
        <>
          <p>{t`categories.general.items.item-3.p1`}</p>
          <br />
          <p className="font-bold mt-4">{t`categories.general.items.item-3.p2`}</p>
          <p>{t`categories.general.items.item-3.p3`}</p>
          <br />
          <p className="font-bold mt-4">{t`categories.general.items.item-3.p4`}</p>
          <p>{t`categories.general.items.item-3.p5`}</p>
          <br />
          <p className="font-bold mt-4">{t`categories.general.items.item-3.p6`}</p>
          <p>{t`categories.general.items.item-3.p7`}</p>
          <br />
          <p className="font-bold mt-4">{t`categories.general.items.item-3.p8`}</p>
          <p>{t`categories.general.items.item-3.p9`}</p>
          <br />
          <p className="font-bold mt-4">{t`categories.general.items.item-3.p10`}</p>
          <p>{t`categories.general.items.item-3.p11`}</p>
          <br />
          <p className="font-bold mt-4">
            {t`categories.general.items.item-3.p12`}
          </p>
          <p>{t`categories.general.items.item-3.p13`}</p>
          <br />
          <p className="font-bold mt-4">{t`categories.general.items.item-3.p14`}</p>
          <p>{t`categories.general.items.item-3.p15`}</p>
        </>
      ),
    },
    {
      title: t`categories.general.items.item-4.title`,
      content: (
        <>
          <p>{t`categories.general.items.item-4.p1`}</p>
          <br />
          <p>{t`categories.general.items.item-4.p2`}</p>
        </>
      ),
    },
    // {
    //   title: t`categories.general.items.item-5.title`,
    //   content: (
    //     <>
    //       <p>{t`categories.general.items.item-5.p1`}</p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p2`}</span>{' '}
    //         {t`categories.general.items.item-5.p3`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p4`}</span>{' '}
    //         {t`categories.general.items.item-5.p5`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p6`}</span>{' '}
    //         {t`categories.general.items.item-5.p7`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p8`}</span>{' '}
    //         {t`categories.general.items.item-5.p9`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p10`}</span>{' '}
    //         {t`categories.general.items.item-5.p11`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p12`}</span>{' '}
    //         {t`categories.general.items.item-5.p13`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p14`}</span>{' '}
    //         {t`categories.general.items.item-5.p15`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p16`}</span>{' '}
    //         {t`categories.general.items.item-5.p17`}
    //       </p>
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p18`}</span>{' '}
    //         {t`categories.general.items.item-5.p19`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p20`}</span>{' '}
    //         {t`categories.general.items.item-5.p21`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">
    //           {t`categories.general.items.item-5.p22`}
    //         </span>{' '}
    //         {t`categories.general.items.item-5.p23`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p24`}</span>{' '}
    //         {t`categories.general.items.item-5.p25`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p26`}</span>{' '}
    //         {t`categories.general.items.item-5.p27`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p28`}</span>{' '}
    //         {t`categories.general.items.item-5.p29`}
    //       </p>
    //       <br />
    //       <p>
    //         <span className="font-bold">{t`categories.general.items.item-5.p30`}</span>{' '}
    //         {t`categories.general.items.item-5.p31`}
    //       </p>
    //     </>
    //   ),
    // },
    {
      title: t`categories.general.items.item-6.title`,
      content: (
        <>
          <p>{t`categories.general.items.item-6.p1`}</p>
          <ol className="list-disc list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">{t`categories.general.items.item-6.p2`}</span>{' '}
              {t`categories.general.items.item-6.p3`}
            </li>
            <li className="mb-4">
              <span className="font-bold">{t`categories.general.items.item-6.p4`}</span>{' '}
              {t`categories.general.items.item-6.p5`}
            </li>
            <li className="mb-4">
              <span className="font-bold">{t`categories.general.items.item-6.p6`}</span>{' '}
              {t`categories.general.items.item-6.p7`}
            </li>
            <li className="mb-4">
              <span className="font-bold">{t`categories.general.items.item-6.p8`}</span>{' '}
              {t`categories.general.items.item-6.p9`}
            </li>
            <li className="mb-4">
              <span className="font-bold">{t`categories.general.items.item-6.p10`}</span>{' '}
              {t`categories.general.items.item-6.p11`}
            </li>
          </ol>
          <br />
          <p>{t`categories.general.items.item-6.p12`}</p>
        </>
      ),
    },
    {
      title: t`categories.general.items.item-7.title`,
      content: (
        <>
          <p>{t`categories.general.items.item-7.p1`}</p>
          <br />
          <p>{t`categories.general.items.item-7.p2`}</p>
          <ol className="list-disc list-inside inline-block">
            <li className="mt-2">
              <span className="font-bold">
                {t`categories.general.items.item-7.p3`}
              </span>{' '}
              {t`categories.general.items.item-7.p4`}
            </li>
            <li className="mt-2">
              <span className="font-bold">{t`categories.general.items.item-7.p5`}</span>{' '}
              {t`categories.general.items.item-7.p6`}
            </li>
            <li className="mt-2">
              <span className="font-bold">{t`categories.general.items.item-7.p7`}</span>{' '}
              {t`categories.general.items.item-7.p8`}
            </li>
            <li className="mt-2">
              <span className="font-bold">{t`categories.general.items.item-7.p9`}</span>{' '}
              {t`categories.general.items.item-7.p10`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`categories.general.items.item-8.title`,
      content: (
        <>
          <p>{t`categories.general.items.item-8.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.general.items.item-9.title`,
      content: (
        <>
          <p>{t`categories.general.items.item-9.p1`}</p>
          <ol className="list-disc list-inside inline-block">
            <li className="mt-2">
              <span className="font-bold">{t`categories.general.items.item-9.p2`}</span>{' '}
              {t`categories.general.items.item-9.p3`}
            </li>
            <li className="mt-2">
              <span className="font-bold">{t`categories.general.items.item-9.p4`}</span>{' '}
              {t`categories.general.items.item-9.p5`}
            </li>
            <li className="mt-2">
              <span className="font-bold">{t`categories.general.items.item-9.p6`}</span>{' '}
              {t`categories.general.items.item-9.p7`}
            </li>
            <li className="mt-2">
              <span className="font-bold">{t`categories.general.items.item-9.p8`}</span>{' '}
              {t`categories.general.items.item-9.p9`}
            </li>
            <li className="mt-2">
              <span className="font-bold">{t`categories.general.items.item-9.p10`}</span>{' '}
              {t`categories.general.items.item-9.p11`}
            </li>
            <li className="mt-2">
              <span className="font-bold">{t`categories.general.items.item-9.p12`}</span>{' '}
              {t`categories.general.items.item-9.p13`}
            </li>
          </ol>
        </>
      ),
    },
    {
      title: t`categories.general.items.item-10.title`,
      content: (
        <div>
          <p>{t`categories.general.items.item-10.p1`}</p>
          <ol className="list-disc list-inside inline-block">
            <li className="mb-4">
              <span className="font-bold">{t`categories.general.items.item-10.p2`}</span>{' '}
              {t`categories.general.items.item-10.p3`}
            </li>
          </ol>
          <p>{t`categories.general.items.item-10.p4`}</p>
        </div>
      ),
    },
    {
      title: t`categories.general.items.item-11.title`,
      content: (
        <>
          <p>{t`categories.general.items.item-11.p1`}</p>
        </>
      ),
    },
    {
      title: t`categories.general.items.item-12.title`,
      content: (
        <>
          <p>{t`categories.general.items.item-12.p1`}</p>
        </>
      ),
    },
  ];

  return (
    <>
      <p className="text-5xl font-extrabold mb-10">{t`categories.general.title`}</p>
      <Accordion items={items} />
    </>
  );
}
