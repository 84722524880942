import React from 'react';
import ArticleHighlightImg from '@assets/footages/blog/article-highlight-background.png';
import youtubeIcon from '@assets/footages/blog/youtube-icon.png';
import { useNavigate } from 'react-router-dom';
import { videos } from '../videos';
import { posts } from '../events';
import { useTranslation } from 'react-i18next';

const video = videos[0];
const post = posts[0];

export default function Highlights() {
  const { t } = useTranslation('blog');
  const navigate = useNavigate();

  const items = [
    {
      imgSrc: ArticleHighlightImg,
      type: 'Article of the Day',
      title:
        'The First Step to Breaking the Stigma Is to Understand Sexual Violence',
      content: `Sexual violence is a challenging topic, often surrounded by silence, stigma, and misunderstandings. It's also insidious, not always explicit or easy to recognize —yet its effects are deeply felt.`,
      contentInLeft: false,
      buttonLabel: 'Read more',
      onAction: () => {
        console.log('Hello');
        navigate(`/blog/articles/what-is-sexual-violence`);
      },
    },
    {
      imgSrc: (
        <div
          className="w-full md:w-1/2 relative cursor-pointer"
          onClick={() => navigate(`/blog/videos?id=${video.id}`)}
        >
          <img
            src={video.thumbnail}
            alt="Article Highlight"
            className="w-full"
          />
          <img
            src={youtubeIcon}
            alt="Youtube Icon"
            className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer scale-50 md:scale-100"
          />
        </div>
      ),
      type: `Today's Featured Video & Podcast`,
      title: video.title,
      content: video.description,
      contentInLeft: true,
      buttonLabel: 'Watch full podcast',
      onAction: () => {
        navigate(`/blog/videos?id=${video.id}`);
      },
    },
    {
      imgSrc: post.highlightThumbnail,
      type: 'Check out our most recent posts',
      title: post.title,
      content: post.content,
      contentInLeft: false,
      buttonLabel: 'Find out more',
      onAction: () => {
        navigate(`/blog/events?id=${post.id}`);
      },
    },
  ];

  return (
    <section className="px-[28px] md:px-[76px]">
      <h2 className="text-5xl md:text-7xl font-extrabold leading-[48px] md:leading-[65px] mb-[40px]">
        {t`tabs.highlights.title`}
      </h2>

      <div className="mb-[96px] flex flex-col gap-[40px]">
        {items.map((item) => {
          const {
            imgSrc,
            type,
            title,
            content,
            contentInLeft,
            buttonLabel,
            onAction,
          } = item;

          return (
            <HighlightCard
              img={imgSrc}
              type={type}
              title={title}
              content={content}
              contentInLeft={contentInLeft}
              buttonLabel={buttonLabel}
              onAction={onAction}
            />
          );
        })}
      </div>
    </section>
  );
}

interface HighlightCardProps {
  img: string | React.ReactNode;
  type: string;
  title: string;
  content: string | React.ReactNode;
  contentInLeft: boolean;
  buttonLabel: string;
  onAction: () => void;
}

function HighlightCard(props: HighlightCardProps) {
  const { img, type, title, content, contentInLeft, buttonLabel, onAction } =
    props;

  const renderImage = () => {
    if (typeof img !== 'string') {
      return img;
    }

    return (
      <div className="w-full md:w-1/2 max-h-[240px] md:max-h-[420px] overflow-hidden">
        <img
          src={img}
          alt="Article Highlight"
          className="w-full h-full object-cover"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="w-full md:w-1/2 p-0 md:p-[20px] md:px-[76px] space-y-[12px] md:space-y-[24px]">
        <p className="hidden md:block font-semibold text-xl md:text-3xl leading-[30px]">
          {type}
        </p>
        <p className="font-bold text-xl md:text-4xl leading-[26px] md:leading-[40px]">
          {title}
        </p>
        <p className="text-[15px] leading-[22px] line-clamp-6">{content}</p>
        <button
          className="h-[30px] md:h-[42px] px-[14px] !mt-[16px] border-black border-[2px] rounded-full text-center text-[14px] md:text-lg font-semibold hover:bg-black hover:text-white transition cursor-pointer"
          onClick={onAction}
        >
          {buttonLabel}
        </button>
      </div>
    );
  };

  return (
    <div
      className="flex-col md:flex-row bg-white shadow-md border border-gray-200 rounded-[24px] md:rounded-[32px] overflow-hidden cursor-pointer"
      onClick={onAction}
    >
      <div className="hidden md:flex">
        {contentInLeft ? renderContent() : renderImage()}
        {contentInLeft ? renderImage() : renderContent()}
      </div>

      <div className="block md:hidden p-[20px]">
        <p className="font-semibold text-base mb-[26px]">{type}</p>
        {renderImage()}
        {renderContent()}
      </div>
    </div>
  );
}
