import backgroundImageMobile from '@assets/footages/learn-more/intro-mobile.png';
import Button from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { openWhatsapp } from '@src/utils/open-whatsapp';
import { useTranslation } from 'react-i18next';

export function IntroSection() {
  const { t } = useTranslation('learn-more');
  const navigate = useNavigate();

  return (
    <section className="relative bg-white mb-[64px] md:mb-[40px]">
      <div
        className="hidden md:block absolute inset-0 bg-no-repeat bg-cover z-0"
        style={{
          backgroundImage: `url(media/learn-more/learn-more-heading.webp)`,
        }}
      ></div>

      <div className="md:hidden absolute left-0 right-0">
        <img
          src={backgroundImageMobile}
          className="w-full"
          alt="Background Image Mobile"
        />
      </div>

      {/* TODO: Will refactor, create a component for this: desktop and mobile img */}

      <div className="relative z-10 px-[25px] py-[20px] md:px-[76px] md:py-[94px]">
        <div className="md:max-w-[1200px] flex flex-col gap-[20px] md:gap-[40px]">
          <h1
            className="font-extrabold leading-[58px] md:leading-[85px] text-6xl md:text-8xl"
            dangerouslySetInnerHTML={{ __html: t`hero.title` }}
          ></h1>

          <div className="hidden md:block">
            <div className="font-semibold text-3xl">{t`hero.subtitle`}</div>
            <div className="font-regular text-2xl">{t`hero.description`}</div>
          </div>
          <div className="block md:hidden">
            <div className="font-bold text-xl">{t`hero.subtitle-mobile`}</div>
            <div className="font-regular text-lg">{t`hero.description-mobile`}</div>
          </div>

          <div>
            <Button
              label={t`hero.cta`}
              className="h-[50px] rounded-[20px] md:h-[60px] md:rounded-[24px] font-semibold text-lg md:text-2xl !px-[30px]"
              onClick={openWhatsapp}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
