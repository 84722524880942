import z from 'zod';

export const ContactUsMessageSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    subject: z.string(),
    message: z.string(),
});
export type ContactUsMessage = z.infer<typeof ContactUsMessageSchema>;

export const ContactUsMessagesSchema = ContactUsMessageSchema.array();
export type ContactUsMessages = z.infer<typeof ContactUsMessagesSchema>;
